import React from 'react';
import ConfigView from '@livingsecurity/configuration';
import { LazyLoad } from '@livingsecurity/shared';
import { AuthorizedLayout } from 'components';

const Config = (props) => {
  return (
    <AuthorizedLayout withoutPadding>
      <LazyLoad component={ConfigView} {...props} />
    </AuthorizedLayout>
  );
};

export default Config;
