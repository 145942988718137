/**
 * Convert number index in uniq string index
 *
 * @param {number} index
 *
 * @return {string}
 */
export const convertNumericIndexToAlphabetic = (index) => {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  if (index + 1 > alphabet.length) {
    let left = index;
    let times = 0;
    let result = '';
    while (left + 1 > alphabet.length) {
      result += alphabet[times];
      times += 1;
      left -= alphabet.length;
    }
    result += alphabet[left];
    return result;
  }
  return alphabet[index];
};
