import { useState, useCallback } from 'react';

export const useSearch = (action) => {
  const [searchFlag, setSearchFlag] = useState(false);

  if (typeof action !== 'function') {
    throw new Error('action should be function');
  }

  return useCallback(
    (value) => {
      if (value.length > 1 || !value.length) {
        /* istanbul ignore else */
        if (!searchFlag) setSearchFlag(true);
        action(value.trim());
      }
      if (!value.length && searchFlag) {
        setSearchFlag(false);
        action(value.trim());
      }
    },
    [action, searchFlag],
  );
};
