export function mockParticipantDashboardAssignments(initialValue = {}) {
  return {
    ...initialValue,
    sections: [
      {
        campaignId: '72850c00-b0fa-4ea3-9376-5e6877210439',
        firstAssignedDate: '2023-06-29 08:50:43.270499',
        assignments: [
          {
            id: 'a1wtQaSzQ8W-FQ==',
            contentId: '5WIb9nDJnEVMC1tr9u88YX',
            progress: 0,
            assignedDate: '2023-06-29 08:50:43.270499',
            dueDate: '2023-07-09T08:50:43.263848+00:00',
            required: true,
            completionDate: null,
            action_id: '76a142fc-6400-4338-b737-cf679025a14e',
          },
          {
            id: 'UimmoI6RQwDsxA==',
            contentId: '60nHHuV7OJAeqazu9V1VQl',
            progress: 0,
            assignedDate: '2023-06-29 08:50:43.270499',
            dueDate: '2023-07-09T08:50:43.263848+00:00',
            required: true,
            completionDate: null,
            action_id: '76a142fc-6400-4338-b737-cf679025a14e',
          },
          {
            id: 'vVjCFeVKF1uluA==',
            contentId: '6UprsqkIvNs5FV1WQ74TbU',
            progress: 0,
            assignedDate: '2023-06-29 08:50:43.270499',
            dueDate: '2023-07-09T08:50:43.263848+00:00',
            required: true,
            completionDate: null,
            action_id: '76a142fc-6400-4338-b737-cf679025a14e',
          },
          {
            id: 'YtlQ51bfWTas5Q==',
            contentId: '2MiWFgwJHK4swJtwl6Jl47',
            progress: 0,
            assignedDate: '2023-06-29 08:50:43.270499',
            dueDate: '2023-07-09T08:50:43.263848+00:00',
            required: true,
            completionDate: null,
            action_id: '76a142fc-6400-4338-b737-cf679025a14e',
          },
          {
            id: '_phydIrdTrjdmw==',
            contentId: 'zsSrszRfUhZwo8h22eZgr',
            progress: 0,
            assignedDate: '2023-06-29 08:50:43.270499',
            dueDate: '2023-07-09T08:50:43.263848+00:00',
            required: true,
            completionDate: null,
            action_id: '76a142fc-6400-4338-b737-cf679025a14e',
          },
          {
            id: '74gRGSOXSsAFXw==',
            contentId: '7JbpwLfeH7sWDjJmPgF5f3',
            progress: 0,
            assignedDate: '2023-06-29 08:50:43.270499',
            dueDate: '2023-07-09T08:50:43.263848+00:00',
            required: true,
            completionDate: null,
            action_id: '76a142fc-6400-4338-b737-cf679025a14e',
          },
        ],
      },
    ],
    features: [],
  };
}

export function mockCampaignsForAssignments() {
  return [
    {
      action_hour: 21,
      assignments_assigned: 9,
      audience_type: 'dynamic',
      audiences: [
        {
          id: '1d1a7894-322c-4548-a018-de1070307ded',
          name: 'First Two',
          participant_count: 2,
        },
      ],
      completed_participant_count: 0,
      created: '2023-06-29 08:46:34.410772+00:00',
      drop_in: false,
      id: '72850c00-b0fa-4ea3-9376-5e6877210439',
      is_jit: false,
      is_priority: false,
      modified: '2023-06-29 08:46:34.410810+00:00',
      participant_count: 2,
      specific_date: false,
      state: 'ACTIVE',
      test: false,
      theme_id: '69OGaH9xaMs3D1zq6iRL0r',
      company_id: 'cc1462aa-51c9-4feb-b9b4-83180879c487',
      date_start: '2023-06-29 08:50:29.928928+00:00',
      description: 'A bunch of phishing content',
      name: 'Phishing Remediation',
    },
  ];
}

export function mockContentOrderForCampaigns(initialValue = {}) {
  return {
    ...initialValue,
    '72850c00-b0fa-4ea3-9376-5e6877210439': {
      '76a142fc-6400-4338-b737-cf679025a14e': [
        '2MiWFgwJHK4swJtwl6Jl47',
        '60nHHuV7OJAeqazu9V1VQl',
        '5WIb9nDJnEVMC1tr9u88YX',
        '7JbpwLfeH7sWDjJmPgF5f3',
        'zsSrszRfUhZwo8h22eZgr',
        '6UprsqkIvNs5FV1WQ74TbU',
      ],
      '52c2c480-cf9a-44a9-8d9f-379622656673': [
        'oCur68s9PovIRxEsdHgRj',
        '7GU86AjFCaxgB5hiVEY5x7',
        '4PU2JNqTjpQJKvXW26ZWos',
      ],
    },
  };
}

export function mockCampaignIdError() {
  return { message: 'No campaign ids provided' };
}
