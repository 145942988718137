import { rest } from 'msw';
import { v4 as uuidv4 } from 'uuid';

export const handlers = [
  // This is the handler from registering a new user through the onboarding process
  rest.post(`${process.env.REACT_APP_API_URL}/onboard/register`, async (req, res, ctx) => {
    const body = await req.json();
    if (
      body.company_name.length > 0 &&
      body.user_email.length > 0 &&
      body.first_name.length > 0 &&
      body.last_name.length > 0
    ) {
      return res(
        ctx.status(200),
        ctx.json({
          location: `/onboard/register/${uuidv4()}`,
        }),
      );
    } else {
      return res(
        ctx.status(400),
        ctx.json({
          message: 'Invalid request',
        }),
      );
    }
  }),

  // This is the handler to validate the new user and company information then create the new tenant and redirect to the dashboard
  rest.get(`${process.env.REACT_APP_API_URL}/onboard/register/:id`, async (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        transaction_state: 'FAILED',
        failure_reason: ['Domain not available: coolkids2.com'],
        id_token: null,
        access_token: null,
      }),
    );
  }),
];
