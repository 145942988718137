import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import * as Styled from './styles';

// Reference: https://github.com/mui-org/material-ui/blob/master/packages/mui-material/src/SvgIcon/SvgIcon.js

const SvgIconBase = ({ children, component, viewBox, inheritViewBox, titleAccess, fontSize, ...svgProps }, ref) => {
  return (
    <Styled.SvgIconRoot
      as={component}
      focusable="false"
      aria-hidden={titleAccess ? undefined : true}
      role={titleAccess ? 'img' : undefined}
      ref={ref}
      fontSize={fontSize}
      {...(inheritViewBox ? {} : { viewBox })}
      {...svgProps}
    >
      {children}
      {titleAccess ? <title>{titleAccess}</title> : null}
    </Styled.SvgIconRoot>
  );
};

const SvgIcon = forwardRef(SvgIconBase);

SvgIcon.defaultProps = {
  component: 'svg',
  viewBox: '0 0 24 24',
  inheritViewBox: false,
  fontSize: 'medium',
};

SvgIcon.propTypes = {
  /**
   * Node passed into the SVG element.
   */
  children: PropTypes.node,

  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: PropTypes.elementType,

  /**
   * Applies a color attribute to the SVG element.
   */
  сolor: PropTypes.string,

  /**
   * Provides a human-readable title for the element that contains it.
   * https://www.w3.org/TR/SVG-access/#Equivalent
   */
  titleAccess: PropTypes.string,
  /**
   * Allows you to redefine what the coordinates without units mean inside an SVG element.
   * For example, if the SVG element is 500 (width) by 200 (height),
   * and you pass viewBox="0 0 50 20",
   * this means that the coordinates inside the SVG will go from the top left corner (0,0)
   * to bottom right (50,20) and each unit will be worth 10px.
   */
  viewBox: PropTypes.string,

  /**
   * The fontSize applied to the icon. Defaults to 24px, but can be configure to inherit font size.
   * @default 'medium'
   */
  fontSize: PropTypes.oneOfType([
    PropTypes.oneOf(['inherit', 'large', 'medium', 'small']),
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default SvgIcon;
