export const getPlayerCoverImage = (imageData) => {
  if (!imageData?.fields) return null;
  const {
    title,
    description,
    file: { url },
  } = imageData.fields;
  return {
    description,
    title,
    coverImage: `https:${url}.jpg`,
  };
};
