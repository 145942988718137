import { tokenService } from '../utils/services';

export default function clientMiddleware(client, clientContentful) {
  return ({ dispatch, getState }) =>
    (next) =>
    (action) => {
      if (typeof action === 'function') {
        return action(dispatch, getState);
      }

      const { promise, types, payload, contentful, ...rest } = action;
      const contentfulToken = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;

      // Checking for env variable for unit test purposes
      if ((!promise && !contentful) || !contentfulToken) {
        return next(action);
      }

      const [REQUEST, SUCCESS, FAILURE] = types;
      next({ ...rest, ...(payload && { payload }), type: REQUEST });

      const actionPromise = promise ? promise(client, getState) : contentful(clientContentful, getState);
      actionPromise
        .then(
          (result) => {
            try {
              next({
                ...rest,
                result,
                type: SUCCESS,
                staticData: getState().staticData,
                ...(payload && { payload }),
              });
            } catch (error) {
              error.meta = { action: SUCCESS };
              throw error;
            }
          },
          (error) => {
            // logout user if expired token or permission denied
            try {
              if (error.response && [401, 403].includes(error.response.status)) {
                tokenService.removeToken();
              }
              next({
                ...rest,
                error,
                type: FAILURE,
                ...(payload && { payload }),
              });
            } catch (e) {
              e.meta = { action: FAILURE };
              throw error;
            }
          },
        )
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('MIDDLEWARE ERROR:', error);
          next({ ...rest, error, type: FAILURE });
        });

      return actionPromise;
    };
}
