import { useState, useRef, useCallback } from 'react';
import { useSortTable } from './useSortTable';

const ORDER_STRING_MAP = {
  1: 'asc',
  '-1': 'desc',
};

export const useSortBy = (defaultSortingState, { onSortingChange = () => {} } = {}) => {
  const [sortingState, setSortingState] = useState(defaultSortingState);
  const safeOnSortingChange = useRef(onSortingChange);

  const handleSortingChange = useCallback((newSortingState) => {
    setSortingState(newSortingState);
    safeOnSortingChange.current(newSortingState);
  }, []);

  const [sortByAsc, sortByDesc] = useSortTable(sortingState, handleSortingChange, true);

  return {
    sortByAsc,
    sortByDesc,
    sortingState,
    sortOrderAsText: ORDER_STRING_MAP[sortingState.sortOrder],
  };
};
