import { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';

import { useNavigate } from './useNavigate';

const calculateOffset = (pageIndex, pageSize) => (pageIndex - 1) * pageSize;

// Can be useful until we completely migrate to React Table v7+

// TODO add tests

export const usePagination = ({
  syncURL = false,
  pageSize: defaultPageSize = 10,
  pageIndex: defaultPageIndex = 1,
  onPaginationChange = () => {},
} = {}) => {
  const { search: searchParams } = useLocation();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [pageIndex, setPageIndex] = useState(defaultPageIndex);
  const safeOnPaginationChange = useRef(onPaginationChange);

  const updatePageURLParams = useCallback(
    (pageValue, perPageValue, replace = false) =>
      navigate({ search: { page: pageValue, per_page: perPageValue } }, { replace, mergeParams: true }),
    [navigate],
  );

  useEffect(() => {
    if (!syncURL) return;
    const prms = qs.parse(searchParams, { parseNumbers: true });

    if (prms?.page && prms?.per_page) {
      setPageIndex(prms.page);
      setPageSize(prms.per_page);
      updatePageURLParams(prms.page, prms.per_page);
    } else {
      updatePageURLParams(defaultPageIndex, defaultPageSize, true);
    }
  }, [syncURL, defaultPageIndex, defaultPageSize, searchParams, updatePageURLParams]);

  const resetPageIndex = useCallback(() => setPageIndex(defaultPageIndex), [defaultPageIndex]);

  const handlePageIndexChange = useCallback(
    (newPageIndex) => {
      if (pageIndex === newPageIndex) return;
      setPageIndex(newPageIndex);

      if (syncURL) {
        updatePageURLParams(newPageIndex, pageSize);
      }

      safeOnPaginationChange.current({ pageIndex: newPageIndex, pageSize });
    },
    [pageIndex, pageSize, syncURL, updatePageURLParams],
  );

  const handlePageSizeChange = useCallback(
    (newPageSize) => {
      if (pageSize === newPageSize) return;
      resetPageIndex();
      setPageSize(newPageSize);

      if (syncURL) {
        updatePageURLParams(defaultPageIndex, newPageSize);
      }

      safeOnPaginationChange.current({ pageIndex: defaultPageIndex, pageSize: newPageSize });
    },
    [defaultPageIndex, pageSize, resetPageIndex, syncURL, updatePageURLParams],
  );

  return {
    pageIndex,
    pageSize,
    handlePageIndexChange,
    handlePageSizeChange,
    resetPageIndex,
    calculateOffset, // For those endpoints that use query params limit/offset instead of page/per_page
  };
};
