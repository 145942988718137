import { useAuth0 } from '../lib';
import { URLS, USER_GROUPS } from '../_constants';
import { getFromLS, LOCATION_KEY } from '../utils/services';

export const useUserLandingPage = () => {
  const { userGroups, isImpersonate } = useAuth0();
  const lastTrainingUrl = getFromLS(LOCATION_KEY.TRAINING_PATH);
  const lastTeamsUrl = getFromLS(LOCATION_KEY.TEAMS_PATH);
  const lastUnifyUrl = getFromLS(LOCATION_KEY.UNIFY_PATH);

  if (userGroups?.includes(USER_GROUPS.LS_ADMIN) && !isImpersonate) {
    return URLS.companies;
  } else if (userGroups?.includes(USER_GROUPS.LS_ADMIN)) {
    return lastTrainingUrl || URLS.userManagement;
  } else if (userGroups?.includes(USER_GROUPS.ENTERPRISE_ADMIN)) {
    return URLS.userManagement;
  } else if (userGroups?.includes(USER_GROUPS.CUSTOMER_ADMIN)) {
    return lastTrainingUrl || URLS.campaigns;
  } else if (
    userGroups?.includes(USER_GROUPS.TEAMS_LS_ADMIN) ||
    userGroups?.includes(USER_GROUPS.TEAMS_CUSTOMER_ADMIN) ||
    userGroups?.includes(USER_GROUPS.TEAMS_COLLABORATOR)
  ) {
    return lastTeamsUrl || URLS.teamsBase;
  } else if (
    userGroups?.includes(USER_GROUPS.UNIFY_CUSTOMER_ADMIN) ||
    userGroups?.includes(USER_GROUPS.UNIFY_CUSTOMER_LIMITED_ADMIN)
  ) {
    return lastUnifyUrl || URLS.unify;
  } else if (
    userGroups?.includes(USER_GROUPS.PHISHING_LS_ADMIN) ||
    userGroups?.includes(USER_GROUPS.PHISHING_CUSTOMER_ADMIN)
  ) {
    return URLS.phishing;
  } else if (userGroups?.includes(USER_GROUPS.TRAINING_DEMO)) {
    return URLS.catalog;
  }

  return URLS.dashboard;
};
