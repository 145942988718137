/**
 * Escapes special symbols and characters except a-z A-Z 0-9 and space \\
 *
 * @example escapeSpecialChars('#hello')
 * return '\\#hello'
 *
 * @param {string} str
 *
 * @return {string}
 *
 */
export const escapeSpecialChars = (str) => (str?.length ? str.replace(/([^a-zA-Z0-9_ ])/g, '\\$1') : '');
