import styled from 'styled-components';

const FONT_SIZE = {
  inherit: 'inherit',
  small: '20px',
  medium: '24px',
  large: '32px',
};

const toPx = (v) => (typeof v === 'number' ? `${v}px` : v);

export const SvgIconRoot = styled.svg`
  user-select: none;
  display: inline-block;
  flex-shrink: 0;
  fill: currentColor;
  width: 1em;
  height: 1em;
  font-size: ${({ fontSize = 'medium' }) => FONT_SIZE[fontSize] || toPx(fontSize)};
  pointer-events: auto;
`;
