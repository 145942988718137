/* ----------  Derived data  ----------*/

export const getCompanyInfo = (state) => state.auth.companyInfo;

export const getCompanySubscriptions = (state) => state.auth.companyInfo?.subscriptions;

export const getCompanyStatusLMS = (state) => state.auth.companyInfo?.lmsGateway;

export const getCompanyName = (state) => state.auth?.companyInfo?.name;

export const getAuthLoading = (state) => state.auth.loading;
