import { cloneDeep } from 'lodash';

/**
 * Change value in nested array in object
 *
 * @example updateNestedArray({ a: [1, 2]}, ['a'], (el) => el === 2), 3)
 * return { a: [1, 3] }
 *
 * @param {object} state
 * @param {string[]} path
 * @param {function} findBy
 * @param {any} value

 *
 * @return {object}
 */
export const updateNestedArray = (state, path, findBy, value) => {
  const newState = cloneDeep(state);
  let current = newState;

  if (path.length > 1) {
    while (path.length > 1) {
      current = current[path.shift()];
    }
  }

  const index = current[path[0]].findIndex(findBy);
  current[path[0]][index] = value;

  return newState;
};
