import { isString } from 'lodash';
import { EMAIL_RULE } from '../../_constants';
/**
 * Validate email by email rule
 * @param  {} email
 *
 * @returns {boolean}
 */
const isValidEmail = (email) => isString(email) && EMAIL_RULE.test(email);

export default isValidEmail;
