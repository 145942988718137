import { useQuery, useMutation } from 'react-query';
import { httpClient } from '../lib';

const getPhishingStatus = ({ tenantId = '' }) =>
  httpClient.get(`/phishing/phishing/integration${tenantId ? `?tenant_id=${tenantId}` : ''}`);

const createPhishingAccount = ({ tenantId = '' }) =>
  httpClient.post(`/phishing/phishing/integration${tenantId ? `?tenant_id=${tenantId}` : ''}`);

const updatePhishingStatus = ({ tenantId = '', body = {} }) =>
  httpClient.put(`/phishing/phishing/integration/status${tenantId ? `?tenant_id=${tenantId}` : ''}`, body);

const updatePhishingImport = ({ tenantId = '', body = {} }) =>
  httpClient.put(`/phishing/phishing/integration/audience/status${tenantId ? `?tenant_id=${tenantId}` : ''}`, body);

const updateSyncSamlToPhishing = ({ tenantId = '' }) =>
  httpClient.put(`/phishing/phishing/integration/saml${tenantId ? `?tenant_id=${tenantId}` : ''}`);

export const useLSPhishingStatus = ({ tenantId = '', queryKey = [] }) => {
  const { data, isLoading, isError, isSuccess, error } = useQuery(queryKey, () => getPhishingStatus({ tenantId }));
  return {
    creationStatus: data?.creation_status,
    status: data?.status,
    importStatus: data?.export_audience_status,
    isStatusLoading: isLoading,
    isStatusError: isError || data?.creation_status === 'failed',
    isStatusSuccess: isSuccess,
    statusError: error,
  };
};

export const useUpdateLSPhishingStatus = (mutationOptions = {}) => {
  return useMutation(({ tenantId = '', body = {} }) => {
    return updatePhishingStatus({ tenantId, body });
  }, mutationOptions);
};

export const useCreateLSPhishingAccount = (mutationOptions = {}) => {
  return useMutation(({ tenantId = '' }) => {
    return createPhishingAccount({ tenantId });
  }, mutationOptions);
};

export const useUpdateLSPhishingImport = (mutationOptions = {}) => {
  return useMutation(({ tenantId = '', body = {} }) => {
    return updatePhishingImport({ tenantId, body });
  }, mutationOptions);
};

export const useSyncSamlToPhishing = (mutationOptions = {}) => {
  return useMutation(({ tenantId = '' }) => {
    return updateSyncSamlToPhishing({ tenantId });
  }, mutationOptions);
};
