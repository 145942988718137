import { isEmpty, flatten, values, isString, isPlainObject } from 'lodash';
import notification from '../services/notification';

export const showErrorsFromBackend = (error, notificationProps = {}) => {
  if (!error || isEmpty(error)) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.log('Unknown error', error);
    }
    return notification.error('Error! Try again!');
  }

  if (isString(error)) {
    return notification.error(error);
  }

  if (isString(error?.response?.data)) {
    return notification.error(error?.response?.data);
  }

  return flatten(values(error?.response?.data)).forEach((message) => {
    notification.error(isPlainObject(message) ? Object.values(message) : message, notificationProps);
  });
};
