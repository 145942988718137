import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import createAuth0Client from '@auth0/auth0-spa-js';
import Cookies from 'js-cookie';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import jwt_decode from 'jwt-decode';

import { TablePersistor, I18N_KEY, getBaseHostname } from '../utils/custom';
import config from '../config';
import { USER_GROUPS, IMPERSONATE, CT_LOCALIZATION, URLS } from '../_constants';
import { tokenService, i18n, setToLS, getFromLS } from '../utils/services';

import httpClient from './httpClient';

const tablesCachePersistor = TablePersistor();

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);

export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  onAuthenticated,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState({});
  const [locale, setLocale] = useState(null);
  const [userGroups, setUserGroups] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [isSelfService, setIsSelfService] = useState(false);

  const handleLocaleChanged = useCallback((userLocale) => {
    setLocale(userLocale);
    i18n.changeLanguage(userLocale);
    localStorage?.setItem(I18N_KEY, userLocale);
  }, []);

  const handleSetLocale = useCallback(
    async (userData) => {
      try {
        const { user_metadata: userMetaData } = await httpClient.get(
          `/users/users/${encodeURI(userData.sub)}?tenant_id=${userData.tenant_id}`,
        );

        const userLocale = userMetaData?.locale || CT_LOCALIZATION.EN;

        handleLocaleChanged(userLocale);
      } catch (e) {
        handleLocaleChanged(CT_LOCALIZATION.EN);
      }
    },
    [handleLocaleChanged],
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    // Don't initialize auth0 for standalone player or LMS
    if (window.location.pathname === URLS.player) return setLoading(false);

    // Don't initialize auth0 if user already exists and is self service
    if (!isEmpty(user) && isSelfService) return setLoading(false);

    const token = tokenService.getToken();

    if (token && isSelfService) {
      return initTrialAuth(token);
    }

    const initAuth0 = async () => {
      try {
        const auth0FromHook = await createAuth0Client(initOptions);
        setAuth0(auth0FromHook);

        if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          onRedirectCallback(appState);
        }

        const userAuthenticated = await auth0FromHook.isAuthenticated();

        setIsAuthenticated(userAuthenticated);

        if (userAuthenticated) {
          const auth0User = await auth0FromHook.getUser();

          if (isImpersonate) {
            setUser({
              ...auth0User,
              tenant_id: localStorage?.getItem(IMPERSONATE.IMPERSONATED_COMPANY_ID),
              ...(auth0User[config.USER_CLAIMS.USER_ID] ? { sub: auth0User[config.USER_CLAIMS.USER_ID] } : {}),
            });
            setUserGroups([...getCombinedUserGroups(auth0User), USER_GROUPS.CUSTOMER_ADMIN]);
          } else {
            setUser({
              ...auth0User,
              tenant_id: auth0User[config.USER_CLAIMS.TENANT_ID],
              ...(auth0User[config.USER_CLAIMS.USER_ID] ? { sub: auth0User[config.USER_CLAIMS.USER_ID] } : {}),
            });
            setUserGroups(getCombinedUserGroups(auth0User));
          }
        }

        setLoading(false);
      } catch (error) {
        errorHandler(error);
      }
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const updateElastic = async (authUser) => {
      try {
        const claims = await auth0Client.getIdTokenClaims();
        const token = claims?.__raw || tokenService.getToken();

        if (token) {
          tokenService.manageToken(token);

          if (isAuthenticated) {
            await handleSetLocale(authUser);
          }

          Cookies.set('lsUser', JSON.stringify(authUser), {
            domain: location.hostname === 'localhost' ? location.hostname : `.${getBaseHostname(location.hostname)}`,
            sameSite: 'Lax',
            expires: 30,
          });
          onAuthenticated(authUser, token);
        } else {
          setUser({});
          setIsAuthenticated(false);
          logoutHandler();
        }
      } catch (error) {
        errorHandler(error);
      }
    };

    if (!isEmpty(user) && isAuthenticated) {
      updateElastic(user);
    }
  }, [user, auth0Client, onAuthenticated]);

  const errorHandler = (error) => {
    console.error(error);
    setUser({});
    setIsAuthenticated(false);
    logoutHandler();
  };

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      errorHandler(error);
    } finally {
      setPopupOpen(false);
    }
    const auth0User = await auth0Client.getUser();
    setUser({
      ...auth0User,
      tenant_id: auth0User[config.USER_CLAIMS.TENANT_ID],
    });
    setUserGroups(getCombinedUserGroups(auth0User));
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const auth0User = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser({
      ...auth0User,
      tenant_id: auth0User[config.USER_CLAIMS.TENANT_ID],
    });
    setUserGroups(getCombinedUserGroups(auth0User));
  };

  const getCombinedUserGroups = (auth0User) => {
    return (auth0User[config.USER_CLAIMS.GROUPS] || []).concat(
      auth0User[config.USER_CLAIMS.TEAMS_GROUPS]?.groups || [],
    );
  };

  const impersonateUser = (tenantId) => {
    localStorage?.setItem(IMPERSONATE.IMPERSONATED_COMPANY_ID, tenantId);
    Cookies.remove(IMPERSONATE.IMPERSONATED_TEAMS_COMPANY_ID, {
      domain: `.${getBaseHostname(location.hostname)}`,
    });
    setUser({ ...user, tenant_id: tenantId });
    setUserGroups([...getCombinedUserGroups(user), USER_GROUPS.CUSTOMER_ADMIN]);
    tablesCachePersistor.clear();
    auth0Client.loginWithRedirect({ appState: { targetUrl: URLS.campaigns } });
  };

  const cancelImpersonate = () => {
    localStorage?.removeItem(IMPERSONATE.IMPERSONATED_COMPANY_ID);
    Cookies.remove(IMPERSONATE.IMPERSONATED_TEAMS_COMPANY_ID, {
      domain: `.${getBaseHostname(location.hostname)}`,
    });
    setUser({ ...user, tenant_id: user[config.USER_CLAIMS.TENANT_ID] });
    setUserGroups(getCombinedUserGroups(user));
  };

  const checkImpersonate = () => {
    try {
      return !!localStorage?.getItem(IMPERSONATE.IMPERSONATED_COMPANY_ID);
    } catch (e) {
      console.error(`[react-auth0-spa.js] Could not get user's impersonate status from localStorage: `, e);
      return false;
    }
  };
  const isImpersonate = checkImpersonate();

  const isUserHasRights = (groups) => !groups.length || !!intersection(userGroups, groups).length;

  const canImpersonate = isUserHasRights([USER_GROUPS.LS_ADMIN, USER_GROUPS.ENTERPRISE_ADMIN]);

  const initTrialAuth = useCallback(
    async (token) => {
      try {
        tokenService.manageToken(token);

        setIsAuthenticated(true);
        setIsSelfService(true);

        const auth0User = jwt_decode(token);

        if (isImpersonate) {
          setUser({
            ...auth0User,
            tenant_id: localStorage?.getItem(IMPERSONATE.IMPERSONATED_COMPANY_ID),
            ...(auth0User[config.USER_CLAIMS.USER_ID] ? { sub: auth0User[config.USER_CLAIMS.USER_ID] } : {}),
          });
          setUserGroups([...getCombinedUserGroups(auth0User), USER_GROUPS.CUSTOMER_ADMIN]);
        } else {
          setUser({
            ...auth0User,
            tenant_id: auth0User[config.USER_CLAIMS.TENANT_ID],
            ...(auth0User[config.USER_CLAIMS.USER_ID] ? { sub: auth0User[config.USER_CLAIMS.USER_ID] } : {}),
          });
          setUserGroups(getCombinedUserGroups(auth0User));
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        errorHandler(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const logoutHandler = (location) => {
    const tablesCache = tablesCachePersistor.getTablesCache();
    const localTheme = getFromLS('theme');
    const hidePopupState = getFromLS('HIDE_ATTENTION_POPUP');
    const themeToSet = ['dark', 'light'].includes(localTheme) ? localTheme : 'dark';

    cancelImpersonate();

    if (userGroups?.length && userGroups.includes(USER_GROUPS.LS_ADMIN)) {
      tablesCachePersistor.clearTablesCache();
      localStorage.clear();
      sessionStorage.clear();
    } else {
      sessionStorage.clear();
      localStorage.clear();
      tablesCachePersistor.clearTablesCache();
      tablesCachePersistor.setTablesCache(tablesCache);
    }

    setToLS('theme', themeToSet);
    setToLS('HIDE_ATTENTION_POPUP', typeof hidePopupState === 'boolean' ? hidePopupState : false);

    setUser({});
    setIsAuthenticated(false);

    auth0Client?.logout({
      federated: Boolean(user[config.USER_CLAIMS.IS_FEDERATED_SLO]),
      returnTo: window.location.origin,
    });
  };

  const loginWithRedirectHandler = (...p) => {
    cancelImpersonate();
    auth0Client?.loginWithRedirect(...p);
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        locale,
        userGroups,
        isUserHasRights,
        impersonateUser,
        canImpersonate,
        cancelImpersonate,
        isImpersonate,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: loginWithRedirectHandler,
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: logoutHandler,
        initTrialAuth,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

Auth0Provider.propTypes = {
  onRedirectCallback: PropTypes.func.isRequired,
  onAuthenticated: PropTypes.func.isRequired,
};
