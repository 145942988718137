import moment from 'moment';
import { DATE_FORMATS } from '../../_constants';

/**
 * Convert object with array of dates
 * to object with dates in gt and lt formats
 * Could programmaticaly set start and end of day in UTC format by withUTC flag
 *
 * @example dumpDateToGtLtFormat({
 *  test: [new Date('October 13, 2014 11:13:00'), new Date('October 15, 2014 11:13:00')]
 *  })
 * return {
 *    hello_gte: '2014-10-13',
 *    hello_lte: '2014-10-15',
 *  }
 *
 * @param {object} dateObj
 *
 * @return {object}
 */
export const dumpDateToGtLtFormat = (dateObj, withUTC = false) => {
  if (!dateObj) return null;

  const dumpedDates = Object.entries(dateObj).reduce((acc, dateEntry) => {
    const [key, dateArr] = dateEntry;

    if (!dateArr.length) return acc;

    const secondDate = dateArr.length === 1 ? dateArr[0] : dateArr[1];

    return {
      ...acc,
      [`${key}_gte`]: withUTC
        ? moment(dateArr[0]).startOf('day').toISOString()
        : moment(dateArr[0]).format(DATE_FORMATS.BE_FILTERING),
      [`${key}_lte`]: withUTC
        ? moment(secondDate).endOf('day').toISOString()
        : moment(secondDate).format(DATE_FORMATS.BE_FILTERING),
    };
  }, {});

  return dumpedDates;
};
