import { rest } from 'msw';

import { mockKeyIndicator, mockNoFilterError } from '../../entities/other';
// ? We may not need this because of process.env.REACT_APP_API_URL
// import { trainingApi } from 'mocks/baseUrls';

export const handlers = [
  // // Accuracy
  // rest.post(`${process.env.REACT_APP_API_URL}/next-gen-reporting/campaigns/stats/accuracy`, async (req, res, ctx) => {
  //   const body = await req.json();

  //   if (body?.filter) {
  //     return res(ctx.status(200), ctx.json(mockKeyIndicator({ data: { accuracy: 0.93 } })));
  //   } else {
  //     return res(ctx.status(400), mockNoFilterError(ctx));
  //   }
  // }),
  // // Completion Rate
  // rest.post(
  //   `${process.env.REACT_APP_API_URL}/next-gen-reporting/campaigns/stats/completion_rate`,
  //   async (req, res, ctx) => {
  //     const body = await req.json();

  //     if (body?.filter) {
  //       return res(ctx.status(200), ctx.json(mockKeyIndicator({ data: { completion_rate: 0.14 } })));
  //     } else {
  //       return res(ctx.status(400), mockNoFilterError(ctx));
  //     }
  //   },
  // ),
  // // Participant Progress
  // rest.post(
  //   `${process.env.REACT_APP_API_URL}/next-gen-reporting/campaigns/stats/participant_progress`,
  //   async (req, res, ctx) => {
  //     const body = await req.json();

  //     if (body?.filter) {
  //       return res(
  //         ctx.status(200),
  //         ctx.json(
  //           mockKeyIndicator({
  //             data: {
  //               participant_progress: {
  //                 participant_count: 193,
  //                 OVERDUE: 36,
  //                 NOT_STARTED: 20,
  //                 UNDERWAY: 17,
  //                 COMPLETED: 120,
  //               },
  //             },
  //           }),
  //         ),
  //       );
  //     } else {
  //       return res(ctx.status(400), mockNoFilterError(ctx));
  //     }
  //   },
  // ),
  // // Total Audiences
  // rest.post(
  //   `${process.env.REACT_APP_API_URL}/next-gen-reporting/campaigns/stats/audience_count`,
  //   async (req, res, ctx) => {
  //     const body = await req.json();

  //     if (body?.filter) {
  //       return res(ctx.status(200), ctx.json(mockKeyIndicator({ data: { audience_count: 8 } })));
  //     } else {
  //       return res(ctx.status(400), mockNoFilterError(ctx));
  //     }
  //   },
  // ),
  // // Avg. Content Completion Time
  // rest.post(
  //   `${process.env.REACT_APP_API_URL}/next-gen-reporting/campaigns/stats/completion_time`,
  //   async (req, res, ctx) => {
  //     const body = await req.json();

  //     if (body?.filter) {
  //       return res(ctx.status(200), ctx.json(mockKeyIndicator({ data: { completion_time: 400 } })));
  //     } else {
  //       return res(ctx.status(400), mockNoFilterError(ctx));
  //     }
  //   },
  // ),
  // Todo: Table Data
  // rest.post(),
  // Select Participants (for adhoc actions modal)
  rest.post(
    `${process.env.REACT_APP_API_URL}/next-gen-reporting/campaigns/participants/select`,
    async (req, res, ctx) => {
      const { count } = await req.json();

      return res(
        ctx.json({
          participant_count: count,
        }),
      );
    },
  ),
];
