import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary, Iframe, URLS } from '@livingsecurity/shared';

import { AuthorizedLayout } from 'components';

const SupportGarden = ({ history }) => {
  useEffect(() => {
    if (window.location?.href?.includes('livingsecurity.com')) {
      window.document.domain = 'livingsecurity.com';
    }
  }, []);

  return (
    <>
      <AuthorizedLayout app="SUPPORT" withBottomPaddingForHubspot>
        <ErrorBoundary scope="support-garden">
          <Iframe
            id="support-garden-embed-frame"
            className="embedded-iframe"
            data-testid="support-garden-embed-frame"
            title="Living Security Support Garden"
            src={URLS.supportDesk}
          />
        </ErrorBoundary>
      </AuthorizedLayout>
    </>
  );
};

SupportGarden.propTypes = {
  history: PropTypes.any.isRequired,
};

export default SupportGarden;
