import { isValidEmailDomain } from '..';

export default (domains, isRequired) => {
  const errors =
    Array.isArray(domains) &&
    domains
      .map((domain, index, arr) => {
        if (isRequired && !domain.length) {
          return {
            value: domain,
            error: 'This field is required',
          };
        }

        if (domain.length && !isValidEmailDomain(domain)) {
          return {
            value: domain,
            error: 'Invalid email domain',
          };
        }

        if (arr.indexOf(domain) !== index) {
          return {
            value: domain,
            error: 'This domain is already added',
          };
        }

        return null;
      })
      .filter((domain) => domain);

  return errors || [];
};
