import COLORS_CONFIG from '../assets/styles/colors';

const INTEGRATION_STATUS_PROPS = {
  SYNCED: {
    bgColor: '#def5e1',
    strokeColor: '#7CD88B',
    color: 'black',
    label: 'messages.success.synced',
  },
  DISABLED: {
    bgColor: COLORS_CONFIG.PRIMARY.SANDY_GRAY,
    strokeColor: COLORS_CONFIG.SECONDARY.IRON,
    color: 'black',
    label: 'messages.info.disabled',
  },
  PENDING: {
    bgColor: '#ced9f4',
    strokeColor: COLORS_CONFIG.FEEDBACK.INFO,
    color: 'black',
    label: 'messages.info.pending',
  },
  DISCONNECTED: {
    bgColor: '#fbd7d3',
    strokeColor: COLORS_CONFIG.PRIMARY.POPPY_RED,
    color: 'black',
    label: 'messages.error.needsAttention',
  },
};

export default INTEGRATION_STATUS_PROPS;
