import { useState, useCallback } from 'react';

/**
 * This hook returns a React ref and width of whichever element you set the ref on
 * @returns {number} width
 * @returns {node} elementRef
 */

export const useElementWidth = () => {
  const [width, setWidth] = useState(0);
  const elementRef = useCallback((node) => {
    /* istanbul ignore else  */
    if (node !== null) {
      // this takes a second to let the component(s) load, otherwise the returned width will be incorrect
      setTimeout(() => {
        setWidth(node.getBoundingClientRect().width);
      }, 1000);
    }
  }, []);

  return { width, elementRef };
};
