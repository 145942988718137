import moment from 'moment';

/**
 * Concat array of start date and end date to query string
 *
 * @example dumpDateToQuery([1610649636036, 1610735975516])
 * return '[1610571600000 TO 1610744399999]'
 *
 * @param {array} date
 *
 * @return {string}
 *
 */
export const dumpDateToQuery = (date) => {
  if (!date.length) return null;

  const secondDate = date.length === 1 ? date[0] : date[1];

  return `[${moment(date[0]).startOf('day').valueOf()} TO ${moment(secondDate).endOf('day').valueOf()}]`;
};
