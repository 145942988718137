import { rest } from 'msw';

export const handlers = [
  // table
  rest.get(`${process.env.REACT_APP_API_URL}/next-gen-reporting/feedback/content`, async (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: {
          data: [
            {
              id: '38239c95-ecde-4a2d-b92f-6ea60fb2e720',
              contentTitle: 'Test Video',
              contentType: 'Mandatory',
              averageRating: 4.5,
              campaignTitle: 'Test Campaign',
            },
            {
              id: '38239c95-ecde-4a2d-b92f-6ea60fb2e750',
              contentTitle: 'Test Video 2',
              contentType: 'Mandatory',
              averageRating: 4.5,
              campaignTitle: 'Test Campaign 2',
            },
            {
              id: '38239c95-ecde-4a2d-b92f-6ea60fb2e710',
              contentTitle: 'Test Video 3',
              contentType: 'Optional',
              averageRating: 4.2,
              campaignTitle: 'Test Campaign 3',
            },
            {
              id: '38239c95-ecde-4a2d-b92f-6ea60fb2e730',
              contentTitle: 'Test Video 4',
              contentType: 'Optional',
              averageRating: 4.2,
              campaignTitle: 'Test Campaign 4',
            },
          ],
          total: 4,
        },
      }),
    );
  }),

  // stats
  rest.get(`${process.env.REACT_APP_API_URL}/next-gen-reporting/feedback/stats`, async (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: {
          total: 86,
          ratings: {
            2.0: 1,
            4.0: 2,
            0.0: 2,
            5.0: 80,
            1.0: 1,
          },
          ratedPercent: 0.004,
        },
      }),
    );
  }),
];
