import { useState } from 'react';

import { setToLS, getFromLS } from '../utils/services';
import themes from '../assets/theme/themes';

const loadTheme = () => {
  const defaultTheme = themes.dark.id;
  const localTheme = getFromLS('theme');

  const isValidTheme = ['dark', 'light'].includes(localTheme);

  if (!isValidTheme) {
    setToLS('theme', defaultTheme);
  }

  return isValidTheme ? localTheme : defaultTheme;
};

export const useTheme = () => {
  const [theme, setTheme] = useState(loadTheme);

  const handleSetTheme = (mode) => {
    setToLS('theme', mode);
    setTheme(mode);
  };

  return { theme, setTheme: handleSetTheme };
};
