/**
 * Clone object
 *
 * @example cloneObject({ a: 1, b: 2})
 * return { a: 1, b: 2}
 *
 * @param {object} object
 *
 * @return {object}
 *
 */
export const cloneObject = (object) => JSON.parse(JSON.stringify(object));
