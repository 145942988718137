import React from 'react';

const Complete = (props) => (
  <svg
    width={150}
    height={150}
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width: 500,
      height: 500,
    }}
    {...props}
  >
    <style>
      {
        '\n     .tick7 {animation:tick7 3.5s infinite; stroke-dasharray: 110; stroke-dashoffset: 110;}\n     @keyframes tick7 {\n         20%{stroke-dashoffset: 110;}\n         60%{stroke-dashoffset: 0;}\n         100%{stroke-dashoffset: 0;}\n     }\n     @media (prefers-reduced-motion: reduce) {\n         .tick7 {\n             animation: none;\n         }\n     }\n    '
      }
    </style>
    <path
      className="fillCls5"
      d="M40.0741 135.923C40.0741 136.126 39.7812 144.073 39.7812 144.073L50.7314 143.823L49.751 133.422L40.0741 135.923Z"
      fill="rgba(2,2,4,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls5"
      d="M43.6062 140.697H46.9024C48.197 140.697 49.4387 141.212 50.3542 142.127C51.2697 143.043 51.784 144.284 51.784 145.579V146.247C51.784 146.579 51.652 146.898 51.417 147.133C51.182 147.368 50.8633 147.5 50.531 147.5H39.9816C39.8165 147.501 39.6529 147.468 39.5002 147.406C39.3475 147.343 39.2088 147.25 39.0919 147.133C38.9751 147.017 38.8825 146.878 38.8195 146.725C38.7565 146.573 38.7242 146.409 38.7246 146.244V145.579C38.7246 144.284 39.2389 143.043 40.1544 142.127C41.0699 141.212 42.3116 140.697 43.6062 140.697Z"
      fill="rgba(2,2,4,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls5"
      d="M25.6331 125.196L18.2984 130.511C17.9495 130.764 17.7106 131.14 17.6307 131.564C17.5509 131.987 17.6363 132.425 17.8692 132.788L26.7782 146.671C26.9411 146.924 27.165 147.133 27.4294 147.277C27.6938 147.422 27.9902 147.498 28.2915 147.498H35.1695C35.452 147.498 35.7311 147.435 35.9867 147.315C36.2423 147.195 36.4682 147.019 36.6483 146.802C36.8283 146.584 36.9582 146.329 37.0285 146.056C37.0988 145.782 37.1079 145.496 37.0551 145.219C36.8812 144.309 36.6106 143.14 35.6841 141.941C34.4342 140.322 31.7087 139.483 31.7087 139.483C31.7087 139.483 30.2462 137.999 32.8945 135.688C35.5428 133.378 25.6331 125.196 25.6331 125.196Z"
      fill="rgba(2,2,4,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls8"
      d="M34.2914 55.1016C34.2914 55.1016 34.1969 111.24 34.322 117.32C34.447 123.4 35.9095 137.239 35.9095 137.239C39.2817 137.438 42.6627 137.438 46.0348 137.239C51.3232 136.901 55.2072 135.665 55.2072 135.665L53.6624 77.1218L57.8321 95.7737L24.1387 124.52C24.1387 124.52 28.2403 129.021 29.7678 131.384C31.2953 133.748 34.446 138.585 36.5817 139.485L71.46 110.344L75.6226 56.565L34.2914 55.1016Z"
      fill="rgba(156,226,214,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls9"
      d="M34.3034 115.848L31.8809 117.916L36.3221 121.409L58.9627 107.452C58.539 106.202 58.1176 104.951 57.6986 103.699L54.5947 98.5336L54.2357 98.8387L54.2245 98.4258L34.2912 113.464C34.2922 114.393 34.2973 115.193 34.3034 115.848Z"
      fill="rgba(73,179,163,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls9"
      d="M57.0141 122.416C54.9699 122.353 52.8851 122.676 50.9212 123.051C48.6198 123.477 46.3466 124.044 44.1144 124.748C41.9146 125.46 39.7159 126.365 37.8608 127.768C35.9489 129.215 34.5759 131.204 34.4732 133.652C34.405 135.257 34.6827 136.852 35.0834 138.415C35.6132 138.911 36.1218 139.29 36.5825 139.484L39.125 137.364C40.982 137.402 43.4564 137.398 46.0406 137.233C51.329 136.896 55.213 135.659 55.213 135.659L54.9079 124.178L57.0141 122.416Z"
      fill="rgba(73,179,163,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls8"
      d="M34.2914 55.1016L53.6624 77.1167L57.8321 95.7686L24.1387 124.52C24.1387 124.52 28.2403 129.021 29.7678 131.384C31.2953 133.748 34.446 138.585 36.5817 139.485L71.46 110.344L75.6226 56.565L34.2914 55.1016Z"
      fill="rgba(156,226,214,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls9"
      d="M57.8229 95.7039L59.2223 94.4682L55.4746 85.2104L57.8229 95.7039Z"
      fill="rgba(73,179,163,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls5"
      d="M32.376 31.4046C30.1101 29.0828 29.2131 25.5131 30.1121 22.396C30.4904 21.0841 31.2522 19.7772 32.5072 19.2433C33.1682 18.9626 33.9137 18.9178 34.5625 18.6097C35.9965 17.9293 36.5569 16.2085 36.9016 14.6596C37.2464 13.1107 37.6227 11.39 38.9173 10.4726C40.0574 9.6641 41.6826 9.67325 42.6803 8.69285C43.6515 7.73687 43.68 6.19102 44.3207 4.98892C45.356 3.04542 47.8507 2.31521 50.0414 2.53895C51.394 2.67624 52.7598 3.12983 53.7361 4.07361C54.4409 4.755 54.9026 5.64895 55.2464 6.56629C55.9024 8.31351 56.1688 10.1879 56.2268 12.053C56.2593 13.1128 56.2827 14.3128 57.0699 15.0237C58.0777 15.9329 59.8707 15.5749 60.7515 16.6082C61.7786 17.8123 60.6986 19.7955 61.4186 21.2051C61.6017 21.5631 61.8895 21.8549 62.1539 22.159C63.0489 23.1862 63.7231 24.431 63.9377 25.7755C64.1523 27.12 63.8787 28.5652 63.0601 29.6533C62.0339 31.0172 60.3213 31.667 58.6605 32.0606C55.8017 32.7389 52.8452 32.8559 49.9244 33.1691C47.0036 33.4824 44.0766 33.998 41.1436 33.821C37.9215 33.6223 34.8047 32.5986 32.0922 30.8483"
      fill="rgba(2,2,4,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls6"
      d="M96.0121 144.698C96.0121 144.698 78.7921 138.172 68.5071 117.849C58.2222 97.526 59.7029 74.3454 59.7029 74.3454C59.7029 74.3454 67.6508 69.2451 75.676 66.0944C83.7012 62.9437 96.0161 60.3188 96.0161 60.3188C96.0161 60.3188 108.322 62.9448 116.356 66.0944C124.391 69.2441 132.323 74.3454 132.323 74.3454C132.323 74.3454 133.804 97.522 123.519 117.849C113.234 138.176 96.0151 144.698 96.0151 144.698"
      fill="rgba(78,179,212,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls7"
      d="M96.0119 140.122C96.0119 140.122 80.6551 134.304 71.4899 116.182C62.3246 98.0598 63.6396 77.3952 63.6396 77.3952C63.6396 77.3952 70.7251 72.8482 77.8777 70.0392C85.0303 67.2303 96.0089 64.8901 96.0089 64.8901C96.0089 64.8901 106.984 67.2293 114.139 70.0392C121.294 72.8492 128.377 77.3952 128.377 77.3952C128.377 77.3952 129.699 98.0588 120.527 116.182C111.354 134.305 96.0058 140.122 96.0058 140.122"
      fill="rgba(255,255,255,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls6"
      d="M96.0129 130.333C96.0129 130.333 84.6549 126.029 77.8715 112.623C71.0881 99.217 72.0644 83.9294 72.0644 83.9294C72.0644 83.9294 77.3071 80.5651 82.5996 78.4874C87.8921 76.4097 96.0129 74.6787 96.0129 74.6787C96.0129 74.6787 104.132 76.4076 109.425 78.4874C114.719 80.5672 119.96 83.9294 119.96 83.9294C119.96 83.9294 120.937 99.216 114.153 112.623C107.37 126.03 96.0129 130.333 96.0129 130.333Z"
      fill="rgba(78,179,212,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="strokeCls1 tick7"
      d="M83.3066 100.665L92.7841 109.787L108.721 94.4458"
      stroke="rgba(255,255,255,1)"
      strokeWidth="2px"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls2"
      d="M53.9117 24.0123C57.8872 24.537 66.4606 26.7165 69.9631 28.5125C75.0807 31.1384 77.7636 39.5389 77.7636 39.5389L71.0879 56.9399L24.4338 58.0657C24.4338 58.0657 23.5338 46.1393 25.2596 40.6637C26.9855 35.1882 31.1094 30.1631 35.5314 27.8372C39.9533 25.5113 47.0327 23.937 47.0327 23.937L53.9117 24.0123Z"
      fill="rgba(23,48,66,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls3"
      d="M77.7626 39.5381L64.4866 45.7133L68.5373 55.0139C68.5373 55.0139 54.8769 63.5852 52.9863 65.0151C50.4234 66.9474 47.7233 73.1786 46.7825 75.0906C45.8418 77.0026 47.7172 78.0663 48.9182 76.1788C50.1702 74.2139 51.1679 72.7657 51.1679 72.7657C51.1679 72.7657 50.8302 73.6475 50.8119 74.2282C50.7936 74.8089 51.3204 77.3229 53.212 78.8972C54.3958 79.8817 55.1749 79.2023 55.0813 78.1914C55.0289 77.7586 54.9536 77.3288 54.8555 76.9039C54.8555 76.9039 55.5674 79.2064 57.0228 79.303C58.7435 79.4169 57.0228 75.0906 57.2262 74.4214C57.4296 73.7522 58.4069 71.9643 58.4069 71.9643C58.4069 71.9643 57.6757 73.7085 57.8262 74.3838C57.9767 75.0591 59.2887 77.1968 60.3758 78.3216C61.463 79.4464 62.6448 78.1904 61.9512 76.9904C61.2576 75.7903 60.6199 74.1021 60.6199 74.1021C60.6199 74.1021 69.3204 69.5459 71.3829 68.42C73.4454 67.2942 81.6547 64.0012 82.4469 60.2077C83.4639 55.3271 79.9003 44.8631 77.7626 39.5381Z"
      fill="rgba(255,185,177,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls3"
      d="M24.1988 49.4771C24.1988 49.4771 24.0635 75.5124 24.1988 80.4174C24.3341 85.3225 25.1833 89.7179 25.8585 91.8933C26.5338 94.0687 27.4858 94.1623 28.0146 93.0375C28.0146 93.0375 28.1834 94.1623 29.0835 94.4613C29.9835 94.7603 30.3039 93.5795 30.3039 93.5795C30.3039 93.5795 30.5469 94.686 31.3534 94.6484C32.1599 94.6108 32.4976 93.3548 32.4976 93.3548C32.4976 93.3548 33.0223 94.4053 33.7353 94.2924C34.4482 94.1796 34.8418 93.5429 34.56 91.3106C34.2783 89.0783 34.3566 86.3273 34.3566 86.3273C34.3566 86.3273 34.2753 88.6562 35.6259 90.3821C36.9765 92.1079 37.9385 90.2092 37.4199 88.9582C37.0273 88.0155 36.832 86.5469 36.6825 85.4597C36.6195 85.0021 35.9971 78.2563 36.174 71.002C36.4202 61.0028 37.4117 49.9622 37.4117 49.9622L24.1988 49.4771Z"
      fill="rgba(255,185,177,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls3"
      d="M54.6914 6.78585C55.4663 9.36091 57.9672 17.261 53.0164 16.5359L53.9164 24.0119C53.9164 24.0119 52.9116 25.1652 50.4362 25.1652C47.9608 25.1652 47.0364 23.9397 47.0364 23.9397L48.211 11.4356C48.211 11.4356 47.9364 12.3611 47.0364 12.3611C46.1363 12.3611 43.9609 9.46058 45.2332 8.18119C46.5055 6.90179 47.734 8.28289 47.734 8.28289L48.3859 5.63867C48.3859 5.63867 51.1858 7.03807 52.8109 7.06248C54.4361 7.08689 54.6914 6.78585 54.6914 6.78585Z"
      fill="rgba(255,185,177,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls8"
      d="M52.8187 9.64844L52.7363 12.2479L54.9483 12.4981L52.8187 9.64844Z"
      fill="rgba(156,226,214,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls8"
      d="M51.1419 12.1485C51.5806 12.1485 51.9362 11.975 51.9362 11.761C51.9362 11.547 51.5806 11.3735 51.1419 11.3735C50.7033 11.3735 50.3477 11.547 50.3477 11.761C50.3477 11.975 50.7033 12.1485 51.1419 12.1485Z"
      fill="rgba(156,226,214,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls8"
      d="M55.9282 12.0722C55.9017 11.8465 55.8702 11.6176 55.8346 11.3898C55.7612 11.3786 55.6871 11.3731 55.6129 11.3736C55.198 11.3736 54.8613 11.5373 54.8613 11.7397C54.8613 11.9421 55.198 12.1068 55.6129 12.1068C55.719 12.1073 55.8247 12.0957 55.9282 12.0722V12.0722Z"
      fill="rgba(156,226,214,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls4"
      d="M53.3697 19.5217L53.0097 16.5358C51.472 16.3608 49.6719 14.3105 49.6719 14.3105V17.5111C50.5475 18.6237 53.3697 19.5217 53.3697 19.5217Z"
      fill="#FD8571"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls4"
      d="M68.5364 55.0141L69.886 54.2015L66.1465 49.5273L68.5364 55.0141Z"
      fill="#FD8571"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls1"
      d="M51.384 10.0509C51.5174 10.0509 51.6255 9.9057 51.6255 9.72661C51.6255 9.54752 51.5174 9.40234 51.384 9.40234C51.2507 9.40234 51.1426 9.54752 51.1426 9.72661C51.1426 9.9057 51.2507 10.0509 51.384 10.0509Z"
      fill="rgba(0,0,0,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls1"
      d="M54.476 10.0508C54.589 10.0508 54.6806 9.92746 54.6806 9.77538C54.6806 9.62329 54.589 9.5 54.476 9.5C54.3631 9.5 54.2715 9.62329 54.2715 9.77538C54.2715 9.92746 54.3631 10.0508 54.476 10.0508Z"
      fill="rgba(0,0,0,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls1"
      d="M52.0328 14.0714C52.4341 14.3912 52.9197 14.5875 53.4305 14.6365C53.9414 14.6854 54.4554 14.5849 54.9102 14.3472"
      fill="rgba(0,0,0,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
  </svg>
);

export default Complete;
