import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation, matchPath } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { keyBy } from 'lodash';

import { TooltipWrapper, Img, ErrorWarningFillIconReactComponent } from '@livingsecurity/shared';

import * as Styled from './styles';
import { useSidebarIcons } from './_hooks';

function SidebarDropdown({ title, nestedRoutes, icon, iconClass, dark, isAlwaysOpen, userGroups }) {
  const { t } = useTranslation('sidebar');
  const { pathname } = useLocation();
  const {
    adminAnalyticsRisk,
    adminAnalyticsTraining,
    adminAnalyticsParticipants,
    adminAnalyticsCampaigns,
    adminAnalyticsQuestions,
  } = useFlags();

  const isTitleHighlighted = nestedRoutes.some((item) => matchPath(pathname, { path: item.path }));

  const [isOpen, setOpen] = useState(isTitleHighlighted);

  const { data: iconsByPath = {} } = useSidebarIcons();

  const toggleDropdown = () => setOpen((prev) => !prev);

  const displayItem = (routeTitle, sectionTitle) => {
    // ! This should change if/when we add a new section to the sidebar with a feature flag
    if (sectionTitle !== t('analytics')) return true;
    switch (routeTitle) {
      case t('risk'):
        return !adminAnalyticsRisk;
      case t('training'):
        return !adminAnalyticsTraining;
      case t('participants'):
        return !adminAnalyticsParticipants;
      case t('campaigns'):
        return !adminAnalyticsCampaigns;
      case t('questions'):
        return !adminAnalyticsQuestions;
      default:
        return true;
    }
  };

  const iconsToShow = useMemo(() => {
    const nestedPathsMap = keyBy(nestedRoutes, 'path');
    return Object.entries(iconsByPath).filter(([p, s]) => !!nestedPathsMap[p] && s); // filter by path group and only truthy values;
  }, [nestedRoutes, iconsByPath]);

  return (
    <Styled.Wrapper highlighted={isTitleHighlighted} isOpen={isOpen || isAlwaysOpen} dark={dark}>
      <Styled.Title onClick={toggleDropdown} aria-label={title} data-testid={`${title} menu`}>
        {iconClass ? (
          <i className={iconClass} style={{ fontSize: '14px', marginRight: '14px' }} />
        ) : (
          <Img size={[28, 28]} src={icon} />
        )}
        <TooltipWrapper content={title} showTooltip={title?.length > 18}>
          <Styled.TitleLabel>{title}</Styled.TitleLabel>
        </TooltipWrapper>
        {!isOpen && iconsToShow.length > 0 && <ErrorWarningFillIconReactComponent />}
      </Styled.Title>
      <Styled.List>
        {nestedRoutes.map((route, id) => {
          const allowedGroups = route.groups?.filter((value) => userGroups.includes(value)) || userGroups;
          return !!allowedGroups?.length && displayItem(route.title, title) ? (
            <Styled.ListItem
              dark={dark}
              isActiveRoute={matchPath(pathname, { path: route.path })}
              key={id}
              data-testid={`${route.title}-menu-item`}
            >
              {!!iconsByPath[route.path] && <ErrorWarningFillIconReactComponent />}
              <Styled.Link to={{ pathname: route.link, state: { from: pathname } }}>
                <TooltipWrapper content={route.title} showTooltip={route.title?.length > 18}>
                  <Styled.TitleLabel>{route.title}</Styled.TitleLabel>
                </TooltipWrapper>
              </Styled.Link>
            </Styled.ListItem>
          ) : null;
        })}
      </Styled.List>
    </Styled.Wrapper>
  );
}

SidebarDropdown.propTypes = {
  /**
   * @type {string} - title of the dropdown
   */
  title: PropTypes.string.isRequired,

  /**
   * @type {array} - nested routes
   * @example
   */
  nestedRoutes: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }),
  ).isRequired,

  /**
   * @type {string} - icon of the dropdown
   */
  icon: PropTypes.string.isRequired,

  /**
   * @type {boolean} - if the dropdown is dark
   * @default false
   */
  dark: PropTypes.bool,

  userGroups: PropTypes.array.isRequired,
  iconClass: PropTypes.string,
  isAlwaysOpen: PropTypes.bool,
};

SidebarDropdown.defaultProps = {
  dark: false,
  iconClass: null,
  isAlwaysOpen: false,
};

export default SidebarDropdown;
