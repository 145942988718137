import React from 'react';
import PropTypes from 'prop-types';

const ListView = ({ color, strokeWidth, style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={strokeWidth}
    stroke={color}
    style={style}
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12" />
  </svg>
);

ListView.propTypes = {
  color: PropTypes.string,
  strokeWidth: PropTypes.number,
  style: PropTypes.object,
};

ListView.defaultProps = {
  color: '#000',
  strokeWidth: 1.5,
  style: { width: '1.5rem', height: '1.5rem' },
};

export default ListView;
