import styled from 'styled-components';

/* eslint-disable-next-line */
export const Text = styled.p`
  &:not(:last-of-type) {
    margin: 0 0 33px;
  }

  b {
    word-break: break-word;
  }
`;
