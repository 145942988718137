import { useEffect } from 'react';
import { useAuth0, notification } from '@livingsecurity/shared';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LOCALHOST = 'localhost';
const SUBDOMAIN_REGEXP = /(?:http[s]*:\/\/)*(.*?)\.(?=[^/]*\..{2,5})/i; // parse subdomain
const USER_BLOCKED_MSG = 'user is blocked';
const USER_UNAUTHORIZED_MSG = 'No tenants or permissions assigned';

const LoginLayout = () => {
  const { t } = useTranslation('shared');
  const { loginWithRedirect, logout } = useAuth0();
  const { state = {} } = useLocation();
  const currentHref = window.location.href;
  const subdomain = currentHref.includes(LOCALHOST) ? '' : SUBDOMAIN_REGEXP.exec(currentHref)[1];

  useEffect(() => {
    if (state.error) {
      let errorMsg = t('messages.error.loginFailed');
      if (state.error_description === USER_BLOCKED_MSG) {
        errorMsg = t('messages.error.blockedUser');
      } else if (state.error_description === USER_UNAUTHORIZED_MSG) {
        errorMsg = t('messages.error.unAuthorizedUser');
      }
      notification.error(errorMsg);
      setTimeout(() => logout(state), 2000);
    } else {
      loginWithRedirect();
    }
  }, []); // eslint-disable-line

  return null;
};

export default LoginLayout;
