import React from 'react';
import { isEmpty } from 'lodash';
import { Redirect, useLocation } from 'react-router-dom';

import { useAuth0, URLS, useUserLandingPage } from '@livingsecurity/shared';

// TODO: move redirect logic to special component
const Home = () => {
  const { isAuthenticated } = useAuth0();
  const location = useLocation();
  const userLandingPage = useUserLandingPage();

  const redirectingPayload = isEmpty(location.search)
    ? {}
    : location.search
        .replace('?', '')
        .split('&')
        .reduce((acc, pair) => {
          const splittedPair = pair.split('=');
          return {
            ...acc,
            [splittedPair[0]]: decodeURI(splittedPair[1]),
          };
        }, {});

  return (
    <Redirect
      to={
        isAuthenticated
          ? userLandingPage
          : {
              pathname: URLS.login,
              state: {
                ...redirectingPayload,
              },
            }
      }
    />
  );
};

export default Home;
