import { PUZZLE_STATE } from '@livingsecurity/shared';

const puzzleReducer = (state, { type, payload }) => {
  switch (type) {
    case PUZZLE_STATE.UPDATE_STATE:
      return payload;

    default:
      return state;
  }
};

export default puzzleReducer;
