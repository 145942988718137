import React, { useState, createContext, useContext } from 'react';
import { Info, Warning, ErrorModal } from './_containers';

export const MODAL_TYPES = {
  INFO: 'INFO',
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
};

export const MODAL_COMPONENTS = {
  [MODAL_TYPES.INFO]: Info,
  [MODAL_TYPES.SUCCESS]: 'Success',
  [MODAL_TYPES.WARNING]: Warning,
  [MODAL_TYPES.ERROR]: ErrorModal,
};

const initialState = {
  showModal: () => {},
  hideModal: () => {},
  store: {},
};

const ModalContext = createContext(initialState);
export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [store, setStore] = useState({});
  const { modalType, modalProps } = store;

  // eslint-disable-next-line no-shadow
  const showModal = (modalType = '', modalProps = {}) => {
    setStore({ ...store, modalType, modalProps });
  };

  const hideModal = () => {
    setStore({ ...store, modalType: null, modalProps: {} });
  };

  const renderComponent = () => {
    const ModalComponent = MODAL_COMPONENTS[modalType];

    if (!modalType || !ModalComponent) {
      return null;
    }

    return <ModalComponent id="global-modal" data-testid="global-modal" onClose={hideModal} {...modalProps} />;
  };

  return (
    <ModalContext.Provider value={{ store, showModal, hideModal }}>
      {renderComponent()}
      {children}
    </ModalContext.Provider>
  );
};
