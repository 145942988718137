import { createGlobalStyle, css } from 'styled-components';
import { BREAKPOINTS } from '../../_constants';

export const GlobalStyles = createGlobalStyle`

  .options-content-popover{
    &.fade{
      transition: initial;
    }
  }

  .navbar-item-popover {

    &.fade{
      transition: initial;
    }

    .popover {
      min-width: 270px;
      max-width: 600px;
      max-height: 725px;
      border-color: ${({ theme }) => theme.secondaryColor};
      position: relative;
      z-index: 6;
      top: 15px !important;

      .popover-body {
        height: 100%;
        padding: 16px;

        ${({ theme }) => css`
          background: ${theme.id === 'dark' ? theme.secondaryBgColor : theme.bgColor};
          color: ${theme.color};
        `}
      }
    }
  }

  .bs-popover-bottom > .arrow::after {
    display: none !important;
    background: transparent !important;
    border-bottom-color: transparent !important;
  }

  .bs-popover-auto[x-placement^='bottom'] > .arrow,
  .bs-popover-bottom > .arrow {
    visibility: hidden;
  }


  .App {
    height: 100vh;
    overflow-x: hidden;
    margin-right: -10px;
    padding-right: 10px;
  }

  @media (max-width: ${BREAKPOINTS.PHONE}) {
    .App {
      height: 100vh;
      margin-right: -10px;
      padding-right: 10px;
    }
  }
`;
