import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { json2csv } from 'json-2-csv';
import { useAuth0, USER_GROUPS } from '@livingsecurity/shared';

const PAGE_SIZE = 50;
const SERIES_TYPE = 'series';
const MODULE_TYPE = 'independentModule';

// This is an experimental view for internal Content Team usage
const ContentExport = () => {
  const { loading, isAuthenticated, user, isUserHasRights } = useAuth0();
  const isLsAdmin = isUserHasRights([USER_GROUPS.TEAMS_LS_ADMIN]);
  const [content, setContent] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(PAGE_SIZE);
  const [contentType, setContentType] = useState(SERIES_TYPE);
  const [hasCustomerId, setCustomerId] = useState(true);
  const [errorString, setError] = useState('');
  const MAX_PAGE = Math.ceil(total / size);

  useEffect(() => {
    async function fetchContentful(newPage, newContentType, newSize, newCustomerId) {
      try {
        const result = await axios.get(
          `https://cdn.contentful.com/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/environments/${
            process.env.REACT_APP_CONTENTFUL_ENVIRONMENT
          }/entries?access_token=${
            process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN
          }&content_type=${newContentType}&fields.customerId[exists]=${newCustomerId}&limit=${newSize}&skip=${
            newPage * newSize
          }`,
        );
        setContent(result?.data || []);
        setTotal(result?.data.total || 0);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        setError(error.message);
      }
    }

    fetchContentful(page, contentType, size, hasCustomerId);
  }, [page, contentType, size, hasCustomerId]);

  const setCurrentType = ({ target: { value } }) => {
    setContentType(value === SERIES_TYPE ? SERIES_TYPE : MODULE_TYPE);
  };
  const setPageSize = ({ target: { value } }) => {
    setSize(value);
  };
  const setHasCustomerId = ({ target: { value } }) => {
    setCustomerId(value);
  };
  const nextPage = () => {
    setPage(page + 1);
  };
  const prevPage = () => {
    setPage(page - 1);
  };
  const exportToCSV = (items) => {
    const exportFields = items.map((item) => {
      return {
        id: item.sys?.id,
        title: item.fields?.title || 'MISSING',
        categories: item.fields?.categories || '',
        concepts: item.fields?.concepts || '',
        type: item.fields?.type || '',
        customerId: item.fields?.customerId || '',
      };
    });

    json2csv(
      exportFields,
      (error, result) => {
        const blob = new Blob([result], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', `${contentType}.csv`);
        a.click();
      },
      {},
    );
  };

  if (loading || !isAuthenticated || !isLsAdmin) {
    return null;
  }

  return (
    <div
      style={{
        background: 'white',
        height: '100vh',
        width: '100vw',
        padding: '12px',
        userSelect: 'text',
        overflow: 'auto',
      }}
    >
      {errorString && (
        <div
          style={{
            color: 'red',
            width: '100%',
            textAlign: 'center',
          }}
        >{`${JSON.stringify(errorString)} - Please check there's a valid Access Token and Environment set`}</div>
      )}
      <div>{`Hey ${user.nickname}!`}</div>
      <div style={{ display: 'flex', flexDirection: 'column', margin: '20px' }}>
        <div style={{ display: 'flex', margin: '20px 0 50px', width: '90%', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', minWidth: '900px', alignItems: 'center' }}>
            {!!page && (
              <i
                className="fas fa-lg fa-chevron-left"
                onClick={prevPage}
                role="button"
                tabIndex={0}
                style={{ cursor: 'pointer' }}
              />
            )}
            <div style={{ margin: ' 0 30px' }}>{`Page ${page + 1} of ${MAX_PAGE} (${total} Entries)`}</div>
            {page + 1 < MAX_PAGE && (
              <i
                className="fas fa-lg fa-chevron-right"
                style={{ marginLeft: '10px', cursor: 'pointer' }}
                onClick={nextPage}
                role="button"
                tabIndex={0}
              />
            )}
            <div style={{ marginLeft: '50px' }}>
              <div>Content Type</div>
              <select name="contentType" id="contentType" onChange={(e) => setCurrentType(e)}>
                <option value="series" selected>
                  Series
                </option>
                <option value="module">Module</option>
              </select>
            </div>
            <div style={{ marginLeft: '50px' }}>
              <div>Page Size</div>
              <select name="pageSize" id="pageSize" onChange={(e) => setPageSize(e)}>
                <option value="10">10</option>
                <option value="50" selected>
                  50
                </option>
                <option value="100">100</option>
                <option value="1000">1000</option>
              </select>
            </div>
            <div style={{ marginLeft: '50px' }}>
              <div>Customized</div>
              <select name="customerId" id="customerId" onChange={(e) => setHasCustomerId(e)}>
                <option value="true" selected>
                  True
                </option>
                <option value="false">False</option>
              </select>
            </div>
          </div>
          <i
            className="fas fa-lg fa-download"
            style={{ float: 'right', cursor: 'pointer' }}
            onClick={() => exportToCSV(content?.items)}
            role="button"
            tabIndex={0}
          />
        </div>
        <div
          style={{
            width: '100%',
            minWidth: '1200px',
            fontSize: '11px',
            paddingBottom: '80px',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'flex-start', fontWeight: 'bold' }}>
            <div style={{ width: '150px', textAlign: 'center' }}>ID</div>
            <div style={{ width: '230px', textAlign: 'center' }}>Title</div>
            <div style={{ width: '510px', textAlign: 'center' }}>Categories</div>
            <div style={{ width: '510px', textAlign: 'center' }}>Concepts</div>
            <div style={{ width: '180px', textAlign: 'center' }}>Type</div>
            <div style={{ width: '380px', textAlign: 'center' }}>Customer ID</div>
          </div>
          {content?.items?.map((item, index) => (
            <div
              style={{ display: 'flex', justifyContent: 'flex-start', border: '1px solid', padding: '3px 5px' }}
              key={index}
            >
              <div
                style={{
                  width: '150px',
                  textAlign: 'center',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {item.sys?.id}
              </div>
              <div
                style={{
                  width: '200px',
                  margin: '0  30px',
                  textAlign: 'center',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {item.fields?.title}
              </div>
              <div
                style={{
                  width: '450px',
                  margin: '0  30px',
                  textAlign: 'center',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {JSON.stringify(item.fields?.categories)}
              </div>
              <div
                style={{
                  width: '450px',
                  margin: '0  30px',
                  textAlign: 'center',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {JSON.stringify(item.fields?.concepts)}
              </div>
              <div
                style={{
                  width: '200px',
                  margin: '0  30px',
                  textAlign: 'center',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {JSON.stringify(item.fields?.type)}
              </div>
              <div
                style={{
                  width: '270px',
                  margin: '0  30px',
                  textAlign: 'center',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {item.fields?.customerId}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContentExport;
