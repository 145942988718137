import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TooltipWrapper, Img } from '@livingsecurity/shared';
import { useFlags } from 'launchdarkly-react-client-sdk';

import * as Styled from './styles';

function TeamsSidebarDropdown({
  title,
  nestedRoutes,
  icon,
  iconClass,
  activeNestedRoute,
  setActiveNestedRoute,
  dark,
  isOpen,
  userGroups,
}) {
  const [_isOpen, setOpen] = useState(
    nestedRoutes.some((item) => activeNestedRoute === item.path || activeNestedRoute?.includes(item.link)),
  );
  const toggleDropdown = () => setOpen((prev) => !prev);
  const isTitleHighlighted = nestedRoutes.some((item) => activeNestedRoute === item.path);
  const {
    adminAnalyticsRisk,
    adminAnalyticsTraining,
    adminAnalyticsParticipants,
    adminAnalyticsCampaigns,
    adminAnalyticsQuestions,
  } = useFlags();

  const displayItem = (routeTitle, sectionTitle) => {
    // ! This should change if/when we add a new section to the sidebar with a feature flag
    if (sectionTitle !== 'Analytics') return true;
    switch (routeTitle) {
      case 'Risk':
        return !adminAnalyticsRisk;
      case 'Training':
        return !adminAnalyticsTraining;
      case 'Participants':
        return !adminAnalyticsParticipants;
      case 'Campaigns':
        return !adminAnalyticsCampaigns;
      case 'Questions':
        return !adminAnalyticsQuestions;
      default:
        return true;
    }
  };

  return (
    <Styled.Wrapper highlighted={isTitleHighlighted} isOpen={_isOpen || isOpen} dark={dark}>
      <Styled.Title onClick={toggleDropdown} aria-label={title} data-testid={`${title} menu`}>
        {iconClass ? (
          <i className={iconClass} style={{ fontSize: '14px', marginRight: '14px' }} />
        ) : (
          <Img size={[28, 28]} src={icon} />
        )}
        <TooltipWrapper content={title} showTooltip={title?.length > 18}>
          <Styled.TitleLabel>{title}</Styled.TitleLabel>
        </TooltipWrapper>
      </Styled.Title>
      <Styled.List>
        {nestedRoutes.map((route, id) => {
          const allowedGroups = route.groups?.filter((value) => userGroups.includes(value)) || userGroups;
          return !!allowedGroups?.length && displayItem(route.title, title) ? (
            <Styled.ListItem
              dark={dark}
              isActiveRoute={activeNestedRoute === route.path || activeNestedRoute?.includes(route.link)}
              key={id}
              data-testid={`${route.title}-menu-item`}
              onClick={() => setActiveNestedRoute(route.path)}
            >
              <Styled.Link to={route.link}>
                <TooltipWrapper content={route.title} showTooltip={route.title?.length > 18}>
                  <Styled.TitleLabel>{route.title}</Styled.TitleLabel>
                </TooltipWrapper>
              </Styled.Link>
            </Styled.ListItem>
          ) : (
            <></>
          );
        })}
      </Styled.List>
    </Styled.Wrapper>
  );
}

TeamsSidebarDropdown.propTypes = {
  /**
   * @type {string} - title of the dropdown
   */
  title: PropTypes.string.isRequired,

  /**
   * @type {array} - nested routes
   * @example
   */
  nestedRoutes: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }),
  ).isRequired,

  /**
   * @type {string} - icon of the dropdown
   */
  icon: PropTypes.string.isRequired,

  /**
   * @type {string} - active nested route
   * @example
   * '/campaigns/new'
   * '/campaigns/:id'
   */
  activeNestedRoute: PropTypes.string.isRequired,

  /**
   * @type {function} - function to set active nested route
   */
  setActiveNestedRoute: PropTypes.func.isRequired,

  /**
   * @type {boolean} - if the dropdown is dark
   * @default false
   */
  dark: PropTypes.bool,

  userGroups: PropTypes.array.isRequired,
  iconClass: PropTypes.string,
  isOpen: PropTypes.bool,
};

TeamsSidebarDropdown.defaultProps = {
  dark: false,
  iconClass: null,
  isOpen: true,
};

export default TeamsSidebarDropdown;
