import { LS_EMAIL_RULE } from '../../_constants';
import isValidEmail from './isValidEmail';
/**
 * Validate email string by specific rule
 * @param  {string} email
 *
 * @returns {boolean}
 */
const lsEmailRule = new RegExp(LS_EMAIL_RULE);
export default (email) => isValidEmail(email) && !!email.match(lsEmailRule);
