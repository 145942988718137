import React from 'react';

const Analytics = (props) => (
  <svg
    width={150}
    height={150}
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width: 500,
      height: 500,
      transform: 'rotateY(0deg)',
    }}
    {...props}
  >
    <style>
      {
        '\n     .analysis1 {animation:analysis1 6s infinite;}\n     @keyframes analysis1 {\n         0%{transform: translate3d(0, -4px, 0);}\n         30%{transform: translate3d(0, 4px, 0);}\n         100%{transform: translate3d(0, -4px, 0);}\n     }\n     @media (prefers-reduced-motion: reduce) {\n         .analysis1 {\n             animation: none;\n         }\n     }\n    '
      }
    </style>
    <g
      className="analysis1"
      style={{
        animationDuration: '2s',
      }}
    >
      <path
        className="fillCls1"
        d="M60.6753 20.4161H143.633C144.658 20.4161 145.642 20.8235 146.367 21.5486C147.092 22.2738 147.5 23.2573 147.5 24.2828V95.965H56.8086V24.2828C56.8086 23.2573 57.216 22.2738 57.9411 21.5486C58.6663 20.8235 59.6498 20.4161 60.6753 20.4161Z"
        fill="#FFFFFF"
        strokeWidth="2px"
      />
      <path
        className="strokeCls1"
        d="M60.6753 20.4161H143.633C144.658 20.4161 145.642 20.8235 146.367 21.5486C147.092 22.2738 147.5 23.2573 147.5 24.2828V95.965H56.8086V24.2828C56.8086 23.2573 57.216 22.2738 57.9411 21.5486C58.6663 20.8235 59.6498 20.4161 60.6753 20.4161Z"
        stroke="#000000"
        strokeWidth="2px"
        strokeMiterlimit={10}
      />
      <path
        className="strokeCls2"
        d="M144.27 27.3275H60.0469"
        stroke="#231F20"
        strokeWidth="2px"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="strokeCls2"
        d="M64.9473 37.3336C65.0691 39.0785 65.2102 40.8901 66.0705 42.4135C66.9309 43.937 68.7328 45.0806 70.4206 44.604C72.354 44.0569 73.3622 41.704 75.3072 41.2062C77.1748 40.7286 78.9931 42.1941 80.0594 43.7998C81.7539 46.3508 82.4558 49.421 83.783 52.1809C85.1103 54.9407 87.4429 57.5943 90.4976 57.8089C93.1753 57.9974 95.6606 56.2139 97.1841 54.005C98.7076 51.7961 99.4925 49.1716 100.485 46.6824C101.907 43.1057 102.123 33.7917 108.851 33.7917C112.997 33.7917 116.06 43.5736 116.842 46.0512C117.338 47.6191 117.967 49.3495 119.441 50.0793C121.042 50.871 123.038 50.1054 124.3 48.842C125.563 47.5785 126.306 45.9062 127.234 44.3807C128.162 42.8553 129.416 41.3705 131.143 40.9171C132.793 40.485 134.576 41.1027 135.929 42.1409C137.281 43.1792 138.284 44.5973 139.266 45.9922"
        stroke="#231F20"
        strokeWidth="2px"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="fillCls3"
        d="M119.479 69.9562C118.24 70.2362 117.082 70.7964 116.093 71.5939C115.104 72.3913 114.311 73.4047 113.775 74.5563C113.239 75.7079 112.974 76.967 113 78.237C113.026 79.5071 113.343 80.7542 113.926 81.8826C114.51 83.011 115.344 83.9908 116.365 84.7467C117.386 85.5025 118.566 86.0144 119.816 86.2429C121.065 86.4715 122.351 86.4107 123.573 86.0651C124.795 85.7195 125.922 85.0984 126.867 84.2495L121.309 78.0628L119.479 69.9562Z"
        fill="rgba(57,57,96,1)"
        strokeWidth="2px"
        style={{
          animationDuration: '2s',
        }}
      />
      <path
        className="fillCls5"
        d="M129.408 79.9594C129.727 78.5877 129.693 77.1575 129.308 75.8028C128.923 74.4481 128.201 73.2131 127.209 72.2136C126.217 71.2141 124.988 70.4826 123.636 70.0878C122.284 69.693 120.855 69.6477 119.48 69.9562L121.31 78.0676L129.408 79.9594Z"
        fill="rgba(78,179,212,1)"
        strokeWidth="2px"
        style={{
          animationDuration: '2s',
        }}
      />
      <path
        className="fillCls12"
        d="M129.408 79.9594L121.307 78.0705L126.865 84.2572C128.134 83.1197 129.022 81.6191 129.408 79.9594V79.9594Z"
        fill="#222222"
        strokeWidth="2px"
      />
      <path
        className="strokeCls2"
        d="M67.9336 70.7678H96.5744"
        stroke="#231F20"
        strokeWidth="2px"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="strokeCls2"
        d="M67.9336 75.3897H96.5744"
        stroke="#231F20"
        strokeWidth="2px"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="strokeCls2"
        d="M67.9336 80.2638H84.9665"
        stroke="#231F20"
        strokeWidth="2px"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <path
      className="fillCls2"
      d="M11.5172 100.568V127.363H59.1996V107.91H83.6653V86.729H2.5V100.568H11.5172Z"
      fill="rgba(164,138,117,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls8"
      d="M6.8893 86.729H6.50263C6.43037 91.3498 6.63114 95.9709 7.1039 100.568H7.49057C7.01782 95.9709 6.81705 91.3498 6.8893 86.729V86.729Z"
      fill="#551C12"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls8"
      d="M9.99168 100.568C10.1802 99.0774 10.185 97.6022 10.1396 96.2876C10.1183 95.636 10.0854 94.9729 10.0526 94.332C9.92595 91.8099 9.79738 89.2086 10.3426 86.729H9.94335C9.41071 89.2298 9.54024 91.8312 9.66688 94.3513C9.69878 94.9912 9.73164 95.6525 9.75388 96.3001C9.79738 97.6119 9.79351 99.0851 9.60211 100.568H9.99168Z"
      fill="#551C12"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls8"
      d="M23.6059 126.863C22.952 122.059 22.762 117.203 23.0385 112.362V112.318C23.0859 111.497 23.1352 110.65 22.8239 109.866C22.5126 109.083 21.6716 108.329 20.7339 108.497C19.7122 108.681 19.1196 109.743 19.021 111.569L18.3443 124.121C18.2873 125.175 18.179 126.312 17.9219 127.367H18.3192C18.5676 126.31 18.674 125.186 18.73 124.141L19.4067 111.589C19.5169 109.536 20.2264 108.979 20.8016 108.878C21.5102 108.75 22.2033 109.353 22.4633 110.01C22.7427 110.712 22.6963 111.517 22.6518 112.296V112.341C22.3737 117.207 22.5646 122.089 23.2222 126.919C23.2396 127.049 23.2637 127.201 23.2947 127.367H23.6881C23.6533 127.176 23.6253 127.005 23.6059 126.863Z"
      fill="#551C12"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls8"
      d="M77.8383 94.9439C77.7417 95.8352 77.3453 97.9261 75.8972 98.0952C75.0659 98.1919 74.3264 97.5384 73.989 96.8908C73.5966 96.1339 73.526 95.2165 73.4641 94.4074L72.9711 87.9248C72.9382 87.4975 72.8912 87.099 72.83 86.729H72.4375C72.5019 87.1054 72.5512 87.514 72.5854 87.9548L73.0832 94.4373C73.148 95.2832 73.2215 96.2421 73.6507 97.0686C74.2191 98.1619 75.2128 98.5651 75.9466 98.479C76.66 98.3959 77.9041 97.8313 78.2279 94.9874C78.5387 92.2463 78.6501 89.4863 78.5614 86.729H78.1747C78.262 89.4718 78.1496 92.2174 77.8383 94.9439V94.9439Z"
      fill="#551C12"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls8"
      d="M14.6299 117.958C14.6879 114.264 15.0166 110.499 15.3288 106.858C15.903 100.236 16.4956 93.3953 15.4902 86.729H15.0987C16.1089 93.3692 15.5173 100.206 14.9441 106.824C14.628 110.472 14.3012 114.244 14.2432 117.952C14.1959 121.095 14.3383 124.238 14.6695 127.363H15.0562C14.7245 124.24 14.5821 121.099 14.6299 117.958V117.958Z"
      fill="#551C12"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls8"
      d="M61.3184 104.809L61.1899 95.9705C61.1754 94.97 61.0342 93.5828 59.9844 93.0965C59.0255 92.6528 57.9186 93.2957 57.443 94.1454C56.9674 94.9951 56.95 96.0565 56.9404 96.9014L56.5856 126.658C56.5856 126.912 56.5701 127.147 56.5508 127.363H56.9375C56.9607 127.113 56.9713 126.874 56.9732 126.663L57.328 96.9062C57.3377 96.0633 57.3493 95.1072 57.7814 94.3348C58.1748 93.6321 59.0883 93.1072 59.823 93.4474C60.6659 93.8341 60.7897 95.0038 60.8042 95.9763L60.9327 104.815C60.9463 105.807 60.9627 106.924 61.4035 107.908H61.8327C61.3494 106.962 61.3329 105.82 61.3184 104.809Z"
      fill="#551C12"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls8"
      d="M28.5101 127.363C28.5391 126.88 28.5217 126.402 28.5053 125.951L27.2399 91.2009C27.1886 89.7799 27.1335 88.2226 27.3868 86.729H26.9934C26.7469 88.238 26.8 89.7934 26.8542 91.2144L28.1166 125.966C28.1331 126.412 28.1495 126.889 28.1166 127.363H28.5101Z"
      fill="#551C12"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls8"
      d="M38.0419 102.129C38.0747 101.743 38.107 101.356 38.1385 100.969C38.5252 96.5225 38.9303 91.924 38.0119 87.5024C37.9558 87.2327 37.8959 86.9755 37.8331 86.729H37.4338C37.5054 87.0007 37.5711 87.2849 37.633 87.5826C38.5407 91.9491 38.1405 96.5186 37.7528 100.937C37.7187 101.324 37.6865 101.711 37.6562 102.097C36.9408 110.5 36.6524 118.933 36.792 127.364H37.1786C37.0387 118.943 37.3268 110.521 38.0419 102.129V102.129Z"
      fill="#551C12"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls8"
      d="M53.0699 125.769C54.7596 112.831 54.8988 99.6013 54.9307 86.729H54.5441C54.5122 99.5859 54.373 112.805 52.6861 125.72C52.6019 126.264 52.5567 126.813 52.5508 127.363H52.9375C52.9438 126.83 52.988 126.297 53.0699 125.769V125.769Z"
      fill="#551C12"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls8"
      d="M66.2738 105.636L66.4672 89.3072C66.4778 88.4816 66.4884 87.5874 66.6518 86.729H66.2641C66.1075 87.599 66.0959 88.4816 66.0853 89.3023L65.892 105.631C65.8804 106.569 65.7653 107.329 65.5469 107.91H65.9567C66.1565 107.304 66.2622 106.546 66.2738 105.636Z"
      fill="#551C12"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls8"
      d="M40.1043 89.4493C40.1178 88.4642 40.1536 87.5497 40.3614 86.729H39.967C39.7418 87.6706 39.7282 88.6489 39.7176 89.4435C39.5429 102.101 40.0412 114.759 41.2102 127.363H41.5968C40.4274 114.761 39.9291 102.105 40.1043 89.4493V89.4493Z"
      fill="#551C12"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls8"
      d="M45.201 87.4328L45.0338 101.81C45.0125 103.614 45.5539 104.733 46.5554 104.963C46.7731 105.014 46.9996 105.016 47.2182 104.969C47.4369 104.921 47.6421 104.825 47.8188 104.688C48.5989 104.108 49.0136 102.851 49.0755 101.857C49.2128 99.7502 49.0359 97.5597 48.8648 95.4417C48.6173 92.3841 48.3814 89.4483 48.9933 86.729H48.6067C47.9996 89.5324 48.2422 92.5359 48.4791 95.4726C48.6492 97.5771 48.8251 99.7531 48.6898 101.831C48.6328 102.701 48.2635 103.873 47.5858 104.378C47.4541 104.482 47.3005 104.554 47.1365 104.59C46.9726 104.626 46.8027 104.625 46.6395 104.586C45.5519 104.337 45.4079 102.742 45.4185 101.814L45.5829 87.4366C45.5829 87.2046 45.5829 86.9678 45.5751 86.729H45.1885C45.202 86.9697 45.203 87.2017 45.201 87.4328Z"
      fill="#551C12"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls7"
      d="M29.5918 123.154C29.6073 122.996 29.962 119.577 29.962 119.577L37.8888 119.281L37.4287 123.414L29.5918 123.154Z"
      fill="rgba(175,128,113,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls6"
      d="M72.4308 60.5079C72.4308 60.5079 73.4815 61.8767 72.8677 62.3755C72.2538 62.8743 71.0909 62.2392 71.0909 62.2392L70.3379 60.9438L72.4308 60.5079Z"
      fill="rgba(26,54,61,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls7"
      d="M68.3184 56.036C69.9617 54.9214 74.6443 51.9547 76.1485 51.8339C77.6526 51.713 79.1606 51.9093 79.9591 52.8286C80.7576 53.7479 80.2597 55.3738 79.4468 56.6537C78.6338 57.9336 78.046 57.7983 77.7744 57.6204C77.5028 57.4425 77.699 56.792 77.699 56.792L70.8762 62.5002L68.6209 60.4025L68.3184 56.036Z"
      fill="rgba(175,128,113,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls6"
      d="M18.8965 81.0962C18.8965 81.0962 21.0377 82.1847 21.3567 81.0063C21.6757 79.8279 19.3789 79.1087 19.3789 79.1087L18.8965 81.0962Z"
      fill="rgba(26,54,61,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls7"
      d="M13.6658 76.1429C13.6658 76.1429 12.15 81.0034 10.7445 81.9073C9.30028 82.8353 5.51283 84.5473 4.89028 85.3709C4.44851 85.9509 4.79362 86.2544 4.79362 86.2544C4.79362 86.2544 4.49298 86.5299 4.72402 86.8566C4.91058 87.1215 5.50703 86.8566 5.50703 86.8566C5.50703 86.8566 5.50703 87.4096 6.94255 87.2085C8.37807 87.0075 9.72368 86.7368 9.72368 86.7368C9.72368 86.7368 9.54291 87.1583 11.0596 87.1583C12.5764 87.1583 16.8326 86.9176 17.7771 85.2404C18.7215 83.5632 20.739 78.7037 20.739 78.7037L13.6658 76.1429Z"
      fill="rgba(175,128,113,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls5"
      d="M25.3344 66.306L21.3585 81.0063C21.3585 81.0063 21.575 80.2832 20.0728 79.5805C18.4865 78.8371 12.8633 76.6282 12.8633 76.6282C12.8633 76.6282 17.1408 60.9439 18.3463 57.2096C19.5517 53.4753 22.4431 47.9865 26.419 46.8139C30.395 45.6413 37.6886 44.7984 37.6886 44.7984L41.0623 45.2508C41.0623 45.2508 45.8222 45.8472 47.9006 46.8139C49.9789 47.7806 62.1108 60.331 62.1108 60.331L68.3197 56.036C68.3197 56.036 71.4527 63.6119 72.8631 62.3774L64.6096 69.0794L57.6215 70.7064L51.601 62.5128L49.0413 60.1028L49.1012 66.3379L25.3344 66.306Z"
      fill="rgba(78,179,212,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls6"
      d="M49.0626 62.766L49.0375 60.1009L46.6875 57.8418L49.0626 62.766Z"
      fill="rgba(26,54,61,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls7"
      d="M68.0879 75.0216L70.1789 80.2021L61.6818 82.3317L60.7383 78.0744L68.0879 75.0216Z"
      fill="rgba(175,128,113,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls6"
      d="M27.4123 58.6151L25.334 66.306L26.9406 66.3089L27.4123 58.6151Z"
      fill="rgba(26,54,61,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls7"
      d="M40.8966 37.3684L41.8633 45.5214C41.8633 45.5214 40.6182 46.927 38.7699 46.8071C36.9217 46.6872 35.7539 45.039 35.7539 45.039C35.7539 45.039 37.0995 36.8716 36.8985 34.421C36.6974 31.9705 36.7477 30.4451 36.7477 30.4451L40.9498 31.2436L40.8966 37.3684Z"
      fill="rgba(175,128,113,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls14"
      d="M38.125 37.1278V39.5271C38.125 39.5271 39.7471 41.0486 41.3885 41.5301L41.0705 38.7972L39.1854 37.0118L38.125 37.1278Z"
      fill="rgba(119,73,63,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls7"
      d="M38.1255 37.1278C40.2522 38.6532 40.7761 38.9345 42.0212 38.8147C43.2663 38.6948 44.1034 36.6029 42.7849 31.9464C40.9482 25.46 37.1211 29.9377 37.1211 29.9377L38.1255 37.1278Z"
      fill="rgba(175,128,113,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls10"
      d="M40.8187 32.4896C40.8332 32.5389 40.4668 35.3394 40.4668 35.3394L42.2793 35.6391L40.8187 32.4896Z"
      fill="rgba(100,58,66,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls10"
      d="M39.0456 34.2858C39.4321 34.2858 39.7455 34.043 39.7455 33.7435C39.7455 33.444 39.4321 33.2012 39.0456 33.2012C38.659 33.2012 38.3457 33.444 38.3457 33.7435C38.3457 34.043 38.659 34.2858 39.0456 34.2858Z"
      fill="rgba(100,58,66,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls10"
      d="M43.2951 34.1698C43.2467 33.874 43.1878 33.5646 43.1162 33.2389C43.0341 33.2141 42.9488 33.2015 42.863 33.2012C42.4763 33.2012 42.1621 33.4438 42.1621 33.7435C42.1621 34.0431 42.4763 34.2858 42.863 34.2858C43.0149 34.2873 43.1643 34.2471 43.2951 34.1698V34.1698Z"
      fill="rgba(100,58,66,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls11"
      d="M39.3433 32.5631C39.4725 32.5631 39.5772 32.4224 39.5772 32.2489C39.5772 32.0754 39.4725 31.9347 39.3433 31.9347C39.2141 31.9347 39.1094 32.0754 39.1094 32.2489C39.1094 32.4224 39.2141 32.5631 39.3433 32.5631Z"
      fill="#111111"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls11"
      d="M41.8525 32.3997C41.9619 32.3997 42.0506 32.2803 42.0506 32.1329C42.0506 31.9856 41.9619 31.8661 41.8525 31.8661C41.743 31.8661 41.6543 31.9856 41.6543 32.1329C41.6543 32.2803 41.743 32.3997 41.8525 32.3997Z"
      fill="#111111"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls3"
      d="M42.3581 30.0323C42.3581 30.0323 41.3073 27.0057 38.3831 27.8921C36.154 28.5688 36.2139 30.6955 36.3115 31.6573L42.3581 30.0323Z"
      fill="rgba(57,57,96,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls3"
      d="M45.1817 30.2555C45.1817 30.2555 46.0508 29.8459 45.7572 29.002C45.4636 28.1581 44.6456 28.3161 44.6456 28.3161L36.5095 30.5235C36.5095 30.5235 35.1062 30.7679 35.5556 31.8559C36.005 32.9439 36.8406 32.4945 36.8406 32.4945L45.1817 30.2555Z"
      fill="rgba(57,57,96,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls5"
      d="M61.0931 79.6761C61.0931 79.6761 67.0236 80.4437 68.0676 79.7612C69.1117 79.0787 69.6143 78.578 69.8937 78.577C70.1731 78.5761 71.1204 79.942 71.6627 80.1827C72.205 80.4234 75.7788 82.0097 76.7426 82.5124C77.7064 83.015 79.4745 84.1557 79.5943 84.8015C79.7142 85.4472 79.5141 86.729 75.8997 86.729H62.1835C61.2999 86.729 60.7383 85.7449 60.7383 84.2389C60.7383 82.7328 61.0931 79.6761 61.0931 79.6761Z"
      fill="rgba(78,179,212,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls13"
      d="M66.206 86.4265L68.7986 82.0832L67.9044 81.5496L64.8594 86.6498C64.8594 86.6498 65.856 86.4748 66.206 86.4265Z"
      fill="rgba(70,29,29,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls13"
      d="M68.3285 85.9615L70.1584 82.8971L69.2643 82.3635L66.9336 86.266C67.4047 86.195 67.8707 86.0932 68.3285 85.9615V85.9615Z"
      fill="rgba(70,29,29,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls13"
      d="M70.1766 85.9547L71.4835 83.6889L70.5894 83.1552L68.918 85.9586C69.3936 85.8745 69.7184 86.1113 70.1766 85.9547Z"
      fill="rgba(70,29,29,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls9"
      d="M79.4695 84.5308C79.2762 84.9871 78.8605 85.3563 78.3965 85.5535C77.774 85.8184 77.0799 85.8368 76.4042 85.8513C71.2015 85.9653 65.9467 86.0726 60.8262 85.2094C61.0195 86.1538 61.5028 86.729 62.1795 86.729H75.8957C79.5102 86.729 79.7112 85.4443 79.5904 84.8015C79.5683 84.7042 79.5272 84.6122 79.4695 84.5308Z"
      fill="#000000"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls3"
      d="M20.2461 81.5563C20.5255 83.2422 21.2331 85.0287 22.9219 87.2598C26.1757 91.5537 31.8385 99.1499 31.8385 99.1499L26.9558 120.768H41.2965C41.2965 120.768 47.201 100.467 47.0801 97.754C46.9593 95.0406 42.0215 86.5937 42.0215 86.5937C42.0215 86.5937 48.767 87.573 52.3824 82.2108C55.9978 76.8486 57.0224 75.1018 57.0224 75.1018L59.6131 80.3316L70.6584 75.4431C70.6584 75.4431 66.695 65.8401 63.5901 60.3039C61.6016 56.7581 56.2298 55.9693 52.103 60.1415C48.1193 64.1677 46.6896 66.3089 46.6896 66.3089H25.3318L21.3558 81.0024C21.3558 81.0024 21.2389 81.4655 20.857 81.508C20.4752 81.5505 20.2461 81.5563 20.2461 81.5563Z"
      fill="rgba(57,57,96,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls7"
      d="M37.121 31.8052C36.8793 31.3692 36.1244 30.8666 35.6333 30.9623C35.3883 31.0147 35.1655 31.1412 34.9949 31.3247C34.8244 31.5082 34.7145 31.7398 34.6802 31.9879C34.6583 32.3035 34.7189 32.6193 34.8561 32.9043C35.0451 33.3234 35.2861 33.717 35.5734 34.0759C35.7074 34.2628 35.8676 34.4295 36.049 34.5709C36.2332 34.7125 36.4616 34.7844 36.6937 34.7739C36.8087 34.7664 36.9193 34.727 37.0131 34.66C37.1068 34.5931 37.18 34.5012 37.2245 34.3949"
      fill="rgba(175,128,113,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls4"
      d="M36.5371 76.1429L42.0211 86.5908C43.5551 86.7522 45.1056 86.632 46.5964 86.236L36.5371 76.1429Z"
      fill="rgba(189,66,66,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls4"
      d="M57.0193 75.1067L54.9216 70.8852L54.6094 78.8748C56.3668 76.2039 56.9729 75.1831 57.0193 75.1067Z"
      fill="rgba(189,66,66,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls5"
      d="M29.4544 122.531C29.4544 122.531 35.385 123.299 36.429 122.615C37.473 121.932 37.9757 121.433 38.255 121.432C38.5344 121.431 39.4817 122.796 40.0241 123.038C40.5664 123.279 44.1402 124.865 45.104 125.366C46.0677 125.868 47.8358 127.01 47.9557 127.656C48.0755 128.303 47.8754 129.584 44.261 129.584H30.5448C29.6613 129.584 29.0996 128.6 29.0996 127.094C29.0996 125.588 29.4544 122.531 29.4544 122.531Z"
      fill="rgba(78,179,212,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls13"
      d="M34.5673 129.281L37.1599 124.938L36.2657 124.404L33.2207 129.504C33.2207 129.504 34.2183 129.33 34.5673 129.281Z"
      fill="rgba(70,29,29,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls13"
      d="M36.6957 128.815L38.5256 125.752L37.6314 125.217L35.3008 129.117C35.7719 129.047 36.2379 128.947 36.6957 128.815Z"
      fill="rgba(70,29,29,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls13"
      d="M38.5418 128.81L39.8488 126.544L38.9546 126.009L37.2832 128.812C37.7607 128.73 38.0836 128.966 38.5418 128.81Z"
      fill="rgba(70,29,29,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls9"
      d="M47.8348 127.386C47.6414 127.842 47.2258 128.211 46.7618 128.408C46.1392 128.672 45.4452 128.692 44.7694 128.706C39.5668 128.819 34.3119 128.928 29.1914 128.064C29.3847 129.008 29.8681 129.584 30.5448 129.584H44.2581C47.8725 129.584 48.0736 128.298 47.9527 127.656C47.9317 127.559 47.8916 127.467 47.8348 127.386V127.386Z"
      fill="#000000"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls4"
      d="M44.7012 66.3089L44.9013 68.741L46.6848 66.3089H44.7012Z"
      fill="rgba(189,66,66,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls4"
      d="M26.9384 66.3089H25.3318L21.3558 81.0024C21.3558 81.0024 21.2389 81.4654 20.857 81.508C20.4752 81.5505 20.2461 81.5505 20.2461 81.5505C20.4455 82.7331 20.8405 83.8743 21.4148 84.9271L26.9384 66.3089Z"
      fill="rgba(189,66,66,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
    <path
      className="fillCls11"
      d="M40.1695 36.6081C40.454 36.8348 40.7982 36.974 41.1603 37.0087C41.5224 37.0434 41.8868 36.9721 42.2092 36.8036"
      fill="#111111"
      strokeWidth="2px"
      style={{
        animationDuration: '2s',
      }}
    />
  </svg>
);

export default Analytics;
