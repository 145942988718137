import union from 'lodash/union';
import { FILTER_FIELDS } from '../../_constants';

export const getFilterParamsFromItems = (items) =>
  items.reduce((acc, { fields }) => {
    Object.keys(FILTER_FIELDS).forEach((key) => {
      if (!acc[key]) acc[key] = [];
      if (fields[key]) {
        acc[key] = union(acc[key], Array.isArray(fields[key]) ? fields[key] : [fields[key]]);
      }
    });
    return acc;
  }, {});
