import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import httpClient from '../lib/httpClient';
import { useAuth0 } from '../lib/react-auth0-spa';
import notification from '../utils/services/notification';

export const loadCompany = (id) => httpClient.get(`/companies/companies/${id}?tenant_id=${id}`);

export const useGetCompany = ({ notify = false } = {}) => {
  const { t } = useTranslation('shared');
  const {
    user: { tenant_id: tenantId },
    loading: isUserLoading,
  } = useAuth0();

  const [loading, setLoading] = useState(true); // it's on purpose, don't let any code to execute until company will be loaded
  const [data, setData] = useState({});

  const handleLoadCompany = useCallback(async () => {
    try {
      const company = await loadCompany(tenantId);
      setData(company ?? {});
    } catch (e) {
      if (notify) {
        notification.error(t('messages.error.loadCompany'));
      }
    } finally {
      setLoading(false);
    }
  }, [notify, t, tenantId]);

  useEffect(() => {
    if (tenantId) {
      handleLoadCompany();
    }
  }, [handleLoadCompany, tenantId]);

  return { loading: isUserLoading || loading, data, loadCompany: handleLoadCompany };
};
