import React from 'react';
import PropTypes from 'prop-types';

import { LOADER_ICON } from '../../assets/icons';
import * as Styled from './styles';

const Loader = ({ coverElement, ...rest }) => (
  <Styled.Wrapper coverElement={coverElement} {...rest}>
    <Styled.Loader src={LOADER_ICON} alt="loader" />
  </Styled.Wrapper>
);

Loader.propTypes = {
  coverElement: PropTypes.bool,
};

Loader.defaultProps = {
  coverElement: false,
};

export default Loader;
