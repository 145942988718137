import { getLinkWithParams, SETTINGS_ICON, URLS, USER_GROUPS } from '@livingsecurity/shared';
import { ICON_RISK, ICON_STRATEGY, ICON_TRAINING } from '@livingsecurity/shared/dist/assets/icons/sidebar-menu';

export const getRoutes = (t, flags = {}) => [
  {
    title: t('dashboard'),
    path: URLS.dashboard,
    link: URLS.dashboard,
    icon: ICON_TRAINING,
    groups: [USER_GROUPS.LS_ADMIN, USER_GROUPS.CUSTOMER_ADMIN, USER_GROUPS.CAMPAIGN_MGR, USER_GROUPS.PARTICIPANT],
  },
  {
    title: t('campaigns'),
    icon: ICON_STRATEGY,
    groups: [
      USER_GROUPS.LS_ADMIN,
      USER_GROUPS.CUSTOMER_ADMIN,
      USER_GROUPS.ENTERPRISE_ADMIN,
      USER_GROUPS.CAMPAIGN_MGR,
      USER_GROUPS.TRAINING_DEMO,
    ],
    nestedRoutes: [
      {
        title: t('audience'),
        path: URLS.audiences,
        link: getLinkWithParams(URLS.audiences),
        groups: [
          USER_GROUPS.LS_ADMIN,
          USER_GROUPS.CUSTOMER_ADMIN,
          USER_GROUPS.ENTERPRISE_ADMIN,
          USER_GROUPS.CAMPAIGN_MGR,
        ],
      },
      {
        title: t('catalog'),
        path: URLS.catalog,
        link: `${URLS.catalog}/preview`,
        groups: [
          USER_GROUPS.LS_ADMIN,
          USER_GROUPS.CUSTOMER_ADMIN,
          USER_GROUPS.ENTERPRISE_ADMIN,
          USER_GROUPS.CAMPAIGN_MGR,
          USER_GROUPS.TRAINING_DEMO,
        ],
      },
      {
        title: t('notifications'),
        path: `${URLS.notifications}`,
        link: flags.tmpHideNotificationsLog ? `${URLS.notifications}` : `${URLS.notifications}/email`,
        groups: [USER_GROUPS.LS_ADMIN, USER_GROUPS.CUSTOMER_ADMIN, USER_GROUPS.ENTERPRISE_ADMIN],
      },
      {
        title: t('campaign-builder'),
        path: URLS.campaignBuilder,
        link: getLinkWithParams(URLS.campaignBuilder),
        groups: [
          USER_GROUPS.LS_ADMIN,
          USER_GROUPS.CUSTOMER_ADMIN,
          USER_GROUPS.ENTERPRISE_ADMIN,
          USER_GROUPS.CAMPAIGN_MGR,
        ],
      },
    ],
  },
  {
    id: 'analytics',
    title: t('analytics'),
    icon: ICON_RISK,
    groups: [USER_GROUPS.LS_ADMIN, USER_GROUPS.CUSTOMER_ADMIN],
    nestedRoutes: [
      {
        title: t('campaigns'),
        path: URLS.campaigns,
        link: URLS.campaigns,
      },
      {
        title: t('participants'),
        path: URLS.participants,
        link: URLS.participants,
      },
      ...(flags?.adminAnalyticsTraining
        ? []
        : [
            {
              title: t('training'),
              path: URLS.training,
              link: URLS.training,
            },
          ]),
      ...(flags?.hideAnalyticsContentFeedback
        ? []
        : [
            {
              title: t('feedback'),
              path: URLS.feedback,
              link: URLS.feedback,
            },
          ]),
      // TODO: Are we ever going to build this?
      // {
      //   title: t('questions'),
      //   path: URLS.questions,
      //   link: URLS.questions,
      // },
    ],
  },
  {
    id: 'settings',
    title: t('settings'),
    icon: SETTINGS_ICON,
    groups: [USER_GROUPS.LS_ADMIN, USER_GROUPS.CUSTOMER_ADMIN, USER_GROUPS.ENTERPRISE_ADMIN],
    nestedRoutes: [
      {
        title: t('account'),
        path: URLS.account,
        link: URLS.account,
      },
      {
        title: t('user-management'),
        path: URLS.userManagement,
        link: URLS.userManagement,
      },
      {
        title: t('user-provisioning'),
        path: URLS.userProvisioning,
        link: URLS.userProvisioning,
      },
      {
        title: t('user-domains'),
        path: URLS.userDomains,
        link: URLS.userDomains,
      },
      {
        title: t('user-policy'),
        path: URLS.userPolicy,
        link: URLS.userPolicy,
      },
      {
        title: t('policy-acceptance'),
        path: URLS.policyAcceptance,
        link: URLS.policyAcceptance,
      },
      {
        title: t('campaign-settings'),
        path: URLS.configCampaigns,
        link: URLS.configCampaigns,
      },
      // {
      //   title: t('notifications'),
      //   path: URLS.customNotifications,
      //   link: URLS.customNotifications,
      // },
    ],
  },
];
