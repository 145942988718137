import React from 'react';

import { PuzzleWithWrapper } from '@livingsecurity/shared';
import PuzzleDebug from '../Puzzle/PuzzleDebug';
import puzzleReducer from '../Puzzle/reducer';
import { useViewerContext } from '../../utils/context';

import * as Styled from './styles';

const PuzzleWithDebug = ({ flags, handlePuzzleEnding, puzzleContent }) => {
  const { pageLayoutRef } = useViewerContext();

  return process.env.REACT_APP_DEBUG === 'true' || flags?.debug ? (
    <PuzzleDebug handlePuzzleEnding={handlePuzzleEnding} puzzleContent={puzzleContent} />
  ) : (
    <Styled.PuzzleContainer>
      <PuzzleWithWrapper
        puzzleContent={puzzleContent}
        reducer={puzzleReducer}
        handlePuzzleEnding={handlePuzzleEnding}
        instructionsMountRef={pageLayoutRef}
      />
    </Styled.PuzzleContainer>
  );
};

export default PuzzleWithDebug;
