import React from 'react';
import { useHistory } from 'react-router-dom';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Heading1,
  InputRHF,
  Paragraph,
  Button,
  Img,
  BUTTON_TYPES,
  notification,
  COLORS_CONFIG,
  URLS,
} from '@livingsecurity/shared';

import { useCheckEmail } from '../../hooks';

import Layout from '../Layout';

import * as GlobalStyled from '../../styles';
import * as Styled from './styles';

import Sony from '../../assets/sony.webp';
import Target from '../../assets/target.webp';
import Verizon from '../../assets/verizon.webp';

const schema = yup.object().shape({
  email: yup.string().trim().email().required('An email address is required.'),
});

const Welcome = () => {
  const history = useHistory();
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
    mode: 'onChange',
  });

  const { mutate: checkEmail, isLoading: isCheckingEmail } = useCheckEmail({
    onSuccess: (data, variables) => {
      if (data?.allow === true) {
        history.push(URLS.register, variables);
      }
      if (data?.allow === false) {
        notification.error('Please use a work related email address.');
      }
    },
    onError: (error) => {
      notification.error('Something went wrong. Please try again.');
      console.error(error);
    },
  });

  const handleCheckEmail = (data) => {
    checkEmail({ ...data });
  };

  return (
    <GlobalStyled.Wrapper>
      <Layout>
        <Styled.MainContainer>
          <Heading1 style={{ textAlign: 'center', fontSize: '3.75rem', marginBottom: '.5rem' }}>
            See our training in action
          </Heading1>
          <Paragraph style={{ textAlign: 'center', marginBottom: '1rem' }}>
            Security awareness training can help your company mitigate risks by educating employees about good practices
            and common online “traps” set by cyber criminals.
          </Paragraph>
          <Paragraph bold style={{ marginBottom: '2rem' }}>
            But first, we need your email.
          </Paragraph>
          <GlobalStyled.Form onSubmit={handleSubmit(handleCheckEmail)}>
            <InputRHF placeholder="Work email" name="email" control={control} />
            <Button
              variant={BUTTON_TYPES.SKY}
              size="large"
              style={{ width: '100%', marginTop: '1rem' }}
              loading={isCheckingEmail}
              type="submit"
              disabled={isCheckingEmail}
            >
              Get started
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                style={{ width: '2rem', marginLeft: '.5rem' }}
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
              </svg>
            </Button>
          </GlobalStyled.Form>
          <Styled.ClientsContainer>
            <Paragraph color={COLORS_CONFIG.SECONDARY.IRON}>Our Clients</Paragraph>
            <Styled.Clients>
              <Styled.Client>
                <Img src={Sony} width={113} height={24} />
              </Styled.Client>
              <Styled.Client>
                <Img src={Target} width={113} height={24} />
              </Styled.Client>
              <Styled.Client>
                <Img src={Verizon} width={113} height={40} style={{ marginBottom: '.75rem' }} />
              </Styled.Client>
            </Styled.Clients>
          </Styled.ClientsContainer>
        </Styled.MainContainer>
      </Layout>
    </GlobalStyled.Wrapper>
  );
};

export default Welcome;
