import { httpClient } from '@livingsecurity/shared';

export const checkEmail = (email) => {
  return httpClient.get(`/onboard/check-email?email=${email}`);
};

export const registerUser = (data) => {
  return httpClient.post('/onboard/register', data);
};

export const getTrialUser = (location) => {
  return httpClient.get(location);
};
