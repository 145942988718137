import styled from 'styled-components';
import { ButtonStyles } from '@livingsecurity/shared';

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${ButtonStyles.ButtonWrapper} + ${ButtonStyles.ButtonWrapper} {
    margin: 0 0 0 16px;
  }
`;
