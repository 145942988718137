export const TRANSACTION_STATES = {
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

export const LOADING_SCREENS = [
  {
    id: 'REGISTERING',
    message: 'self-signup.registering',
    icon: 'REGISTERING',
  },
  {
    id: 'BUILDING ANALYTICS',
    message: 'self-signup.building-analytics',
    icon: 'ANALYTICS',
  },
  {
    id: 'BUILDING DASHBOARD',
    message: 'self-signup.building-dashboard',
    icon: 'DASHBOARD',
  },
  {
    id: 'COMPLETE',
    message: 'self-signup.complete',
    icon: 'COMPLETE',
  },
  {
    id: 'FAILED',
    message: 'self-signup.failed',
    icon: 'FAILED',
  },
];
