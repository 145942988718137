import { useState, useCallback, useEffect } from 'react';

import { useSearch } from './useSearch';
import { DEFAULT_SORT, ITEMS_PER_PAGE_OPTIONS } from '../_constants';

export const useTableHandling = ({
  filterDefaults = {},
  sortDefaults = {
    sortBy: DEFAULT_SORT.audienceParticipants.prop,
    sortOrder: DEFAULT_SORT.audienceParticipants.order,
  },
  pageDefaults = 1,
  perPageDefaults = ITEMS_PER_PAGE_OPTIONS[0].value,
  searchDefaults = '',
  changeCallback = () => {},
}) => {
  const [filterOptions, setFilterOptions] = useState(filterDefaults);
  const [itemsPerPage, setItemsPerPage] = useState(perPageDefaults);
  const [sortOption, setSortOption] = useState(sortDefaults);
  const [currentPage, setCurrentPage] = useState(pageDefaults);
  const [searchValue, setSearchValue] = useState(searchDefaults);

  const handleFetch = useCallback(
    async ({
      sortBy = sortOption.sortBy,
      sortOrder = sortOption.sortOrder,
      page = currentPage,
      perPage = itemsPerPage,
      search = searchValue,
      ...rest
    }) => {
      changeCallback({
        sortBy,
        sortOrder,
        page,
        perPage,
        search,
        ...filterOptions,
        ...rest,
      });
    },
    [sortOption.sortBy, sortOption.sortOrder, currentPage, itemsPerPage, searchValue, changeCallback, filterOptions],
  );

  const filterHandler = useCallback(
    (prop, data) => {
      setCurrentPage(1);
      setFilterOptions({ ...filterOptions, [prop]: data });
      handleFetch({ ...filterOptions, [prop]: data, page: 1 });
    },
    [filterOptions, handleFetch],
  );

  const sortHandler = useCallback(
    (sortBy, sortOrder) => {
      setSortOption({ sortBy, sortOrder });
      handleFetch({ sortBy, sortOrder });
    },
    [handleFetch],
  );

  const perPageHandler = useCallback(
    (value) => {
      if (itemsPerPage === value) return;
      setItemsPerPage(value);
      setCurrentPage(1);
      handleFetch({ perPage: value, page: 1 });
    },
    [handleFetch, itemsPerPage],
  );

  const pageHandler = useCallback(
    (newPage) => {
      if (currentPage === newPage) return;
      setCurrentPage(newPage);
      handleFetch({ page: newPage });
    },
    [currentPage, handleFetch],
  );

  const searchItems = (value) => {
    setCurrentPage(1);
    handleFetch({ search: value, page: 1 });
  };

  const searchWithHook = useSearch(searchItems);

  const searchHandler = useCallback(
    ({ target: { value } }) => {
      setSearchValue(value);
      searchWithHook(value);
    },
    [searchWithHook],
  );

  const setValues = useCallback(
    ({ page, perPage, search, sortOrder, sortBy }) => {
      if (perPage) setItemsPerPage(perPage);
      if (sortOrder || sortBy)
        setSortOption({
          sortOrder,
          sortBy,
        });
      if (page) setCurrentPage(page);
      if (search) setSearchValue(search);

      handleFetch({
        page: page || undefined,
        perPage: perPage || undefined,
        search: search || undefined,
        sortBy: sortBy || undefined,
        sortOrder: sortOrder || undefined,
      });
    },
    [handleFetch],
  );

  const setToDefaults = useCallback(() => {
    setFilterOptions(filterDefaults);
    setItemsPerPage(perPageDefaults);
    setSortOption(sortDefaults);
    setCurrentPage(pageDefaults);
    setSearchValue(searchDefaults);

    handleFetch({
      page: pageDefaults,
      perPage: perPageDefaults,
      search: searchDefaults,
      sortBy: DEFAULT_SORT.audiences.prop,
      sortOrder: DEFAULT_SORT.audiences.order,
    });

    return {
      page: pageDefaults,
      perPage: perPageDefaults,
      search: searchDefaults,
      sortBy: DEFAULT_SORT.audiences.prop,
      sortOrder: DEFAULT_SORT.audiences.order,
    };
  }, [filterDefaults, handleFetch, pageDefaults, perPageDefaults, searchDefaults, sortDefaults]);

  useEffect(() => {
    (async () => {
      if (!window.ldClient) {
        return;
      }

      const res = await changeCallback({
        sortBy: sortOption.sortBy,
        sortOrder: sortOption.sortOrder,
        page: currentPage,
        perPage: itemsPerPage,
        search: searchValue,
        ...filterOptions,
      });

      const total = res?.info?.total;
      const offset = res?.info?.offset;

      // setting the page to 1 ignores the cached (pageDefaults) page value
      //setCurrentPage(1);

      if (total !== 0 && offset > total) {
        changeCallback({
          sortBy: sortOption.sortBy,
          sortOrder: sortOption.sortOrder,
          page: 1,
          perPage: 10,
          search: '',
          ...filterOptions,
        });
      }
    })();
  }, [window.ldClient]); // eslint-disable-line

  return {
    filterOptions,
    itemsPerPage,
    sortOption,
    currentPage,
    searchValue,
    filterHandler,
    sortHandler,
    perPageHandler,
    pageHandler,
    searchHandler,
    setValues,
    setToDefaults,
  };
};
