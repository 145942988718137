/* Libs */
import React from 'react';
import PropTypes from 'prop-types';
import { BUTTON_TYPES, Modal, Button, FakeProgressBar } from '@livingsecurity/shared';

/* Styles */
import { useTranslation } from 'react-i18next';
import * as Styled from './styles';

function CopyTreeModalOld({ onClose, onConfirm, open, isFakeProgressBar }) {
  const { t } = useTranslation('catalog');
  if (!open) return null;
  return (
    <Modal
      onClose={onClose}
      title={t('copyTreeModal.title')}
      status
      open={open}
      isHideElements={isFakeProgressBar}
      preventEscape={isFakeProgressBar}
    >
      {isFakeProgressBar ? (
        <FakeProgressBar infinite />
      ) : (
        <Styled.ButtonWrapper>
          <Button variant={BUTTON_TYPES.SKY} text={t('copyTreeModal.continue')} onClick={onConfirm} />
          <Button variant={BUTTON_TYPES.GREY} text={t('copyTreeModal.cancel')} onClick={onClose} />
        </Styled.ButtonWrapper>
      )}
    </Modal>
  );
}

// Type of props
CopyTreeModalOld.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isFakeProgressBar: PropTypes.bool.isRequired,
};

// Default value for props
CopyTreeModalOld.defaultProps = {};

export default CopyTreeModalOld;
