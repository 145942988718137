import styled from 'styled-components';
import { COLORS_CONFIG } from '@livingsecurity/shared';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-width: 32rem;
`;

export const ClientsContainer = styled.div`
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
`;

export const Clients = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;

export const Client = styled.li`
  img {
    filter: brightness(0%);
  }
`;
