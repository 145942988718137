import React, { lazy } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthorizedLayout } from 'components';
import { LazyLoad, FeedbackModal, useToggle } from '@livingsecurity/shared';

const AudiencePkg = lazy(() => import('@livingsecurity/audience'));

const Audience = (props) => {
  const { t } = useTranslation('shared');
  const [emailReportModalIsOpen, toggleEmailReportModalIsOpen] = useToggle(false);

  return (
    <>
      {emailReportModalIsOpen && (
        <FeedbackModal
          content={t('messages.downloads.notification.success')}
          onClose={() => toggleEmailReportModalIsOpen(false)}
        />
      )}
      <AuthorizedLayout withoutPadding>
        <LazyLoad component={AudiencePkg} toggleFeedbackModal={toggleEmailReportModalIsOpen} {...props} />
      </AuthorizedLayout>
    </>
  );
};

export default Audience;
