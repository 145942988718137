import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { getTrialUser } from '../_api';

import { TRANSACTION_STATES, LOADING_SCREENS } from '../_constants';

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const useGetTrialUser = ({ location, refetchInterval }) => {
  const [loaderIndex, setLoaderIndex] = useState(0);

  const { data, isLoading } = useQuery(['getTrialUser', location], () => getTrialUser(location), {
    refetchInterval: (registrationStatusObj) => {
      if (registrationStatusObj?.transaction_state === TRANSACTION_STATES.IN_PROGRESS) {
        // if the transaction is still in progress, we want to refetch the data every 5 seconds
        return refetchInterval;
      }
      // if the transaction is not in progress, we don't want to refetch the data
      return false;
    },
    onSuccess: async (registrationStatusObj) => {
      // increment the loader index while the transaction is in progress
      if (loaderIndex === 0) {
        await delay(3000);
      }
      setLoaderIndex((prevIndex) => {
        if (registrationStatusObj?.transaction_state === TRANSACTION_STATES.SUCCESS) {
          return 3;
        }
        if (prevIndex < LOADING_SCREENS.length - 3) {
          return prevIndex + 1;
        }
        return prevIndex;
      });
      // if the transaction is successful, we want to show the last loader screen

      if (registrationStatusObj?.transaction_state === TRANSACTION_STATES.FAILED) {
        setLoaderIndex(4);
      }
    },
  });

  return {
    status: data?.transaction_state,
    idToken: data?.id_token,
    failureMessages: data?.failure_reason,
    isTrialUserDataLoading: isLoading,
    loaderIndex,
  };
};

export default useGetTrialUser;
