import moment from 'moment';

const TIMEFRAMES = [
  {
    label: 'common.timeframe-select.timeframe-labels.today',
    value: {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
    },
  },
  {
    label: 'common.timeframe-select.timeframe-labels.yesterday',
    value: {
      startDate: moment().subtract(1, 'd').format('YYYY-MM-DD'),
      endDate: moment().subtract(1, 'd').format('YYYY-MM-DD'),
    },
  },
  {
    label: 'common.timeframe-select.timeframe-labels.last-week',
    value: {
      startDate: moment().subtract(7, 'd').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
    },
  },
  {
    label: 'common.timeframe-select.timeframe-labels.last-month',
    value: {
      startDate: moment().subtract(30, 'd').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
    },
  },
  {
    label: 'common.timeframe-select.timeframe-labels.last-60-days',
    value: {
      startDate: moment().subtract(60, 'd').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
    },
  },
  {
    label: 'common.timeframe-select.timeframe-labels.last-90-days',
    value: {
      startDate: moment().subtract(90, 'd').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
    },
  },
  {
    label: 'common.timeframe-select.timeframe-labels.last-year',
    value: {
      startDate: moment().subtract(1, 'y').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
    },
  },

  // This is hidden because no company has 2 years worth of data yet...
  // {
  //   label: 'common.timeframe-select.timeframe-labels.last-2-years',
  //   value: {
  //     start: moment().subtract(2, 'years').format(),
  //     end: moment().format(),
  //   },
  // },

  {
    label: 'common.timeframe-select.timeframe-labels.custom-range',
    value: {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
    },
  },
];

export default TIMEFRAMES;
