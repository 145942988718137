import { useCallback } from 'react';

export const useIframeRenderer = (html) => {
  const injectHTML = useCallback(
    (node) => {
      if (!node) return;
      const doc = node.contentDocument;
      doc.open();
      doc.write(html);
      doc.close();
    },
    [html],
  );

  return { ref: injectHTML, src: 'about:blank' };
};
