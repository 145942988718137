import * as TYPES from './Auth.types';

/* eslint-disable */
export const setId = (id) => ({
  type: TYPES.SET_ID,
  payload: { id },
});

export const setCompanyName = (companyName) => ({
  type: TYPES.SET_COMPANY_NAME,
  payload: { companyName },
});

export const setCompanyInfo = (data) => ({
  type: TYPES.SET_COMPANY_INFO,
  payload: data,
});

/* eslint-enable */
