import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { SlideOutPanel } from '@livingsecurity/cyberblocks';

import * as Styled from './styles';

const { Wrapper, Slides, Title, CollapseBlock, Navigation, GoBack, DetailsWrapper, DetailsText } = SlideOutPanel;

const ChatSlideout = ({ mountRef }) => {
  const { t } = useTranslation('reporting');
  const i18nKey = 'campaign-details.contextual-help';
  const blocks = Object.entries(t(`campaign-details.contextual-help.blocks`, { returnObjects: true }));

  const [activeItem, setActiveItem] = React.useState(null);

  const handleNavigationClick = (item = {}, next = () => {}) => {
    setActiveItem(item);
    next();
  };

  const renderBlocks = (topKey = '', block = {}, next = () => {}) => {
    const info = Object.entries(block).slice(1);

    return (
      <CollapseBlock key={topKey} title={block?.title}>
        {info.map(([key = '', infoBlock = {}]) => (
          <Navigation
            key={key}
            hasSubItem
            title={infoBlock?.title}
            onClick={() => handleNavigationClick(infoBlock, next)}
          />
        ))}
      </CollapseBlock>
    );
  };

  return (
    <Wrapper mountRef={mountRef} iconColor="#000" style={{ height: 'calc(100vh - 57px)', top: '57px' }} isPlatform>
      <Slides.Wrapper>
        <Slides.Slide>
          {({ next }) => (
            <>
              <Title color="#FFFFFF">{t(`${i18nKey}.title`)}</Title>
              {blocks.map(([key, block]) => renderBlocks(key, block, next))}
            </>
          )}
        </Slides.Slide>
        <Slides.Slide>
          {({ prev }) => (
            <>
              <GoBack title={t(`${i18nKey}.back`)} onClick={prev} />
              <DetailsWrapper>
                <Styled.SubTitle>
                  <h5>{activeItem?.title}</h5>
                  {activeItem?.subtitle && <DetailsText>{activeItem?.subtitle}</DetailsText>}
                </Styled.SubTitle>
                {activeItem?.blocks ? (
                  activeItem?.blocks?.map((block) => (
                    <Styled.DetailsTextWrapper>
                      <strong>{block?.title}</strong>
                      <DetailsText>{block?.description}</DetailsText>
                      {block?.note && <DetailsText>{block?.note}</DetailsText>}
                    </Styled.DetailsTextWrapper>
                  ))
                ) : (
                  <Styled.DetailsTextWrapper>
                    <DetailsText>{activeItem?.description}</DetailsText>
                    {activeItem?.note && <DetailsText>{activeItem?.note}</DetailsText>}
                  </Styled.DetailsTextWrapper>
                )}
              </DetailsWrapper>
            </>
          )}
        </Slides.Slide>
      </Slides.Wrapper>
    </Wrapper>
  );
};

ChatSlideout.propTypes = {
  mountRef: PropTypes.node.isRequired,
};

export default ChatSlideout;
