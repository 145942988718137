import React from 'react';
import { useAuth0 } from '@livingsecurity/shared';

/*
 * App wrapper that routes users to our various application routes
 */
const ChatLayout = ({ children }) => {
  return (
    <>
      <div id="chat-layout" className="flex mt-px" style={{ height: 'calc(100vh - 58px)' }}>
        <main className="flex flex-col overflow-x-hidden w-full">{children}</main>
      </div>
    </>
  );
};

export default ChatLayout;
