import axios from 'axios';
import last from 'lodash/last';
import httpClient from '../../lib/httpClient';

const fetchConfig = (credsUrl, cancelToken) =>
  httpClient.get(credsUrl, {
    ...(cancelToken ? { signal: cancelToken } : {}),
  });

const uploadFile = async (file, config, cancelToken) => {
  const { url, fields } = config;

  const formData = new FormData();
  formData.append('key', fields.key);
  formData.append('AWSAccessKeyId', fields.AWSAccessKeyId);
  formData.append('policy', fields.policy);
  formData.append('signature', fields.signature);
  formData.append('x-amz-security-token', fields['x-amz-security-token']);
  formData.append('file', file, file.name);

  const response = await axios.post(url, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    ...(cancelToken ? { signal: cancelToken } : {}),
  });
  return response;
};

export const uploadToS3 = async (credsUrl, file, cancelToken) => {
  const config = await fetchConfig(credsUrl, cancelToken);
  const uploadResponse = await uploadFile(file, config, cancelToken);

  const name = last(config.fields.key.split('/'));

  return {
    config,
    uploadResponse,
    name,
  };
};
