import React from 'react';

const ALLOW_PROMPTS = false;
const ALLOW_ATTACHMENT = false;
const ALLOW_MIC = false;
const ALLOW_SCROLL = false;

export const ChatBottomBar = ({
  updateValue,
  submitMsg,
  updateInputHeight,
  inputRef,
  inputHeight,
  embedded,
  loading,
  value,
}) => {
  let map = {};

  return (
    <>
      <div className="w-full bg-accent dark:bg-neutral/95 backdrop-blur-lg bottom-0 z-10 sticky">
        <footer
          className={`max-w-full mx-auto py-1 px-2 sm:px-2 lg:px-0 ${embedded ? 'lg:max-w-[95%]' : 'lg:max-w-[80%]'}`}
        >
          {false && (
            <div className="flex justify-between items-center mb-1">
              <button
                type="button"
                className="py-1.5 px-2 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-primary transition-all text-xs dark:bg-neutral dark:hover:bg-slate-800 dark:border-white/30 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
              >
                <svg
                  className="w-2 h-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M5 3.5h6A1.5 1.5 0 0 1 12.5 5v6a1.5 1.5 0 0 1-1.5 1.5H5A1.5 1.5 0 0 1 3.5 11V5A1.5 1.5 0 0 1 5 3.5z" />
                </svg>
                Stop generating
              </button>
            </div>
          )}
          <div className="relative">
            <textarea
              ref={inputRef}
              className="p-2 pb-6 block w-full border-gray-200/60 text-label rounded-md focus:border-primary focus:ring-primary dark:bg-neutral dark:border-white/30 dark:text-gray-400"
              placeholder="How can I assist you?"
              value={value}
              style={{ height: `${parseInt(inputHeight) + 4}px` }}
              onChange={(e) => {
                if (e.nativeEvent.key !== 'Enter') {
                  // @ts-ignore
                  updateValue(e.target?.value);
                }
              }}
              onKeyDown={(e) => {
                map[e.keyCode] = e.type == 'keydown';
              }}
              onKeyUp={(e) => {
                if (
                  e.nativeEvent.key === 'Enter' &&
                  !(loading || e.target?.value?.length < 1) &&
                  !(map[17] && map[16])
                ) {
                  submitMsg();
                }
                map = {};
              }}
            />
            <div className="absolute bottom-px inset-x-px p-1 rounded-b-md bg-white dark:bg-neutral">
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  {ALLOW_PROMPTS && (
                    <button
                      type="button"
                      className="btn btn-neutral btn-xs inline-flex flex-shrink-0 justify-center items-center h-5 w-5 rounded-md text-gray-500 hover:text-primary focus:z-10 focus:outline-none focus:ring-2 focus:ring-primary transition-all dark:hover:text-primary"
                    >
                      <svg
                        className="h-2.5 w-2.5"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                        <path d="M11.354 4.646a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708l6-6a.5.5 0 0 1 .708 0z" />
                      </svg>
                    </button>
                  )}
                  {ALLOW_ATTACHMENT && (
                    <button
                      type="button"
                      className="btn btn-neutral btn-xs inline-flex flex-shrink-0 justify-center items-center h-5 w-5 rounded-md text-gray-500 hover:text-primary focus:z-10 focus:outline-none focus:ring-2 focus:ring-primary transition-all dark:hover:text-primary"
                    >
                      <svg
                        className="h-2.5 w-2.5"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                      </svg>
                    </button>
                  )}
                </div>
                <div className="flex items-center gap-x-1">
                  {ALLOW_MIC && (
                    <button
                      type="button"
                      className="btn btn-neutral btn-xs inline-flex flex-shrink-0 justify-center items-center h-5 w-5 rounded-md text-gray-500 hover:text-primary focus:z-10 focus:outline-none focus:ring-2 focus:ring-primary transition-all dark:hover:text-primary"
                    >
                      <svg
                        className="h-2.5 w-2.5"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                        <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
                      </svg>
                    </button>
                  )}
                  <button
                    type="button"
                    className={`btn btn-primary btn-xs inline-flex flex-shrink-0 justify-center items-center h-5 w-5 rounded-md text-white hover:text-white/60 focus:z-10 focus:outline-none focus:ring-2 focus:ring-primary transition-all dark:hover:text-white/60 ${
                      !value?.length && 'opacity-50'
                    }`}
                    disabled={loading || !value?.length}
                    onClick={submitMsg}
                  >
                    <svg
                      className="h-2.5 w-2.5"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {ALLOW_SCROLL && (
          <div id="scroll-bottom" className="absolute right-1 lg:right-2 top-[-20px]">
            <button className="btn btn-neutral/10 dark:btn-neutral opacity-95 hover:opacity-100 btn-circle">
              <i className="fa-solid fa-down font-[24px]" />
            </button>
          </div>
        )}
      </div>
    </>
  );
};
