import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import axios from 'axios';
import Cookies from 'js-cookie';

import { useAuth0 } from '../lib';
import notification from '../utils/services/notification';
import { getToken } from '../utils/services/token';
import {
  getTeamsManagementUrl,
  getTeamsPermalinkFromRoute,
  USER_GROUPS,
  MARKETING_PAGE,
  IMPERSONATE,
} from '../_constants';
import { useGetCompany } from './useGetCompany';
import { getBaseHostname } from '../utils/custom';

const TEAMS_IMPERSONATE_URL = `${getTeamsManagementUrl().api}/v1/companies`;
const TEAMS_COMPANY_URL = `${getTeamsManagementUrl().api}/v1/companies/?offset=0&limit=1&is_active=true`;

export const useLSTeams = () => {
  const { t } = useTranslation('shared');
  const { enableEmbeddedTeams, debug } = useFlags();
  const { isUserHasRights, loading: loadingUser } = useAuth0();
  const token = getToken();
  const {
    data: { name: companyName },
    loading: loadingCompany,
  } = useGetCompany();

  const lsAdmin = isUserHasRights([USER_GROUPS.TEAMS_LS_ADMIN]);
  const customerAdmin = isUserHasRights([USER_GROUPS.TEAMS_CUSTOMER_ADMIN]);
  const collaborator = isUserHasRights([USER_GROUPS.TEAMS_COLLABORATOR]);
  const roleHasAccess = enableEmbeddedTeams && (collaborator || customerAdmin || lsAdmin);
  const [hasAccess, setHasAccess] = useState(roleHasAccess);

  const [loading, setLoading] = useState(true);

  // Attempting to look up the user's company in Teams, even if it's being impersonated
  const findAssociatedTeamsCompany = useCallback(
    async (companyName) => {
      setLoading(true);
      if (!loadingUser) {
        try {
          const result = await axios.get(`${TEAMS_COMPANY_URL}${companyName ? `&name=${companyName}` : ''}`, {
            headers: { authorization: `JWT ${token}` },
          });

          const tenantId = companyName && result?.data?.results?.length && result?.data?.results[0]?.id;

          if (debug) {
            console.log('[DEBUG] Found Teams company with tenant ID: ', { tenantId, lsAdmin });
          }

          // Ideally the Training company should provide us with a Teams tenant ID so we could continue impersonation.
          // For now, we'll just default to a company. This only impacts LS_ADMIN users.
          if (lsAdmin && tenantId) {
            const result = await axios.get(`${TEAMS_IMPERSONATE_URL}/${tenantId}/`, {
              headers: { authorization: `JWT ${token}` },
            });
            const teamsCompanyId = result.data?.id;
            setHasAccess(!!teamsCompanyId);
            Cookies.set(IMPERSONATE.IMPERSONATED_TEAMS_COMPANY_ID, teamsCompanyId, {
              domain: location.hostname === 'localhost' ? location.hostname : `.${getBaseHostname(location.hostname)}`,
              sameSite: 'Lax',
              expires: 7,
            });
          } else if (tenantId || lsAdmin) {
            if (debug) {
              console.log('[DEBUG] User has access to Teams: ', { tenantId, lsAdmin });
            }
            setHasAccess(true);
          } else {
            Cookies.remove(IMPERSONATE.IMPERSONATED_TEAMS_COMPANY_ID, {
              domain: `.${getBaseHostname(location.hostname)}`,
            });
            console.error(`Could not find Teams company with name: ${companyName}`);
            setHasAccess(false);
          }
        } catch (e) {
          Cookies.remove(IMPERSONATE.IMPERSONATED_TEAMS_COMPANY_ID, {
            domain: `.${getBaseHostname(location.hostname)}`,
          });
          console.error(e);
          notification.error(t('messages.error.loadCompany'));
          setHasAccess(false);
        } finally {
          setLoading(false);
        }
      }
    },
    [hasAccess, loadingUser],
  );

  const getEmbeddedUrl = useCallback(
    (view, href) => {
      const isImpersonating = lsAdmin && Cookies.get(IMPERSONATE.IMPERSONATED_TEAMS_COMPANY_ID);

      if (debug && !loading && view === '/teams/schedule') {
        console.log({ isImpersonating, lsAdmin, cookie: Cookies.get(IMPERSONATE.IMPERSONATED_TEAMS_COMPANY_ID), view });
        console.log(
          hasAccess && !loading
            ? `${getTeamsManagementUrl().view}${getTeamsPermalinkFromRoute(
                view,
                href,
                lsAdmin,
                isImpersonating,
              )}?embedded=true`
            : !loading && MARKETING_PAGE.TEAMS,
        );
      }

      return hasAccess && !loadingUser && !loading
        ? `${getTeamsManagementUrl().view}${getTeamsPermalinkFromRoute(
            view,
            href,
            lsAdmin,
            isImpersonating,
          )}?embedded=true`
        : !loadingUser && !loading && MARKETING_PAGE.TEAMS;
    },
    [hasAccess, loading, loadingUser],
  );

  useEffect(() => {
    if (roleHasAccess && !loading && companyName && lsAdmin) {
      findAssociatedTeamsCompany(companyName);
    } else if ((!companyName && !loading && !loadingCompany) || !roleHasAccess) {
      setHasAccess(false);
    }
    setLoading(false);
  }, [companyName]);

  return { loading, hasAccess, getEmbeddedUrl };
};
