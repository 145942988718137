import styled from 'styled-components';

export const SubTitle = styled.div`
  h5 {
    font-size: 16px;
    font-weight: 900;
  }

  div {
    margin-top: 0.5rem;
  }
  margin-bottom: 1rem;
  padding-bottom: 12px;
  border-bottom: 1px solid #a2acb3;
`;

export const DetailsTextWrapper = styled.div`
  margin-bottom: 1rem;

  div {
    margin-top: 0.25rem;

    :nth-of-type(2) {
      margin-top: 1rem;
    }
  }
`;
