import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { URLS, tokenService, useAuth0, LightLogo } from '@livingsecurity/shared';

import { useGetTrialUser } from '../../hooks';

import Failure from './Failure';
import Loader from './Loader';

import { LOADING_SCREENS, TRANSACTION_STATES } from '../../_constants';

import * as GlobalStyled from '../../styles';

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const Loading = () => {
  const location = useLocation();
  const history = useHistory();
  const { initTrialAuth, logout } = useAuth0();

  if (!location?.state?.location) {
    logout(URLS.welcome);
    history.push(URLS.welcome);
  }

  const { status, idToken, failureMessages, isTrialUserDataLoading, loaderIndex } = useGetTrialUser({
    location: location?.state?.location,
    refetchInterval: 5000,
  });

  useEffect(() => {
    const handleSuccessRedirect = async () => {
      await delay(4000);
      initTrialAuth(idToken);
      history.push(URLS.config);
    };

    if (status === TRANSACTION_STATES.SUCCESS) {
      handleSuccessRedirect();
    }
  }, [status, idToken, history, initTrialAuth]);

  return (
    <GlobalStyled.Wrapper>
      <div
        style={{
          width: '100vw',
          height: '100vh',
          background: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <LightLogo style={{ marginBottom: '4rem' }} />
        {(isTrialUserDataLoading ||
          status === TRANSACTION_STATES.IN_PROGRESS ||
          status === TRANSACTION_STATES.SUCCESS) && <Loader {...LOADING_SCREENS[loaderIndex]} />}
        {!isTrialUserDataLoading && status === TRANSACTION_STATES.FAILED && (
          <Failure failureMessages={failureMessages} {...LOADING_SCREENS[4]} />
        )}
      </div>
    </GlobalStyled.Wrapper>
  );
};

export default Loading;
