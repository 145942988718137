import { gt } from 'lodash';
import hasDigit from './hasDigit';
import hasCapitalLetter from './hasCapitalLetter';
/**
 * Validate password if it contain capital letter, digit and count of symbols
 * @param  {string} password
 *
 * @returns {boolean}
 */
export default (password) => gt(password.length, 7) && hasCapitalLetter(password) && hasDigit(password);
