import * as TYPES from './Auth.types';

const initialState = {
  id: null,
  companyName: null,
  companyInfo: null,
  loading: false,
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.SET_ID:
      return {
        ...state,
        id: action.payload.id,
      };
    case TYPES.SET_COMPANY_NAME:
      return {
        ...state,
        ...action.payload,
      };
    case TYPES.SET_COMPANY_INFO:
      return {
        ...state,
        companyInfo: {
          ...state?.companyInfo,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;
