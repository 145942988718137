import { useEffect } from 'react';

export const useEnterPressed = (enterPressedCallback) => {
  useEffect(() => {
    const handleWindowClick = (e) => {
      /* istanbul ignore else */
      if (e.key === 'Enter') {
        enterPressedCallback();
      }
    };
    window.addEventListener('keypress', handleWindowClick);
    return () => window.removeEventListener('keypress', handleWindowClick);
  }, [enterPressedCallback]);
};
