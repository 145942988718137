const ANSWER_POINTS = {
  RIGHT: 1,
  WRONG: -5,
};

const DEFAULT_ANSWER = {
  fields: {
    answerPlainText: '',
    answerValue: ANSWER_POINTS.RIGHT,
    correctAnswer: true,
    title: 'Custom Answer',
  },
};

const DEFAULT_QUESTION = {
  fields: {
    questionPlainText: '',
    concepts: ['Other'],
    type: 'Other',
    points: 5,
    categories: ['Other'],
    title: 'Custom Question',
    answers: [
      DEFAULT_ANSWER,
      {
        ...DEFAULT_ANSWER,
        fields: {
          ...DEFAULT_ANSWER.fields,
          correctAnswer: false,
          answerValue: ANSWER_POINTS.WRONG,
        },
      },
    ],
  },
};

export default {
  DEFAULT_ANSWER,
  DEFAULT_QUESTION,
  ANSWER_POINTS,
};
