import { useState, useCallback, useMemo } from 'react';
import _ from 'lodash';

export const useTableRowSelection = ({ count = 0, rows = [], rowsPerPage = 25, selectionKey = '' }) => {
  // This may be needed when the server responds with a different row/item count--notification specific

  // const [rowCount, setRowCount] = useState(0);
  const [include, setInclude] = useState([]);
  const [exclude, setExclude] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [allDataSelected, setAllDataSelected] = useState(false);
  const [allRowsOnPage, setAllRowsOnPage] = useState(false);

  // Determines whether or not the data is paginated
  const hasPagination = count > rowsPerPage;

  const paginatedSelectedRows = useMemo(
    () =>
      hasPagination
        ? _.intersectionBy(selectedRows, rows, (r) => (selectionKey ? r[selectionKey] : r.user_id))
        : selectedRows,
    [hasPagination, selectedRows, rows, selectionKey],
  );

  // When called, this function handles singular selection/deselection of a row or rows in the table
  const handleSelectRow = ({ checked, row }) => {
    if (!checked) {
      setAllRowsOnPage(false);
      // setAllDataSelected(false);

      setExclude((prevExclude) => (selectionKey ? [...prevExclude, row[selectionKey]] : [...prevExclude, row.user_id]));

      setInclude((prevInclude) =>
        prevInclude.filter((r) => (selectionKey ? r !== row[selectionKey] : r !== row.user_id)),
      );
    }

    if (checked && !allDataSelected) {
      setInclude((prevInclude) => (selectionKey ? [...prevInclude, row[selectionKey]] : [...prevInclude, row.user_id]));

      setExclude((prevExclude) =>
        prevExclude.filter((r) => (selectionKey ? r !== row[selectionKey] : r !== row.user_id)),
      );
    }

    setSelectedRows((prevSelected) =>
      checked
        ? [...prevSelected, row]
        : prevSelected.filter((r) =>
            selectionKey ? r[selectionKey] !== row[selectionKey] : r.user_id !== row.user_id,
          ),
    );
  };

  // When called, this function handles selecting all of the rows on the current page. There is a separate function that handles selected ALL of the paginated data
  const handleSelectPage = ({ checked, allData }) => {
    setAllRowsOnPage(checked);
    setSelectedRows(checked ? rows : []);

    if (checked) {
      setInclude((prevInclude) => [...prevInclude, ...rows.map((r) => (selectionKey ? r[selectionKey] : r.user_id))]);
    }

    if (!allData) {
      setInclude(checked ? rows.map((r) => (selectionKey ? r[selectionKey] : r.user_id)) : []);

      setExclude([]);
    }

    if (allData || !checked) {
      setInclude([]);
      setExclude([]);
    }

    if (!checked) {
      setAllDataSelected(false);
    }
  };

  // When called, this function handles selecting ALL of the data from all of the pages in the table. It also handles a visual cue of showing all of the current page's rows as selected
  const handleSelectAll = () => {
    setAllDataSelected(true);
    handleSelectPage({ checked: true, allData: true });
  };

  // When called, this callback resets the boolean piece of state that determines if ALL participants are selected in the table or not
  const handleDeselectAll = () => handleSelectPage({ checked: false });

  return {
    include,
    exclude,
    selectedRows,
    allDataSelected,
    allRowsOnPage,
    paginatedSelectedRows,
    handleSelectRow,
    handleSelectPage,
    handleSelectAll,
    handleDeselectAll,
  };
};
