import { gt, isNull } from 'lodash';
import { hasFalsyValue } from '..';
import { COMMON_ERRORS } from '../../../_constants';

/**
 * Validate answer object and return error or boolean
 * @param  {string} {value
 * @param  {number} currentAnswerNumber
 * @param  {array} answers
 * @param  {string} name
 * @param  {number} max=null
 * @param  {number} initial=false
 * @param  {boolean} required=true}
 *
 * @returns {string | boolean} error
 */
export default ({ value, currentAnswerNumber, answers, name, max = null, initial = false, required = true }) => {
  let error =
    (hasFalsyValue(value) && COMMON_ERRORS.isRequired(name)) ||
    (!isNull(max) && gt(value.length, max) && COMMON_ERRORS.moreThan(name, max)) ||
    (!!answers.find((subAnswer, idx) => value === subAnswer.fields.answerPlainText && idx !== currentAnswerNumber) &&
      COMMON_ERRORS.duplicateAnswer);

  error = !required && hasFalsyValue(value) ? null : error;

  error = error && initial ? null : error;

  return error;
};
