import { isUndefined, isPlainObject, isEqual } from 'lodash';

/**
 * Touch two objects to one object with common fields
 * and values from editedObj
 *
 * @example getTouchedDataObject({ a: 1, b: 2}, { a: 2, c: 3 })
 * return { a: 2}
 *
 * @param {object} initialObj
 * @param {object} editedObj

 *
 * @return {object}
 */

export const getTouchedDataObject = (initialObj, editedObj) => {
  if (!isPlainObject(initialObj) || !isPlainObject(editedObj)) {
    // eslint-disable-next-line
    console.warn('Both params in "getTouchedDataObject" should be objects');
    return {};
  }

  return Object.keys(initialObj).reduce((obj, key) => {
    if (Array.isArray(initialObj[key])) {
      return isEqual(initialObj[key], editedObj[key]) ? obj : { ...obj, [key]: editedObj[key] };
    }

    return initialObj[key] === editedObj[key] || isUndefined(editedObj[key]) ? obj : { ...obj, [key]: editedObj[key] };
  }, {});
};
