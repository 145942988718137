/**
 * Replace first find under score to empty space
 *
 * @example removeUnderscore('hello_world')
 * return 'hello world'
 *
 * @param {string} str
 *
 * @return {string}
 *
 */
export const splitCamelCase = (str = '', replaceKeys = false) => {
  let string = str;

  // For i18n translations
  if (replaceKeys) {
    string = str === 'campaignIds' ? 'Campaigns' : str;
  }

  return typeof string === 'string' ? string.replace(/([a-z])([A-Z])/g, '$1 $2') : string;
};
