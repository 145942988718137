import { useQuery } from 'react-query';
import { merge } from 'lodash';

import { useAuth0, httpClient } from '../../lib';

const loadDomainSettings = (tenantId) =>
  httpClient.get(`/notification/email-domain-settings`, { params: { tenant_id: tenantId } });

export const useDomainSettingsQuery = (options) => {
  const {
    user: { tenant_id: tenantId },
  } = useAuth0();

  return useQuery(
    ['configuration/email-domain-settings', tenantId],
    () => loadDomainSettings(tenantId),
    merge({ enabled: !!tenantId }, options),
  );
};
