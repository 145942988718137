export default {
  SUCCESS_USER_ADD: 'User created successfully',
  SUCCESS_ATTRIBUTE_CHANGE: 'Attribute changed succesfully',
  SUCCESS_COMPANY_ADD: 'Company created successfully',
  SUCCESS_AUDIENCE_ADD: 'Audience created successfully',
  SUCCESS_COMPANY_EDIT: 'Company updated successfully',
  SUCCESS_EXPORT_COMPANY_LIST: 'Company list exported successfully',
  SUCCESS_EXPORT_POLICY_LIST: 'Users policy list exported successfully',
  SUCCESS_DOWNLOAD_USER_LIST: 'User list exported successfully',
  SUCCESS_DOWNLOAD_HISTORY_LIST: 'History list exported successfully',
  SUCCESS_IMPORT_USER_LIST_TEMPLATE: 'User list template uploaded successfully. Started processing users',
  SUCCESS_EXPORT_USER_LIST_TEMPLATE: 'User list template exported successfully',
  SUCCESS_EXPORT_LS_ADMINS_LIST: 'LS admins list exported successfully',
  SUCCESS_USER_UPDATE: 'User updated successfully',
  SUCCESS_USER_ATTRIBUTES_UPDATE: 'Update Attributies in progress. Reload page to update data.',
  SUCCESS_PASSWORD_RESET: 'Reset password in progress. Reload page to update data.',
  SUCCESS_USER_DEACTIVATE: 'User deactivating in progress. Reload page to update data.',
  SUCCESS_USER_ACTIVATE: 'User activating in progress. Reload page to update data.',
  SUCCESS_ACCESS_UPDATE: 'Access update in progress. Reload page to update data.',
  SUCCESS_ADD_TO_MY_COLLECTION: 'This folder was added to My Collection successfully',
  SUCCESS_ENTRY_RENAME: 'This folder was renamed successfully',
  SUCCESS_ENTRY_REMOVE: 'This folder was successfully removed from My Collection',
  SUCCESS_REMOVE_QUESTION: 'Question was deleted successfully',
  SUCCESS_ADD_QUESTION: 'Question was added successfully',
  SUCCESS_EDIT_QUESTION: 'Question was updated successfully',
  SUCCESS_PROFILE_DATA_CHANGED: 'Profile data updated successfully',
  SUCCESS_COPY: 'Text is copied',
  INFO_EDIT_QUESTION: 'Please finish editing your question',
  INFO_CATALOG_SUBSCRIPTIONS: 'This campaign should have any subscription to display content in LS Catalog',
  INFO_EXCEPTIONS_BLOCK:
    'Exceptions to rules allows audiences to include participants that don’t fit its rules, or deny access to content assigned to participants that do fit rules.',
  ERROR_MAIN: 'Something went wrong!',
  ERROR_AMOUNT_ANSWERS: "Amount of answers shouldn't be less than 2",
  ERROR_NAME: 'This name already exists. Please try another name.',
  SITE_URL_DISABLED: 'Site Url can be editable only during company creation',
  WARNING_PROFILE_EMAIL_CHANGE: 'After updating the email, you  will redirect to the login page',
  CONFIRM_ACCESS_CHANGE: 'You attempting to set "Customer Admin" access for all users. Confirm this action?',
  BLOCKED_USERS_MAILING: 'Emails will not be sent to blocked users',
  LS_ADMIN_WRONG_MAIL: 'You attempting to add a user with an email address not belonging to livingsecurity.com.',
  SUCCESS_TOKEN_CREATE: 'Token was created successfully',
  SUCCESS_TOKEN_DELETE: 'Token was deleted successfully',
  CONFIGURATION_SUCCESS_SAVE: 'Configuration was saved successfully',
  UNAVAILABLE_EMAIL: 'The email domain is not in list of authorized domains',

  CUSTOM_NOTIFICATIONS: {
    SUCCESS_NOTIFICATION_CREATE: 'Notification was created successfully',
    SUCCESS_NOTIFICATION_EDIT: 'Notification was edited successfully',
    SUCCESS_CUSTOMIZED_BRANDING_SAVE: 'Branding data saved successfully',

    ERROR: {
      FETCH_CUSTOM_NOTIFICATIONS: 'Custom Notifications failed',
      FETCH_CREATE_CUSTOM_NOTIFICATIONS: 'Create Custom Notifications failed',
    },
  },

  CAMPAIGN: {
    WARNING: {
      STOP: 'Are you sure you want to stop this campaign?',
      DELETE: 'Are you sure you want to delete this campaign?',
      ARCHIVE: 'Are you sure you want to archive this campaign?',
      DELETE_ACTION: 'Are you sure you want to delete this action?',
      DELETE_CONTENT: 'Are you sure you want to remove this content from training?',
      DELETE_NOTIFICATION: 'Are you sure you want to remove this notification from training?',
      STATIC_TO_DYNAMIC:
        'Changing to dynamic campaign will immediately introduce new participants to the campaign. Are you sure you want to change campaign type?',
    },
    INFO: {
      DUE_DATE_SET_TO_ONE: 'Due Date Reminder will be removed because you set Due Date to 1',
    },
    SUCCESS: {
      DUPLICATE: 'Campaign duplicated successfully',
      ADD: 'Campaign created successfully',
      DELETE: 'Campaign deleted successfully',
      STOP: 'Campaign stopped successfully',
      ACTIVATE: 'Campaign activated successfully',
      ARCHIVE: 'Campaign archived successfully',
      RESTORE: 'Campaign restored successfully',
      RESUME: 'Campaign resumed successfully',
      RENAME: `Campaign's name was changed successfully`,
      DESCRIPTION: `Campaign's description was changed successfully`,
      PRIORITY: 'Campaign successfully set as priority',
      TEST: 'Campaign successfully set as test.',
    },
    ERROR: {
      ACTIVE_CAMPAIGN_NO_CONTENT: 'Active campaign must have content assigned',
      FETCH_CAMPAIGN: 'Campaign not found',
      EDIT_CAMPAIGN: 'Campaign is stopped. It can not be editable. Changes will not be saved',
      SET_TEST_CAMPAIGN: 'There was an issue setting the campaign as a test. Please try again.',
    },
  },

  CATALOG: {
    SUCCESS: {
      CUSTOMIZE: 'Entry copied successfully',
      COPY_FOLDER: 'Folder copied successfully',
    },
    INFO: {
      CANT_REMOVE:
        'Removing content from “My Collection” or customizing it is not possible while that content is assigned to a participant.  When content is no longer active for any participant, it can then be modified.',
    },
    ERROR: {
      CUSTOMIZE: 'Entry copying failed',
      COPY_FOLDER: 'Folder copying failed',
    },
  },

  AUDIENCE: {
    SUCCESS: {
      RULE_ADD: 'Rule was added successfully',
      RULE_DELETE: 'Rule was deleted successfully',
      MULTIPLE_RULE_DELETE: 'Rules were deleted successfully',
      EXCEPTION_RULE_ADD: 'Rule exception was added successfully',
      EXCEPTION_RULE_DELETE: 'Rule exception was deleted successfully',
      SAVE: 'Audience was saved successfully',
      RENAME: 'Audience renamed successfully',
      REVERT: 'Audience was reverted successfully',
      DELETE: 'Audience was deleted successfully',
      DOWNLOAD_AUDIENCE: 'Audience was exported successfully',
      CHANGE_ADMISSION: 'Admission changed successfully',
      DUPLICATE: 'Audience duplicated successfully',
    },
    ERROR: {
      FETCH: 'Audience not found',
      EXCEPTIONS_LIMIT: (limit) => `${limit} exceptions allowed only`,
      ATTACHED_TO_CAMPAIGNS: (campaigns) =>
        campaigns.length === 1
          ? `This audience cannot be removed because it is attached to ${campaigns[0]} campaign`
          : `This audience cannot be removed because it is attached to these campaigns: ${campaigns.join(', ')}`,
    },
    INFO: {
      LEAVE: 'Changes will not be saved. Do you really want to leave the page?',
    },
  },

  // Campaign Builder Info
  CAMPAIGN_BUILDER_AUDIENCE: 'Participants on the campaign.\nCreate an audience and assign them to a campaign',
  CAMPAIGN_BUILDER_ACTIVE_CAMPAIGN_AUDIENCE: 'Active campaign must have at least one audience',
  CAMPAIGN_BUILDER_ACTIONS: 'Validate, Duplicate or Delete the campaign',
  CAMPAIGN_BUILDER_ACTIONS_NOT_ACTIVE: 'Validate, Duplicate the campaign',
  CAMPAIGN_BUILDER_ACTIONS_STOPPED: 'Validate, Duplicate or Archive the campaign',
  CAMPAIGN_BUILDER_SHOW_IF: 'Rules to evaluate when this training should show',
  CAMPAIGN_BUILDER_TRAINING_TYPE: 'Training can be Mandatory or Optional impacting the engagement reporting',
  CAMPAIGN_BUILDER_NICKNAME: 'Choose a friendly name for your content',
  CAMPAIGN_BUILDER_DUE_DATE: 'Set the date by when the Audience must complete the Campaign',
  CAMPAIGN_BUILDER_BADGES: 'Configurable badges to encourage participation',
  CAMPAIGN_BUILDER_FEATURED_TRAINING: 'Content that will be prominently displayed to Audience',
  CAMPAIGN_BUILDER_REPETITIONS_INTERVAL: 'Frequency at which notifications are sent',
  CAMPAIGN_BUILDER_PRIORITY_BADGE: 'This shows the priority campaign first. The others will be in alphabetical order',
  CAMPAIGN_BUILDER_PRIORITY:
    'If this is selected, this campaign will be the first campaign shown the audience sees, all other campaigns will be listed in alphanumeric order',

  // Campaign view Info
  CAMPAIGN_AUDIENCE: 'Participants on the campaign',
  CAMPAIGN_IMPACT: 'Potential increase of the member’s Security Score after completing the campaign',
  CAMPAIGN_TOTAL_POINTS: 'Maximum number of points available upon training completion',
  CAMPAIGN_SECURITY_SCORE_IMPACT: 'Potential increase of the member’s Security Score after completing the campaign',

  // Audience info
  AUDIENCE_RULES: 'Rules let you narrow down audience based on attributes',
  AUDIENCE_CAMPAIGNS: 'Assign audiences to campaigns',
  AUDIENCE_COLUMNS: 'Customize your table view by selecting columns',

  // Reporting
  QUESTIONS_CATEGORIES: 'Customize your table view by selecting categories',
  CAMPAIGNS_STATUSES: 'Customize your table view by selecting a status type',

  // messages with params
  ERROR_UPLOADED_FILE_TOO_BIG: (number) => `Input file should be less than ${number}MB`,
  REQUIRED_FIELD_ERROR: (field) => `${field} is required`,
  AUTH0_LOGIN_ERROR: 'Login failed. Unknown error.',
  AUTH0_BLOCKED_USER: 'Sorry, you can’t log in. User was deactivated.',
  AUTH0_UNAUTHORIZED_USER: 'Login failed. User has no company.',
  SEARCH_INFO: (symbolsCount) => `Search by First Name, Last Name, or Email (minimum ${symbolsCount} characters)`,
  COMPANIES_SEARCH_INFO: (symbolCount) => `Search by Company or Site URL (minimum ${symbolCount} characters)`,
  CAMPAIGNS_SEARCH_INFO: (symbolCount) => `Search by Name (minimum ${symbolCount} characters)`,
  CATALOG_SEARCH_INFO:
    'Search box filters the list of series or independent modules when provided with three or more symbols.',
  QUESTION_INFO: (series) => `Add ${series} to My Collection to customize its content  `,
  WRONG_FILE_FORMAT: (format, acceptedFileTypes) => `File format such as ${format} is wrong. Use ${acceptedFileTypes}`,
};
