import React, { lazy } from 'react';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeedbackModal, LazyLoad, useToggle } from '@livingsecurity/shared';

import { AuthorizedLayout } from 'components';

const UserManagementComponentPkg = lazy(() => import('@livingsecurity/user-management'));

const UserManagement = (props) => {
  const { t } = useTranslation('shared');
  const [feedbackModalOpen, toggleFeedbackModal] = useToggle(false);
  const { enablePlatformAdmin } = useFlags();

  return (
    <>
      {feedbackModalOpen && (
        <FeedbackModal content={t('messages.downloads.notification.success')} onClose={() => toggleFeedbackModal()} />
      )}
      <AuthorizedLayout withBottomPaddingForHubspot hideSidebar={enablePlatformAdmin}>
        <LazyLoad component={UserManagementComponentPkg} toggleFeedbackModal={toggleFeedbackModal} {...props} />
      </AuthorizedLayout>
    </>
  );
};

export default UserManagement;
