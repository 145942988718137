import styled, { css } from 'styled-components';
import { Link as DefaultLink } from 'react-router-dom';
import { ImgStyles } from '@livingsecurity/shared';
import { TextEllipsis } from '@livingsecurity/shared/dist/assets/styles/mixins';

export const Title = styled.div`
  display: grid;
  grid-template-columns: min-content minmax(25%, 1fr) min-content;
  column-gap: 5px;
  align-items: center;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: var(--dark-blue-grey);
  cursor: pointer;
`;

export const TitleLabel = styled.div`
  width: 100%;
  white-space: nowrap;

  ${TextEllipsis}
`;

export const List = styled.ul`
  margin: 7px 0 0;
`;

export const Link = styled(DefaultLink)`
  width: 100%;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: var(--dark-blue-grey);
  font-weight: 300;

  ${TextEllipsis}

  &:hover {
    font-weight: normal;
    text-decoration: none;
  }
`;

export const ListItem = styled.li`
  padding: 0 0 0 32px;
  font-size: 14px;
  line-height: 1.43;
  color: var(--dark-blue-grey);
  height: 26px;
  display: flex;
  align-items: center;
  margin: 4px 0;

  position: relative;

  &:hover {
    background: ${({ dark }) => (dark ? 'var(--dark-blue-grey)' : 'var(--light-gray75)')};
  }

  & > svg {
    position: absolute;
    left: 6px;
    top: 50%;
    transform: translateY(-50%);
  }

  ${({ isActiveRoute, dark }) =>
    isActiveRoute &&
    css`
      background: ${dark ? 'var(--dark-blue-grey)' : 'var(--light-gray75)'};

      ${Link} {
        font-weight: bold;
      }
    `};
`;

export const Wrapper = styled.div`
  background: transparent;
  overflow: hidden;
  padding: 0 10px;
  border-radius: 10px;
  max-height: 30px;
  transition: all 0.4s linear;

  ${({ isOpen, dark }) =>
    isOpen &&
    css`
      padding: 10px;
      background: ${dark ? 'var(--dark-blue)' : 'var(--white)'};
      max-height: fit-content;
      -moz-box-shadow: ${dark ? 'inset 0 0 10px var(--black-two33)' : ''};
      -webkit-box-shadow: ${dark ? 'inset 0 0 10px var(--black-two33)' : ''};
      box-shadow: ${dark ? 'inset 0 0 10px var(--black-two33)' : ''};

      ${Title} {
        font-weight: bold;
      }
    `};

  ${({ dark }) =>
    dark &&
    css`
      ${Link}, ${Title} {
        color: var(--white);
      }
    `}

  ${({ highlighted }) =>
    highlighted &&
    css`
      ${Title} {
        font-weight: bold;
      }
    `}
`;
