import { useState, useCallback, useMemo } from 'react';

export const useMultipleLoadingState = (initialData) => {
  if (!Array.isArray(initialData)) {
    // eslint-disable-next-line no-console
    console.error('Wrong argument passed to useMultipleLoadingState hook: array expected');
  }
  const [loadingState, setLoadingState] = useState(() =>
    initialData.reduce((acc, field) => ({ ...acc, [field]: false }), {}),
  );

  const setLoadingStatus = useCallback(
    (field, status) => setLoadingState((prev) => ({ ...prev, [field]: status })),
    [],
  );

  const allLoaded = useMemo(() => Object.values(loadingState).every((item) => !item), [loadingState]);

  return {
    loadingState,
    setLoadingStatus,
    allLoaded,
  };
};
