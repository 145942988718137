import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Heading1,
  InputRHF,
  Paragraph,
  Button,
  Img,
  BUTTON_TYPES,
  notification,
  URLS,
  InputStyles,
  ERROR_ICON,
} from '@livingsecurity/shared';

import Layout from '../Layout';

import { useRegisterUser } from '../../hooks';
import { formFields } from './_constants';

import * as GlobalStyled from '../../styles';
import * as Styled from './styles';
import 'react-phone-number-input/style.css';

require('yup-phone');

const schema = yup.object().shape({
  first_name: yup.string().trim().required('First name is required.'),
  last_name: yup.string().trim().required('Last name is required.'),
  company_name: yup.string().trim().required('Company name is required.'),
  user_email: yup.string().trim().email().required('An email address is required.'),
  phone_number: yup
    .string()
    .phone('', true, 'A valid phone number is required.')
    .required('A valid phone number is required.'),
});

const Register = () => {
  const history = useHistory();
  const location = useLocation();

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      first_name: '',
      last_name: '',
      company_name: '',
      user_email: location?.state?.email || '',
      phone_number: '',
    },
    mode: 'onChange',
  });

  const { mutate: registerUser, isLoading: isRegisteringUser } = useRegisterUser({
    onSuccess: (data, variables) => {
      if (data?.location) {
        history.push(URLS.onboardLoading, { formData: variables, ...data });
      }
    },
    onError: (error) => {
      console.error(error);
      notification.error('Something went wrong. Please try again later.');
    },
  });

  const handleRegisterUser = (data) => {
    const { first_name, last_name } = data;
    const sanitizedData = { ...data };

    sanitizedData.user_name = `${first_name} ${last_name}`;

    registerUser(sanitizedData);
  };

  if (!location?.state?.email) {
    history.push(URLS.welcome);
  }

  return (
    <GlobalStyled.Wrapper>
      <Layout>
        <Styled.MainContainer>
          <Heading1>Create your account</Heading1>
          <Paragraph style={{ marginBottom: '2rem' }}>Register with your work email address to get started.</Paragraph>
          <GlobalStyled.Form onSubmit={handleSubmit(handleRegisterUser)}>
            {formFields.map((field) =>
              field.name === 'phone_number' ? (
                <GlobalStyled.InputWrapper>
                  <Controller
                    name={field.name}
                    control={control}
                    render={({ field: fieldProps, fieldState }) => (
                      <>
                        <InputStyles.Label htmlFor={field.name}>{field.placeholder}</InputStyles.Label>
                        <InputStyles.MainInputWrapper hasError={fieldState.error?.message}>
                          <InputStyles.InputWrapper>
                            <PhoneInput
                              placeholder={field.placeholder}
                              {...fieldProps}
                              id="phone-input"
                              defaultCountry="US"
                            />

                            {fieldState.error?.message && <Img size={[14, 14]} src={ERROR_ICON} />}
                          </InputStyles.InputWrapper>
                          <InputStyles.Error aria-label="error">{fieldState.error?.message}</InputStyles.Error>
                        </InputStyles.MainInputWrapper>
                      </>
                    )}
                  />
                </GlobalStyled.InputWrapper>
              ) : (
                <GlobalStyled.InputWrapper>
                  <InputRHF
                    key={field.name}
                    name={field.name}
                    control={control}
                    placeholder={field.placeholder}
                    type={field.type}
                    title={field.placeholder}
                    floatingLabel={false}
                  />
                </GlobalStyled.InputWrapper>
              ),
            )}

            <Button
              variant={BUTTON_TYPES.SKY}
              size="large"
              style={{ width: '100%', marginTop: '1rem' }}
              loading={isRegisteringUser}
              disabled={isRegisteringUser}
              type="submit"
            >
              Create account
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                style={{ width: '2rem', marginLeft: '.5rem' }}
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
              </svg>
            </Button>
          </GlobalStyled.Form>
        </Styled.MainContainer>
      </Layout>
    </GlobalStyled.Wrapper>
  );
};

export default Register;
