import { isPlainObject } from 'lodash';

/**
 * Convert object with fields
 * to object with fields of previews object fields values
 * and new value from props
 *
 * @example createInitialValues({ test: 'hello' }, 'world')
 * return { hello: 'world' }
 *
 * @param {object} obj
 * @param {any} initialValue
 *
 * @return {object}
 */
export const createInitialValues = (obj, initialValue) => {
  if (!isPlainObject(obj)) {
    /* eslint-disable-next-line */
    console.error('First param in "createInitialValues" must be object');
    return obj;
  }

  return Object.values(obj).reduce((newObj, value) => ({ ...newObj, [value]: initialValue }), {});
};
