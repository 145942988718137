import { useState, useMemo } from 'react';

export const useStateList = (list = [], currentItem, findIdxPredicate = (i) => i === currentItem) => {
  const [activeIdx, setActiveIdx] = useState(() => {
    const idx = list.findIndex(findIdxPredicate);
    return idx >= 0 ? idx : 0;
  });

  const actions = useMemo(
    () => ({
      setStateAt: (num) => {
        if (!list.length || num === activeIdx) return 0;
        const newIdx = num >= 0 ? num % list.length : list.length + (num % list.length);
        setActiveIdx(newIdx);
        return newIdx;
      },
      next: () => actions.setStateAt(activeIdx + 1),
      prev: () => actions.setStateAt(activeIdx - 1),
    }),
    [activeIdx, list.length],
  );

  return {
    ...actions,
    currentIdx: activeIdx,
    state: list[activeIdx],
  };
};
