export const PuzzleStates = {
  INTRO: 'INTRO',
  PLAYING: 'PLAYING',
  INCORRECT: 'INCORRECT',
  CONCLUSION: 'CONCLUSION',
  TIMEISUP: 'TIMEISUP',
  RESTART: 'RESTART',
};

// ReactDnd Item Types
export const SINGLE_LIST_ITEM = 'SINGLE_LIST_ITEM';

export const PuzzleProviders = {
  FIREBASE: 'FIREBASE',
  SOCKET_IO: 'SOCKET_IO',
  LOCAL: 'LOCAL',
};

export const PUZZLE_TYPES = {
  HOTSPOT: 'HOTSPOT',
  VISHING: 'VISHING',
  CRAFT_PHISH: 'CRAFT_PHISH',
  DATING_GAME: 'DATING_GAME',
  UNSCRAMBLE: 'UNSCRAMBLE',
  REORDER: 'REORDER',
  CORKBOARD: 'CORKBOARD',
  RESPONSE: 'RESPONSE',
  CLASSIFY: 'CLASSIFY',
  FLAGS: 'FLAGS',
  PHISHING: 'PHISHING',
  CAMERA_FEED: 'CAMERA_FEED',
  IMAGE_COMPLETE: 'IMAGE_COMPLETE',
};

export const PUZZLE_VARIATIONS = {
  [PUZZLE_TYPES.CRAFT_PHISH]: ['trainingGrounds', 'entranceExam'],
  [PUZZLE_TYPES.VISHING]: ['phishingIrl', 'vishing', 'criticalMass', 'entranceExam', 'coworker'],
  [PUZZLE_TYPES.CORKBOARD]: ['trainingGrounds'],
  [PUZZLE_TYPES.FLAGS]: ['phishingIrl', 'entranceExam', 'criticalMass'],
  [PUZZLE_TYPES.CLASSIFY]: ['criticalMass', 'classify'],
  [PUZZLE_TYPES.DATING_GAME]: ['entranceExam', 'trainingGrounds'],
  [PUZZLE_TYPES.UNSCRAMBLE]: ['entranceExam', 'criticalMass', 'unscrambler', 'unscramble'],
  [PUZZLE_TYPES.REORDER]: ['entranceExam'],
  [PUZZLE_TYPES.PHISHING]: ['phishery', 'rawphish', 'sushi', 'phishing', 'phish1', 'phish3', 'phish5'],
  [PUZZLE_TYPES.RESPONSE]: ['entranceExam'],
  [PUZZLE_TYPES.HOTSPOT]: [
    'spotpatrol',
    'trueeye',
    'zombieland',
    'criticalmass',
    'storeexam',
    'parkhack',
    'entranceexam',
    'guardians',
  ],
  [PUZZLE_TYPES.CAMERA_FEED]: ['feed'],
  [PUZZLE_TYPES.IMAGE_COMPLETE]: ['criticalMass'],
};

export const PUZZLE_STATE = {
  UPDATE_STATE: 'UPDATE_STATE',
};
