import styled from 'styled-components';
import { ButtonWrapper } from '../../Button/styles';

export const Message = styled.div`
  margin-bottom: 15px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;

  ${ButtonWrapper}:not(:last-child) {
    margin-right: 10px;
  }
`;

export const Wrapper = styled.div``;
