import { useQuery, useMutation } from 'react-query';
import { httpClient } from '../lib';

const getSlackStatus = ({ tenantId = '' }) => httpClient.get(`/slack-native/slack/integration?tenant_id=${tenantId}`);

const updateSlackStatus = ({ tenantId = '', body = {} }) =>
  httpClient.post(`/slack-native/slack/integration?tenant_id=${tenantId}`, body);

export const useSlackStatus = ({ tenantId = '', queryKey = [] }) => {
  const { data, isLoading, isError, isSuccess, error } = useQuery(queryKey, () => getSlackStatus({ tenantId }));

  return {
    hasInstallations: data?.hasInstallations,
    status: data?.status,
    isStatusLoading: isLoading,
    isStatusError: isError || data?.creation_status === 'failed',
    isStatusSuccess: isSuccess,
    statusError: error,
  };
};

export const useUpdateSlackStatus = (mutationOptions = {}) => {
  return useMutation(({ tenantId = '', body = {} }) => {
    return updateSlackStatus({ tenantId, body });
  }, mutationOptions);
};
