export const getMediaValue = (file, field = 'file') => {
  if (!file) return null;
  let fileData;
  switch (field) {
    case 'videoFile':
      fileData = file;
      return {
        format: fileData.format,
        originalUrl: fileData.original_url,
        publicID: fileData.public_id,
      };
    case 'video':
      fileData = file.fields.videoFile[0];
      return {
        format: fileData.format,
        originalUrl: fileData.original_url,
        publicID: fileData.public_id,
      };
    default:
      if (!file?.fields?.file) return null;
      fileData = file?.fields?.file;
      return {
        fileName: fileData.fileName.substr(0, fileData.fileName.lastIndexOf('.')),
        contentType: fileData.contentType,
        url: fileData.url,
      };
  }
};
