import { COMMON_ERRORS } from '../../../_constants';
import { isSubdomain, hasFalsyValue } from '..';
/**
 * Validate link and return error string
 * @param  {} {value
 * @param  {} name='link'
 * @param  {} required=false
 * @param  {} flag=true}
 *
 * @returns {string} error
 */
export default ({ value, name = 'link', required = false, flag = true }) => {
  let error =
    (hasFalsyValue(value) && COMMON_ERRORS.isRequired(name)) ||
    (!isSubdomain(value) && COMMON_ERRORS.isNotValid(name)) ||
    (!flag && COMMON_ERRORS.exist(name));
  error = !required && hasFalsyValue(value) ? null : error;
  return error;
};
