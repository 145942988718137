export default {
  name: 'name',
  description: 'description',
  audienceType: 'audience_type',
  audiences: 'audiences',
  assignDay: 'assign_day',
  assignDate: 'assign_date',
  trainingType: 'training_type',
  nickname: 'nickname',
  dueDay: 'due_day',
  notificationsEnabled: 'notifications_enabled',
  startBefore: 'start_before',
  startAfter: 'start_after',
  repetitions: 'repetitions',
  repetitionsInterval: 'repetitions_interval',
  requiredPassingGrade: 'required_passing_grade',
  isPriority: 'is_priority',
};
