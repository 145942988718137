export default [
  {
    value: 25,
    label: '25',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 100,
    label: '100',
  },
  // TODO temporary disabled, until we figure out how to handle more than 100 everywhere
  // {
  //   value: 125,
  //   label: '125',
  // },
  // {
  //   value: 150,
  //   label: '150',
  // },
  // {
  //   value: 200,
  //   label: '200',
  // },
];
