export const formFields = [
  {
    name: 'first_name',
    placeholder: 'First name',
    type: 'text',
  },
  {
    name: 'last_name',
    placeholder: 'Last name',
    type: 'text',
  },
  {
    name: 'company_name',
    placeholder: 'Company name',
    type: 'text',
  },
  {
    name: 'user_email',
    placeholder: 'Work email',
    type: 'email',
  },
  {
    name: 'phone_number',
    placeholder: 'Phone number',
    type: 'tel',
  },
  // {
  //   name: 'password',
  //   placeholder: 'Password',
  //   type: 'password', // This is the only difference
  // },
];
