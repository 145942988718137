import { map, reject, iteratee } from 'lodash';

/**
 * Similar to lodash `_.differenceBy`, but returns the symmetric difference between two arrays, after applying the provided function to each array element of both.
 * @param {Array} a First array
 * @param {Array} b Second array
 * @param {Function | String | Array | Record<string, any>} fn The iteratee invoked per elem
 * @returns {Array} Returns the new array of filtered values.
 */
export const symmetricDifferenceBy = (a, b, fn) => {
  const sA = new Set(map(a, fn));
  const sB = new Set(map(b, fn));
  const iterateeCb = iteratee(fn);
  return [...reject(a, (i) => sB.has(iterateeCb(i))), ...reject(b, (i) => sA.has(iterateeCb(i)))];
};
