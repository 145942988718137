export const getLinkWithParams = (link, params) => {
  if (!link) return console.warn(`Argument 'link' is required in 'getLinkWithParams' util`);
  if (params !== undefined && (typeof params !== 'object' || params === null))
    return console.warn(`Argument 'params' should be an object in 'getLinkWithParams' util`);

  const linkParts = link.split`/`.slice(1);

  return linkParts.reduce((acc, item) => {
    const isParameter = item.includes`:`;
    const isRequired = item.includes`:` && !item.includes`?`;
    const part = item.match(/(\w)(.*)(\w)/)[0];

    const newPart = isParameter ? params?.[part] : part;
    if (!newPart && isRequired) {
      throw Error(`Parameter ${part} is required for '${link}' link in 'getLinkWithParams' util`);
    }
    return `${acc}${newPart ? `/${newPart}` : ''}`;
  }, '');
};
