/* istanbul ignore file */
import { useMutation, useQuery } from 'react-query';

import { useAuth0, httpClient } from '../../lib';

const createJob = (body) => httpClient.post('/notification/send-notification-job', body);

const updateJob = (jobId, body, isImpersonate, tenantId) =>
  httpClient.put(`/notification/send-notification-job/${jobId}${isImpersonate ? `?tenant_id=${tenantId}` : ''}`, body);

const updateJobInAnalytics = ({ url, body }) => httpClient.post(`${url}`, body);

export const useCreateJob = (queryKey) => {
  const {
    user: { tenant_id: tenantId },
  } = useAuth0();

  const { data, ...rest } = useQuery(queryKey, () => createJob({ tenant_id: tenantId }));

  return {
    ...data,
    ...rest,
  };
};

export const useUpdateJob = (mutationOptions) => {
  const {
    user: { email, name },
  } = useAuth0();

  return useMutation(({ inAnalytics, jobId, body, url, isImpersonate, tenantId }) => {
    if (inAnalytics) {
      return updateJobInAnalytics({ url, body });
    } else {
      return updateJob(
        jobId,
        {
          ...body,
          sent_by: {
            email,
            name,
          },
        },
        isImpersonate,
        tenantId,
      );
    }
  }, mutationOptions);
};
