import { campaignDetailsHandlers, feedbackHandlers } from './domains/analytics';
import { contenfulCampaignsHandlers } from './domains/content-catalog';
import { checkEmailHandlers, registerHandlers } from './domains/onboarding';
import { assignmentsHandlers } from './domains/participant-dashboard';

export const handlers = [
  ...campaignDetailsHandlers,
  ...contenfulCampaignsHandlers,
  ...feedbackHandlers,
  ...checkEmailHandlers,
  ...registerHandlers,
  ...assignmentsHandlers,
];
