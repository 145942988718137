export default {
  LS_ADMIN: 'LS_ADMIN',
  CAMPAIGN_MGR: 'CAMPAIGN_MGR',
  CUSTOMER_ADMIN: 'CUSTOMER_ADMIN',
  ENTERPRISE_ADMIN: 'ENTERPRISE_ADMIN',
  TRAINING_DEMO: 'TRAINING_DEMO', // Limited to content catalog
  PARTICIPANT: 'PARTICIPANT',

  UNIFY_CUSTOMER_ADMIN: 'UNIFY_CUSTOMER_ADMIN',
  UNIFY_CUSTOMER_LIMITED_ADMIN: 'UNIFY_CUSTOMER_LIMITED_ADMIN',
  UNIFY_LS_ADMIN: 'UNIFY_LS_ADMIN',

  TEAMS_COLLABORATOR: 'TEAMS_COLLABORATOR',
  TEAMS_CUSTOMER_ADMIN: 'TEAMS_CUSTOMER_ADMIN',
  TEAMS_LS_ADMIN: 'TEAMS_LS_ADMIN',

  PHISHING_CUSTOMER_ADMIN: 'PHISHING_CUSTOMER_ADMIN',
  PHISHING_LS_ADMIN: 'PHISHING_LS_ADMIN',
};
