import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 50px;
  font-size: 20px;

  ${({ coverElement }) =>
    coverElement &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      z-index: 6;
      background-color: var(--white80);
    `}
`;

export const Loader = styled.img``;
