import { useEffect, useState } from 'react';
import { clientContentful } from '../lib';
import { CT_LOCALIZATION } from '../_constants';

export const useGetLocales = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      const enFallback = { name: 'English (United States)', code: CT_LOCALIZATION.EN };
      try {
        setLoading(true);

        const result = await clientContentful.getLocales();
        setData(result?.items ?? [enFallback]);
      } catch (e) {
        setData([enFallback]);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return { loading, data };
};
