import { rest } from 'msw';
import {
  mockCampaignIdError,
  mockCampaignsForAssignments,
  mockContentOrderForCampaigns,
  mockParticipantDashboardAssignments,
} from '../../entities/assignments';

export const handlers = [
  // Fetch assignments
  rest.get(`${process.env.REACT_APP_API_URL}/user-content/assignments`, async (req, res, ctx) => {
    const params = await req.url.searchParams;

    return res(ctx.status(200), ctx.json(mockParticipantDashboardAssignments()));
  }),

  // Fetch campaigns for assignments
  rest.post(`${process.env.REACT_APP_API_URL}/campaigns/batch-get`, async (req, res, ctx) => {
    const body = await req.json();

    if (body) {
      return res(ctx.status(200), ctx.json(mockCampaignsForAssignments()));
    } else {
      return res(ctx.status(400), ctx.json(mockCampaignIdError()));
    }
  }),

  // Fetch content order for campaigns
  rest.post(`${process.env.REACT_APP_API_URL}/campaigns/content_list`, async (req, res, ctx) => {
    const { campaign_ids: campaignIds } = await req.json();

    if (campaignIds?.length) {
      return res(ctx.status(200), ctx.json(mockContentOrderForCampaigns()));
    } else {
      return res(ctx.status(400), ctx.json(mockCampaignIdError()));
    }
  }),

  // Fetch next due assignment
  rest.post(`${process.env.REACT_APP_API_URL}/user-content/campaigns/assignments/featured`, async (req, res, ctx) => {
    const { campaign_ids: campaignIds } = await req.json();

    if (campaignIds?.length) {
      return res(
        ctx.status(200),
        ctx.json({
          featuredAssignments: [
            {
              contentId: '5WIb9nDJnEVMC1tr9u88YX',
              dueDate: '2023-07-29T08:50:43.263848+00:00',
              completionDate: null,
              id: 'a1wtQaSzQ8W-FQ==',
              required: true,
              expirationDate: '2023-07-09T08:50:43.263848+00:00',
              progress: 0,
              action_id: '76a142fc-6400-4338-b737-cf679025a14e',
              assignedDate: '2023-06-29T08:50:43.270499+00:00',
              campaign_id: '72850c00-b0fa-4ea3-9376-5e6877210439',
              campaign_priority: false,
              campaign_state: 'ACTIVE',
            },
          ],
        }),
      );
    } else {
      return res(ctx.status(400), ctx.json(mockCampaignIdError()));
    }
  }),
];
