/**
 * Convert object with keys to array of objects with keys and values
 *
 * @example getCatalogPreviewData(['duration'], { duration: 3 })
 * return [{ key: duration, value: '3mins' }]
 *
 * @param {string[]} list
 * @param {object} data

 *
 * @return {object[]}
 */
export const getCatalogPreviewData = (list, data) =>
  Object.entries(data).reduce(
    (acc, [key, value]) =>
      list.includes(key)
        ? acc.concat({
            key: key === 'targetedAudiences' ? 'target roles' : key.replace(/([a-z])([A-Z])/g, '$1 $2'),
            value: key === 'duration' ? `${value} min${value > 1 ? 's' : ''}` : value,
          })
        : acc,
    [],
  );
