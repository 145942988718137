import { useState, useMemo } from 'react';
import { useQuery } from 'react-query';
import { uniq, partition, orderBy } from 'lodash';

import { ChatConversations, fetchUsersConversations } from '../handlers/conversations';

const useConversation = ({ user }) => {
  const [activeConversation, setActiveConversation] = useState({});

  const {
    data: usersConversation,
    isConversationLoading,
    ...conversations
  } = useQuery(ChatConversations.get({ userId: user?.email }), () => fetchUsersConversations({ userId: user?.email }), {
    enabled: !!user?.email,
    onSuccess: (conversationData) => {
      console.log(conversationData);
      // TODO: Sort by updatedAt date and return the most recent conversation
      setActiveConversation(conversationData?.conversationHistory);
    },
  });

  return {
    activeConversation,
    isConversationLoading,
    conversationInformation: {
      ...conversations,
    },
  };
};

export default useConversation;
