import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useTranslation } from 'react-i18next';
import { Button, BUTTON_TYPES } from '@livingsecurity/shared';

import * as Styled from './styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//platform-cdn.livingsecurity.com/pkg/pdf.worker.min.js`;

const Slideshow = ({ file, onComplete }) => {
  const { t } = useTranslation('contentViewer');
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  return (
    <>
      <Styled.SlideshowContainer>
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={console.error}
          loading=""
          style={{ overflow: 'hidden' }}
          externalLinkTarget="_blank"
        >
          <Styled.Page
            pageNumber={pageNumber}
            width={`${window.innerWidth - 250}`}
            canvasBackground="transparent"
            className="pdf-page"
            loading=""
            renderAnnotationLayer={true}
          />
        </Document>
      </Styled.SlideshowContainer>
      <Styled.NotificationContainer>
        {numPages > 1 ? (
          <>
            {pageNumber > 0 ? (
              <Button
                variant={BUTTON_TYPES.SKY}
                disabled={pageNumber <= 1}
                onClick={() => changePage(-1)}
                text={t('slideshow.previous')}
              />
            ) : (
              <div />
            )}
            <Styled.NotificationInfo>
              <Styled.NotificationNextContent>
                {t('slideshow.page-counter', { pageNumber, numPages })}
              </Styled.NotificationNextContent>
            </Styled.NotificationInfo>
          </>
        ) : (
          <div />
        )}
        <Button
          variant={BUTTON_TYPES.SKY}
          onClick={pageNumber === numPages ? onComplete : () => changePage(1)}
          text={pageNumber === numPages ? t('continue') : t('slideshow.next')}
        />
      </Styled.NotificationContainer>
    </>
  );
};

export default Slideshow;
