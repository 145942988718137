export default [
  { value: 'given_name', label: 'First Name' },
  { value: 'family_name', label: 'Last Name' },
  { value: 'job_title', label: 'Job Title' },
  { value: 'department', label: 'Department' },
  { value: 'division', label: 'Division' },
  { value: 'organization', label: 'Organization' },
  { value: 'city', label: 'City' },
  { value: 'state', label: 'State' },
  { value: 'country', label: 'Country' },
  { value: 'user_type', label: 'User Type' },
  { value: 'timezone', label: 'Time Zone' },
  { value: 'company_roles', label: 'Company roles' },
  { value: 'manager_name', label: 'Manager name' },
  { value: 'manager_value', label: 'Manager email' },
];
