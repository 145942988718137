import { compose, createStore, applyMiddleware } from 'redux';

import clientContentful from './clientContentful';
import httpClient from './httpClient';
import clientMiddleware from '../middlewares/clientMiddleware';

// Devtools options: https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md

export const configureStore = (rootReducer, devtoolsOptions) => {
  const isEnabledByFF = window?.ldClient?.variation('enable-redux-devtools');
  const isDevelopment = process.env.NODE_ENV === 'development';

  const isEnabled =
    typeof window !== 'undefined' && window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && (isDevelopment || isEnabledByFF);

  const composeEnhancers = isEnabled ? window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(devtoolsOptions) : compose;

  const middleware = [clientMiddleware(httpClient, clientContentful)];
  const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middleware)));

  return store;
};
