import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@livingsecurity/shared';
import { ChatLayout, ChatConversation } from './components';

import * as Styled from './styles';

const Chatbot = (props) => {
  const { t } = useTranslation(['shared']);
  const { user, loading } = useAuth0();

  if (!user || loading) return null;

  return (
    <Styled.Wrapper id="chatbot" className="dark" data-theme="dark">
      <ChatLayout>
        <ChatConversation user={user} {...props} />
      </ChatLayout>
    </Styled.Wrapper>
  );
};

Chatbot.propTypes = {};

export default Chatbot;
