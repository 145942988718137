import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useAuth0,
  getUnifyUrl,
  ErrorBoundary,
  USER_GROUPS,
  Iframe,
  MARKETING_PAGE,
  UNIFY_URLS,
} from '@livingsecurity/shared';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { AuthorizedLayout } from 'components';

const UnifyDashboard = ({ history }) => {
  const { locale, isUserHasRights } = useAuth0();
  const { enableEmbeddedUnify, openExternalUnifyApp } = useFlags();
  const hasAccess =
    enableEmbeddedUnify &&
    isUserHasRights([
      USER_GROUPS.UNIFY_CUSTOMER_ADMIN,
      USER_GROUPS.UNIFY_CUSTOMER_LIMITED_ADMIN,
      USER_GROUPS.UNIFY_LS_ADMIN,
    ]);
  const embeddedUrl = hasAccess ? `${getUnifyUrl()}?embedded=true&locale=${locale}` : MARKETING_PAGE.UNIFY;

  useEffect(() => {
    if (window.location?.href?.includes('livingsecurity.com')) {
      window.document.domain = 'livingsecurity.com';
    }
  }, []);

  useEffect(() => {
    if (!hasAccess && window.location?.pathname !== UNIFY_URLS.demo) {
      history.replace(UNIFY_URLS.demo);
    }
  }, [hasAccess, history]);

  // This is temporary until Unify is ready to be embedded
  if (openExternalUnifyApp && hasAccess) {
    return (
      <>
        <AuthorizedLayout app="UNIFY" withBottomPaddingForHubspot>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 250px)',
              fontSize: '24px',
            }}
          >
            <div>Coming Soon!</div>
            <div>
              <a href={getUnifyUrl()} target="_blank" rel="noreferrer">
                Open Unify Dashboard
                <i className="fas fa-external-link" style={{ marginLeft: '10px', fontSize: '20px' }} />
              </a>
            </div>
          </div>
        </AuthorizedLayout>
      </>
    );
  }

  return (
    <>
      <AuthorizedLayout app="UNIFY" withBottomPaddingForHubspot>
        <ErrorBoundary scope="unify-dashboard">
          <Iframe
            id="unify-embed-frame"
            className="embedded-iframe"
            data-testid="unify-embed-frame"
            title="Living Security Unify"
            src={embeddedUrl}
          />
        </ErrorBoundary>
      </AuthorizedLayout>
    </>
  );
};

UnifyDashboard.propTypes = {
  history: PropTypes.any.isRequired,
};

export default UnifyDashboard;
