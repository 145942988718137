import styled from 'styled-components';

export const PuzzleResponsiveWrapper = styled.div`
  color: white;
  height: 100%;
  /* zoom: 0.7; */
`;

export const PuzzleContainer = styled.div`
  position: relative;
  overflow: hidden;
  // max-width: 1360px;
  // min-width: 800px;
  // min-height: 400px;
  // max-height: 760px;
  width: 100%;
  height: 100%;
`;
