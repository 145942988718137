import React, { useEffect, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  ErrorBoundary,
  URLS,
  Iframe,
  TEAMS_URLS,
  getTeamsManagementUrl,
  getTeamsPermalinkFromRoute,
} from '@livingsecurity/shared';

const TeamsParticipants = ({ history }) => {
  const { pathname, search } = useLocation();
  const teamsPublicUrls = useMemo(
    () => [
      URLS.teamsBooking,
      URLS.teamsCancelBooking,
      URLS.teamsEditBooking,
      URLS.teamsBookingResult,
      URLS.teamsBookingCancel,
      URLS.teamsLeaderboard,
      // we want to check either the legacy URLs (above) or the new ones that include /teams (below)
      TEAMS_URLS.teamsBooking,
      TEAMS_URLS.teamsCancelBooking,
      TEAMS_URLS.teamsEditBooking,
      TEAMS_URLS.teamsBookingResult,
      TEAMS_URLS.teamsBookingCancel,
      TEAMS_URLS.teamsLeaderboard,
    ],
    [],
  );

  useEffect(() => {
    if (window.location?.href?.includes('livingsecurity.com')) {
      window.document.domain = 'livingsecurity.com';
    }
  }, []);

  useEffect(() => {
    if (!pathname.includes(URLS.teamsBase)) {
      history.replace(`${URLS.teamsBase}${pathname}${search}`);
    }
  }, [pathname, history, search]);

  const getEmbeddedUrl = useCallback(() => {
    if (!teamsPublicUrls.includes(pathname)) {
      return pathname.includes(URLS.teamsBase)
        ? `${getTeamsManagementUrl().view}${pathname.split(URLS.teamsBase)[1]}`
        : `${getTeamsManagementUrl().view}${pathname}`;
    }
    return `${getTeamsManagementUrl().view}${getTeamsPermalinkFromRoute(pathname, window.location.href)}${search}`;
  }, [pathname, search, teamsPublicUrls]);

  return (
    <ErrorBoundary scope="teams-participant-booking">
      <Iframe
        id="teams-embed-participant-booking"
        className="embedded-iframe"
        data-testid="teams-embed-participant-booking"
        title="Living Security Teams Booking"
        src={getEmbeddedUrl()}
      />
    </ErrorBoundary>
  );
};

TeamsParticipants.propTypes = {
  history: PropTypes.any.isRequired,
};

export default TeamsParticipants;
