/**
 * Replace first find under score to empty space
 *
 * @example removeUnderscore('hello_world')
 * return 'hello world'
 *
 * @param {string} str
 *
 * @return {string}
 *
 */
export const removeUnderscore = (str = '') => (typeof str === 'string' ? str.replace('_', ' ') : str);
