import { useMutation } from 'react-query';
import { registerUser } from '../_api';

const useRegisterUser = (mutationOptions = {}) => {
  return useMutation((data = {}) => {
    return registerUser(data);
  }, mutationOptions);
};

export default useRegisterUser;
