import React, { lazy } from 'react';
import { AuthorizedLayout } from 'components';
import { LazyLoad } from '@livingsecurity/shared';

const CompanyManagementPkg = lazy(() => import('@livingsecurity/company-management'));

const Companies = (props) => {
  return (
    <AuthorizedLayout withoutPadding hideSidebar>
      <LazyLoad component={CompanyManagementPkg} {...props} />
    </AuthorizedLayout>
  );
};

export default Companies;
