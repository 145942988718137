/**
 * Convert array of values to array with options
 *
 * @example convertToOptions([1])
 * return [{ value: 1, label: 1}]
 *
 * @param {array} arr
 *
 * @return {array}
 */
export const convertToOptions = (arr) =>
  Array.isArray(arr) ? arr.map((element) => ({ label: element, value: element })) : [];
