import styled, { css } from 'styled-components';
import { BREAKPOINTS } from '@livingsecurity/shared';

export const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 20px 30px; */
  /* border: 1px solid #000; */

  height: ${(props) => (props.barHeight ? props.barHeight : '70px')};
  background-color: ${(props) => props.theme.bgColor || 'var(--dark-blue-two)'};
  ${({ theme }) =>
    theme.color &&
    css`
      color: ${theme.color} !important;
    `}

  position: relative;

  ${({ isSticky }) =>
    isSticky &&
    css`
      position: sticky;
      top: 0;
      z-index: 4;
    `}

  @media (max-width: ${BREAKPOINTS.TABLET}) {
    width: 100vw;
  }
`;

export const ItemsWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: ${(props) => (props.barHeight ? '0' : '0 20px 0 36px')};
  height: ${(props) => (props.barHeight ? props.barHeight : '70px')};
  ${({ theme }) =>
    theme.color &&
    css`
      color: ${theme.color} !important;
    `}
  position: relative;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
  margin-left: 16px;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
`;

export const ProductName = styled.div`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  margin: 20px 0 0 16px;
  color: var(--light-blue-grey-two);
  display: inline-block;
`;

export const ChatbotButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: white;
  height: 56px;
  width: 65px;
  margin: 0;
  border-left: 1px solid var(--slate-grey);
  cursor: pointer;

  &:hover {
    background: var(--dark-blue);
  }
`;
