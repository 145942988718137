export default {
  DANGER: 'DANGER',
  GREY: 'GREY',
  SKY: 'SKY',
  WHITE: 'WHITE',
  TRANSPARENT: 'TRANSPARENT',
  TRANSPARENT_SKY: 'TRANSPARENT_SKY',
  WITH_BORDER: 'WITH_BORDER',
  OUTLINE_WHITE: 'OUTLINE_WHITE',
  OUTLINE_SKY: 'OUTLINE_SKY',
  DISABLED: 'DISABLED',
  GREEN: 'GREEN',
  LS_BLUE: 'LS_BLUE',
};
