import React from 'react';
import { useLocation } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { URLS } from '../../_constants';

export const GlobalStyle = createGlobalStyle`
  div#hubspot-messages-iframe-container {
    display: ${(props) => (props.showHubspot ? 'initial !important;' : 'none !important;')};
  }
`;

export const GlobalStyleWithLocation = () => {
  const { pathname } = useLocation();

  return (
    <GlobalStyle
      showHubspot={pathname !== URLS.player && pathname !== URLS.dashboard && !pathname.includes('assignment')}
    />
  );
};

export const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border: 0;
`;
