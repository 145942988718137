import USER_GROUPS from './USER_GROUPS';

export default [
  { value: USER_GROUPS.PARTICIPANT, label: 'Participant' },
  { value: USER_GROUPS.CUSTOMER_ADMIN, label: 'Training Admin' },
  { value: USER_GROUPS.TRAINING_DEMO, label: 'Training Demo' },
  { value: USER_GROUPS.ENTERPRISE_ADMIN, label: 'Enterprise Admin', isPrivate: true },
  { value: USER_GROUPS.UNIFY_CUSTOMER_ADMIN, label: 'Unify Admin', isPrivate: true },
  { value: USER_GROUPS.UNIFY_CUSTOMER_LIMITED_ADMIN, label: 'Unify Limited Admin', isPrivate: true },
  // { value: USER_GROUPS.TEAMS_CUSTOMER_ADMIN, label: 'Teams Customer Admin', isPrivate: true },
  { value: USER_GROUPS.PHISHING_CUSTOMER_ADMIN, label: 'Phishing Admin', isPrivate: true },
  { value: USER_GROUPS.PHISHING_LS_ADMIN, label: 'Phishing Reseller', isPrivate: true },
  // Always private
  { value: USER_GROUPS.LS_ADMIN, label: 'LS Admin', isPrivate: true },
  // { value: USER_GROUPS.TEAMS_LS_ADMIN, label: 'Teams LS Admin', isPrivate: true },
];
