export default {
  DUPLICATE: 'duplicate',
  DELETE: 'delete',
  ACTIVATE: 'activate',
  ARCHIVE: 'archive',
  RESTORE: 'restore',
  STOP: 'stop',
  RESUME: 'resume',
  PRIORITY: 'priority',
  TEST: 'test',
};
