import moment from 'moment-timezone';
import { sortBy } from 'lodash';

const modificators = [
  'America/Detroit (ET)',
  'America/Indiana/Indianapolis (ET)',
  'America/Indiana/Marengo (ET)',
  'America/Indiana/Petersburg (ET)',
  'America/Indiana/Vevay (ET)',
  'America/Indiana/Vincennes (ET)',
  'America/Indiana/Winamac (ET)',
  'America/Kentucky/Louisville (ET)',
  'America/Kentucky/Monticello (ET)',
  'America/New_York (ET)',
  'America/Chicago (CT)',
  'America/Indiana/Knox (CT)',
  'America/Indiana/Tell_City (CT)',
  'America/Menominee (CT)',
  'America/North_Dakota/Beulah (CT)',
  'America/North_Dakota/Center (CT)',
  'America/North_Dakota/New_Salem (CT)',
  'America/Boise (MT)',
  'America/Denver (MT)',
  'America/Phoenix (MT)',
  'America/Los_Angeles (PT)',
  'America/Fort_Wayne (ET)',
  'America/Indianapolis (ET)',
  'America/Louisville (ET)',
  'America/Knox_IN (CT)',
].reduce((acc, item) => ({ ...acc, [item.split(' ')[0]]: item }), {});

const TIMEZONE_ARRAY = moment.tz
  .names()
  .reduce(
    (tzs, timezone) => tzs.concat({ timezoneName: timezone, timezoneTitle: modificators[timezone] || timezone }),
    [],
  );

export default sortBy(TIMEZONE_ARRAY, (item) => item.timezoneName);
