import { useEffect } from 'react';

export const useClickedOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      setTimeout(() => {
        handler(event);
      }, 0);
    };

    document.addEventListener('mouseup', listener);
    document.addEventListener('touchend', listener);

    return () => {
      document.removeEventListener('mouseup', listener);
      document.removeEventListener('touchend', listener);
    };
  }, [ref, handler]);
};
