import React from 'react';
import { Logo, LightLogo } from '@livingsecurity/shared';

import { Header } from './styles';

export const Navbar = () => {
  return (
    <Header>
      <nav>
        <ul>
          <li>{window.innerWidth <= 1024 ? <LightLogo /> : <Logo />}</li>
        </ul>
      </nav>
    </Header>
  );
};
