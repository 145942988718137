import { rest } from 'msw';

export const handlers = [
  rest.get(`${process.env.REACT_APP_API_URL}/onboard/check-email`, async (req, res, ctx) => {
    const email = req.url.searchParams.get('email');

    if (email.includes('gmail.com')) {
      return res(
        ctx.status(200),
        ctx.json({
          allow: false,
        }),
      );
    } else {
      return res(
        ctx.status(200),
        ctx.json({
          allow: true,
        }),
      );
    }
  }),
];
