import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { i18n } from '@livingsecurity/shared';

import Chatbot from './ChatbotView';

import './styles/tailwind.css';

export default (props) => {
  return (
    <I18nextProvider i18n={i18n}>
      <Chatbot {...props} />
    </I18nextProvider>
  );
};
