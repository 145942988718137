import { useMemo, useState } from 'react';
import { mapValues } from 'lodash';

const utils = {
  isAllTruthy: (o) => Object.values(o ?? {}).every(Boolean),
  isAllFalsy: (o) => Object.values(o ?? {}).filter(Boolean).length === 0,
  setTruthyAll: (o) => mapValues(o, () => true),
  setFalsyAll: (o) => mapValues(o, () => false),
};

export const useBooleanState = (defaultValues = {}) => {
  const [state, setState] = useState(defaultValues ?? {});

  const actions = useMemo(
    () => ({
      set: (key, value) => setState((prevState) => ({ ...prevState, [key]: value })),
      toggle: (key) => setState((prevState) => ({ ...prevState, [key]: !prevState[key] })),
      setTruthy: (key) => actions.set(key, true),
      setTruthyAll: () => setState(utils.setTruthyAll),
      setFalsy: (key) => actions.set(key, false),
      setFalsyAll: () => setState(utils.setFalsyAll),
      setAll: setState,
    }),
    [],
  );

  return {
    state,
    actions,
    utils: {
      isTruthy: (key) => !!state[key],
      isAllTruthy: () => utils.isAllTruthy(state),
      isAllFalsy: () => utils.isAllFalsy(state),
    },
  };
};
