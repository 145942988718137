/**
 * Adding before first find special symbol \\
 *
 * @example escapeSpecialSymbols('#hello')
 * return '\\#hello'
 *
 * @param {string} str
 *
 * @return {string}
 *
 */
export const escapeSpecialSymbols = (str) => (str?.length ? str.replace(/([^a-zA-Z0-9])/g, '\\$1') : '');
