export default {
  AUTH_CONFIG: {
    // domain: 'living-security.auth0.com',
    // domain: 'dev-ju0ef72c.auth0.com',
    domain: process.env.REACT_APP_AUTH_DOMAIN || 'identity-dev.livingsecurity.com',
    // clientId: 'VsGFpFuYT93w4IjTMi1w9bjGxY8jTm8I',
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID || 'rx9CP3CzExh1twZodlA08KM141w3m8Zw',
    audience: process.env.REACT_APP_AUTH_AUDIENCE || 'https://dev-ju0ef72c.auth0.com/api/v2/',
  },

  USERS_LIST: {
    PAGINATION: {
      PER_PAGE: process.env.REACT_APP_USER_LIST_PAGINATION || 25,
    },
  },

  NOTIFICATIONS_LIST: {
    PAGINATION: {
      PER_PAGE: 5,
      MAX: 10000, // to fetch all items
    },
  },

  USER_CLAIMS: {
    // custom claims
    IS_USER_CREATED: process.env.REACT_APP_USER_CLAIMS_NAMESPACE
      ? `${process.env.REACT_APP_USER_CLAIMS_NAMESPACE}/user_created`
      : 'http://test.com/claims/user_created',
    IS_FEDERATED_SLO: process.env.REACT_APP_USER_CLAIMS_NAMESPACE
      ? `${process.env.REACT_APP_USER_CLAIMS_NAMESPACE}/fedrated_slo`
      : 'http://test.com/claims/fedrated_slo',
    GROUPS: process.env.REACT_APP_USER_CLAIMS_NAMESPACE
      ? `${process.env.REACT_APP_USER_CLAIMS_NAMESPACE}/groups`
      : 'http://test.com/claims/groups',
    TEAMS_GROUPS: 'http://teams_mf',
    TENANT_ID: process.env.REACT_APP_USER_CLAIMS_NAMESPACE
      ? `${process.env.REACT_APP_USER_CLAIMS_NAMESPACE}/tenant_id`
      : 'http://test.com/claims/tenant_id',
    USER_ID: process.env.REACT_APP_USER_CLAIMS_NAMESPACE
      ? `${process.env.REACT_APP_USER_CLAIMS_NAMESPACE}/user_id`
      : 'http://test.com/claims/user_id',
  },

  PARTICIPANT_REPORT_LIST: {
    PAGINATION: {
      PER_PAGE: process.env.REACT_APP_PARTICIPANT_LIST_PAGINATION || 25,
    },
  },
};

// Universal login

// image: https://images.squarespace-cdn.com/content/v1/593044ab15cf7dbccaa2ed78/1553710850507-DJV1TBMP0VAWI1WNSSVT/ke17ZwdGBToddI8pDm48kAHOG77v-MnM_Ys_XLpVIiYUqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYxCRW4BPu10St3TBAUQYVKcwuTvpRw4PKK3XopuSewHN3s_5tU1RytWcGWe55hP5ypZ3BaZfS4aZR_wCKxK3Ohh/living+security.png
// primary color: #F16153;
// page background color: #081F35;
