import React from 'react';
import { Navbar } from './Navbar';

import * as Styled from '../../styles';

import WelcomeLeft from '../../assets/WelcomeLeft.webp';
import WelcomeRight from '../../assets/WelcomeRight.png';

const welcomeLeft = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '29%',
  height: '100%',
  zIndex: 0,
  clipPath: 'ellipse(90% 100% at left center)',
};

const welcomeRight = {
  position: 'absolute',
  top: 0,
  right: 0,
  maxWidth: '32%',
  minWidth: '29%',
  height: '100%',
  zIndex: 0,
  clipPath: 'ellipse(90% 100% at right center)',
};

const Layout = ({ children }) => {
  return (
    <>
      <Styled.SideImage src={WelcomeLeft} style={welcomeLeft} />
      <Navbar />
      <Styled.Container>{children}</Styled.Container>
      <Styled.SideImage src={WelcomeRight} style={welcomeRight} />
    </>
  );
};

export default Layout;
