import React from 'react';
import { useTranslation } from 'react-i18next';

import { Heading3, Paragraph } from '@livingsecurity/shared';

import Loader from './Loader';

const Failure = ({ failureMessages, icon, message }) => {
  const { t } = useTranslation('messages');

  // eslint-disable-next-line consistent-return
  const returnFormattedMessage = (rawMessage) => {
    if (rawMessage.includes('Domain')) return t('self-signup.domain-failure');
    if (rawMessage.includes('Email already registered')) return t('self-signup.email-failure');
  };

  const renderMessage = (rawMessage) => {
    return (
      <Paragraph style={{ marginBottom: 0, textAlign: 'center' }} key={rawMessage}>
        {returnFormattedMessage(rawMessage)}
      </Paragraph>
    );
  };

  const renderMessages = (rawMessages) => {
    return <div style={{ marginBottom: '1rem' }}>{rawMessages.map((rawMessage) => renderMessage(rawMessage))}</div>;
  };

  return (
    <div
      style={{
        maxWidth: '35vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Loader icon={icon} />
      <Heading3 style={{ marginBottom: '.5rem' }}>{t(message)}</Heading3>
      {failureMessages?.length > 1 ? renderMessages(failureMessages) : renderMessage(failureMessages)}
    </div>
  );
};

export default Failure;
