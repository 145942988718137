import React, { useState, useCallback } from 'react';
import MarkdownIt from 'markdown-it';
import LoadingDots from './LoadingDots';

const mdParser = new MarkdownIt({
  html: true,
});

const SHOW_BUTTONS = false;

/*
 * App wrapper that routes users to our various application routes
 */
export const ChatBubble = ({ message, avatarUrl, timestamp, position, botLoading, embedded }) => {
  const [isExpanded, toggleExpanded] = useState(false);

  const toggleMessageDetails = useCallback(() => {
    toggleExpanded(!isExpanded);
  }, [isExpanded]);

  const renderMarkdownAsHTML = useCallback((text = '') => {
    return mdParser.render(text);
  }, []);

  return (
    <li
      className={`chat chat-${position} flex items-end ${position === 'end' ? 'ml-auto' : 'mr-auto'} gap-x-2`}
      style={{
        maxWidth: embedded ? '100% !important' : '80%',
      }}
    >
      {position === 'start' && (
        <div className={`min-w-fit ${SHOW_BUTTONS && 'mb-7'}`}>
          <img
            className={`chat-image avatar flex-shrink-0 rounded-full select-none ${
              embedded ? 'h-[36px] w-[40px]' : 'h-[50px] w-[55px]'
            }`}
            src={avatarUrl}
            alt="Bot Avatar"
          />
        </div>
      )}

      <div className={`flex flex-col w-full items-${position}`}>
        <div
          className={`chat-bubble ${
            position === 'end'
              ? 'bg-primary/60 dark:bg-primary text-black'
              : 'bg-white text-black dark:text-white dark:bg-neutral'
          } ${position === 'start' && botLoading ? '' : 'py-2 px-3 space-y-1'} text-md shadow-lg`}
          style={{ maxWidth: 'calc(100% - 60px)' }}
          onDoubleClick={toggleMessageDetails}
        >
          {position === 'start' && botLoading ? (
            <LoadingDots />
          ) : (
            <div className="chat-text-inner" dangerouslySetInnerHTML={{ __html: renderMarkdownAsHTML(message) }} />
          )}
        </div>
        <div className={`chat-footer flex flex-col mt-1 mx-3 w-4/5 items-${position}`}>
          {isExpanded && (
            <div className={`flex opacity-20 text-neutral dark:text-white text-xs py-1 select-none`}>{timestamp}</div>
          )}
          {position === 'start' && SHOW_BUTTONS && (
            <div className="mt-1">
              <div className="sm:flex sm:justify-between">
                <div>
                  <div className="inline-flex border border-gray-200 rounded-full p-0.5 dark:border-white/30">
                    <button
                      type="button"
                      className="inline-flex flex-shrink-0 justify-center items-center h-4 w-4 rounded-full text-gray-500 hover:bg-primary hover:text-blue-800 focus:z-10 focus:outline-none focus:ring-2 focus:ring-primary transition-all dark:hover:bg-primary dark:hover:text-blue-200"
                    >
                      <svg
                        className="h-2 w-2"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z" />
                      </svg>
                    </button>
                    <button
                      type="button"
                      className="inline-flex flex-shrink-0 justify-center items-center h-4 w-4 rounded-full text-gray-500 hover:bg-primary hover:text-blue-800 focus:z-10 focus:outline-none focus:ring-2 focus:ring-primary transition-all dark:hover:bg-primary dark:hover:text-blue-200"
                    >
                      <svg
                        className="h-2 w-2"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8.864 15.674c-.956.24-1.843-.484-1.908-1.42-.072-1.05-.23-2.015-.428-2.59-.125-.36-.479-1.012-1.04-1.638-.557-.624-1.282-1.179-2.131-1.41C2.685 8.432 2 7.85 2 7V3c0-.845.682-1.464 1.448-1.546 1.07-.113 1.564-.415 2.068-.723l.048-.029c.272-.166.578-.349.97-.484C6.931.08 7.395 0 8 0h3.5c.937 0 1.599.478 1.934 1.064.164.287.254.607.254.913 0 .152-.023.312-.077.464.201.262.38.577.488.9.11.33.172.762.004 1.15.069.13.12.268.159.403.077.27.113.567.113.856 0 .289-.036.586-.113.856-.035.12-.08.244-.138.363.394.571.418 1.2.234 1.733-.206.592-.682 1.1-1.2 1.272-.847.283-1.803.276-2.516.211a9.877 9.877 0 0 1-.443-.05 9.364 9.364 0 0 1-.062 4.51c-.138.508-.55.848-1.012.964l-.261.065zM11.5 1H8c-.51 0-.863.068-1.14.163-.281.097-.506.229-.776.393l-.04.025c-.555.338-1.198.73-2.49.868-.333.035-.554.29-.554.55V7c0 .255.226.543.62.65 1.095.3 1.977.997 2.614 1.709.635.71 1.064 1.475 1.238 1.977.243.7.407 1.768.482 2.85.025.362.36.595.667.518l.262-.065c.16-.04.258-.144.288-.255a8.34 8.34 0 0 0-.145-4.726.5.5 0 0 1 .595-.643h.003l.014.004.058.013a8.912 8.912 0 0 0 1.036.157c.663.06 1.457.054 2.11-.163.175-.059.45-.301.57-.651.107-.308.087-.67-.266-1.021L12.793 7l.353-.354c.043-.042.105-.14.154-.315.048-.167.075-.37.075-.581 0-.211-.027-.414-.075-.581-.05-.174-.111-.273-.154-.315l-.353-.354.353-.354c.047-.047.109-.176.005-.488a2.224 2.224 0 0 0-.505-.804l-.353-.354.353-.354c.006-.005.041-.05.041-.17a.866.866 0 0 0-.121-.415C12.4 1.272 12.063 1 11.5 1z" />
                      </svg>
                    </button>
                  </div>
                  <button
                    type="button"
                    className="py-1 px-2 ml-4 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 transition-all text-sm dark:hover:bg-slate-800 dark:hover:text-gray-400 dark:hover:border-gray-900 dark:focus:ring-gray-900 dark:focus:ring-offset-gray-800"
                  >
                    <svg
                      className="h-3.5 w-3.5"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                      <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                    </svg>
                    Copy
                  </button>
                  <button
                    type="button"
                    className="py-1 px-2 ml-4 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 transition-all text-sm dark:hover:bg-slate-800 dark:hover:text-gray-400 dark:hover:border-gray-900 dark:focus:ring-gray-900 dark:focus:ring-offset-gray-800"
                  >
                    <svg
                      className="h-3.5 w-3.5"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z" />
                    </svg>
                    Share
                  </button>
                </div>

                <div className="mt-1 sm:mt-0">
                  <button
                    type="button"
                    className="py-1 px-2 ml-4 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 transition-all text-sm dark:hover:bg-slate-800 dark:hover:text-gray-400 dark:hover:border-gray-900 dark:focus:ring-gray-900 dark:focus:ring-offset-gray-800"
                  >
                    <svg
                      className="h-3.5 w-3.5"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                      <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                    </svg>
                    New answer
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {position === 'end' && (
        <div>
          <img
            className={`chat-image avatar flex-shrink-0 rounded-full select-none ${
              embedded ? 'h-[36px] w-[40px]' : 'h-[50px] w-[55px]'
            }`}
            src={avatarUrl}
            alt="User Avatar"
          />
        </div>
      )}
    </li>
  );
};

ChatBubble.defaultProps = {
  message: 'Testing',
  avatarUrl: '',
  timestamp: '2023-07-18',
  position: 'start',
};
