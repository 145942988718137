import styled, { css } from 'styled-components';
import { Img, BREAKPOINTS } from '@livingsecurity/shared';

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: white;
  overflow-x: hidden;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0 12rem;

  @media (max-width: ${BREAKPOINTS.TABLET}) {
    padding: 0 1.5rem;
  }
`;

export const SideImage = styled(Img)`
  @media (max-width: ${BREAKPOINTS.TABLET}) {
    display: none;
  }
`;

export const Form = styled.form`
  width: 100%;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  margin-bottom: 2rem;

  label {
    margin-bottom: 0.5rem;
  }

  div.PhoneInput {
    width: 100%;
  }

  div.PhoneInputCountry {
    margin-left: 0.75rem;
  }

  input.PhoneInputInput {
    width: 100%;
    color: var(--black-two);
    font-size: 16px;
    height: 100%;
    padding: 0 10px;
    border: none;
    background-color: var(--white);
    border-radius: 5px;
    resize: none; // for textarea

    &:disabled {
      background-color: var(--ice-blue);
      color: var(--light-blue-grey);
      border-color: unset;
      cursor: not-allowed;
    }

    // :read-only doesn't work correctly with :disabled
    &[readonly] {
      background-color: var(--white);
      color: var(--grey);
      user-select: all;
    }

    ${({ hasError }) =>
      hasError &&
      css`
        padding: 0 0 0 10px;
      `}
  }
`;
