/**
 * Concat array of query groups to string
 *
 * @example concatQueryGroup([
 *    ['hello', 'world'],
 *    ['test', 'message'],
 *  ])
 * return 'hello,world AND test,message'
 *
 * @param {array} qArray
 *
 * @return {string}
 *
 */
export const concatQueryGroup = (qArray) =>
  Array.isArray(qArray) ? qArray.filter((qParam) => qParam.length).join(' AND ') : '';
