export const exportFile = (data, dataType, extension, filename, isURL = false) => {
  const encodedContent = encodeURIComponent(data);
  const fileContent = `data:${dataType};charset=utf-8,${encodedContent}`;
  const link = document.createElement('a');

  link.setAttribute('href', isURL ? data : fileContent);
  link.setAttribute('download', `${filename || 'unnamed'}.${extension}`);
  document.body.appendChild(link); // Required for FF
  link.click();
  document.body.removeChild(link); // Required for FF
};
