import { useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { useAuth0, TablePersistor, NAVIGATION_ACTIONS, URLS } from '@livingsecurity/shared';

const tablesPersistor = TablePersistor();

export default () => {
  const { isAuthenticated, cancelImpersonate, logout } = useAuth0();
  const history = useHistory();
  const { params } = useRouteMatch();

  useEffect(() => {
    setTimeout(() => {
      switch (params.action) {
        case NAVIGATION_ACTIONS.CANCEL_IMPERSONATE:
          tablesPersistor.clear();
          cancelImpersonate();
          history.push(URLS.companies);
          break;
        case NAVIGATION_ACTIONS.LOG_OUT:
          if (isAuthenticated) logout(false);
          break;
        // add other navigation actions
        default:
      }
    }, 0);
  }, [params.action]); // eslint-disable-line

  return null;
};
