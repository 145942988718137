import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Heading3 } from '@livingsecurity/shared';

import Icon from './Icon';

const Loader = ({ icon, message }) => {
  const { t } = useTranslation('messages');
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <Icon icon={icon} />
      {message && <Heading3 style={{ marginTop: '2rem', fontSize: '2rem' }}>{t(message)}</Heading3>}
    </div>
  );
};

Loader.propTypes = {
  icon: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default Loader;
