import styled, { css } from 'styled-components';
import { BREAKPOINTS } from '@livingsecurity/shared';

export const Struct = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
`;

export const Container = styled.div`
  display: flex;
  flex: 1 0 auto;
`;

export const ContentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  overflow-x: auto;
`;

export const ContentBlock = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--pale-grey);
  box-shadow: 5px 3px 10px 0 var(--light-blue-grey);
`;

export const Content = styled.div`
  flex-grow: 1;
  height: 0;
  overflow: auto;
  ${({ withoutPadding, withBottomPaddingForHubspot }) =>
    withoutPadding && withBottomPaddingForHubspot
      ? css`
          padding: 0 0 96px 0;
        `
      : withoutPadding && !withBottomPaddingForHubspot
      ? css`
          padding: 0;
        `
      : !withoutPadding && withBottomPaddingForHubspot
      ? css`
          padding: 35px 45px 96px;
        `
      : css`
          padding: 35px 45px;
        `}

  @media (max-width: ${BREAKPOINTS.SM_LAPTOP}) {
    ${({ withoutPadding, withBottomPaddingForHubspot }) =>
      withoutPadding && withBottomPaddingForHubspot
        ? css`
            padding: 0 0 96px 0;
          `
        : withoutPadding && !withBottomPaddingForHubspot
        ? css`
            padding: 0;
          `
        : !withoutPadding && withBottomPaddingForHubspot
        ? css`
            padding: 35px 20px 96px;
          `
        : css`
            padding: 35px 20px;
          `}
  }
`;
