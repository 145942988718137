import { httpClient } from '@livingsecurity/shared';
import queryString from 'query-string';

// Query Keys
export const ChatConversations = {
  list: () => [{ name: 'chatbot.conversations.list' }],
  get: (params = {}) => [{ name: 'chatbot.conversations.get', ...params }],
};

// Handlers
export const fetchUsersConversations = (email) => {
  if (!email) return;
  return httpClient.post('/chatbot/chat/history', { userId: email });
};
