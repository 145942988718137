import React from 'react';

const Failed = (props) => (
  <svg
    width={150}
    height={150}
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width: 500,
      height: 500,
      transform: 'rotateY(0deg)',
    }}
    {...props}
  >
    <style>
      {
        '\n     .browser1-1 {animation:browser1-1 6s infinite;}\n     .browser1-2 {animation:browser1-2 6s infinite;}\n     .browser1-3 {animation:browser1-3 6s infinite;}\n     .browser1-4 {animation:browser1-4 6s infinite;}\n\n     @keyframes browser1-1 {\n         0%{transform: translate3d(0, -4px, 0);}\n         15%{transform: translate3d(0, 4px, 0);}\n         100%{transform: translate3d(0, -4px, 0);}\n     }\n     @keyframes browser1-2 {\n         0%{transform: translate3d(0, -4px, 0);}\n         30%{transform: translate3d(0, 4px, 0);}\n         100%{transform: translate3d(0, -4px, 0);}\n     }\n     @keyframes browser1-3 {\n         0%{transform: translate3d(0, -4px, 0);}\n         45%{transform: translate3d(0, 4px, 0);}\n         100%{transform: translate3d(0, -4px, 0);}\n     }\n     @keyframes browser1-4 {\n         0%{transform: translate3d(0, -4px, 0);}\n         60%{transform: translate3d(0, 4px, 0);}\n         100%{transform: translate3d(0, -4px, 0);}\n     }\n     @media (prefers-reduced-motion: reduce) {\n         .browser1-1, .browser1-2, .browser1-3, .browser1-4 {\n             animation: none;\n         }\n     }\n    '
      }
    </style>
    <g
      className="browser1"
      style={{
        animationDuration: '2s',
      }}
    >
      <path
        className="fillCls9"
        d="M128.666 135.688L147 62.0747L89.6208 14L10.8776 40.902L2 99.8101L34.8362 135.688H128.666Z"
        fill="rgba(241,97,83,1)"
        strokeWidth="2px"
      />
      <g className="browser1-3">
        <path
          className="strokeCls1"
          d="M105.676 33.8701H135.342C136.303 33.8701 137.225 34.252 137.905 34.9319C138.585 35.6117 138.967 36.5337 138.967 37.4951V58.9515H102.051V37.4951C102.051 36.5337 102.433 35.6117 103.113 34.9319C103.792 34.252 104.714 33.8701 105.676 33.8701V33.8701Z"
          stroke="#000000"
          strokeWidth="2px"
          strokeMiterlimit={10}
        />
        <path
          className="strokeCls3"
          d="M135.393 40.6934H105.246"
          stroke="#231F20"
          strokeWidth="2px"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="fillCls7"
          d="M106.383 38.4274C107.013 38.4274 107.524 37.9166 107.524 37.2865C107.524 36.6563 107.013 36.1455 106.383 36.1455C105.753 36.1455 105.242 36.6563 105.242 37.2865C105.242 37.9166 105.753 38.4274 106.383 38.4274Z"
          fill="rgba(0,0,0,1)"
          strokeWidth="2px"
        />
        <path
          className="fillCls7"
          d="M109.821 38.4274C110.451 38.4274 110.962 37.9166 110.962 37.2865C110.962 36.6563 110.451 36.1455 109.821 36.1455C109.191 36.1455 108.68 36.6563 108.68 37.2865C108.68 37.9166 109.191 38.4274 109.821 38.4274Z"
          fill="rgba(0,0,0,1)"
          strokeWidth="2px"
        />
        <path
          className="fillCls7"
          d="M113.254 38.4274C113.884 38.4274 114.395 37.9166 114.395 37.2865C114.395 36.6563 113.884 36.1455 113.254 36.1455C112.624 36.1455 112.113 36.6563 112.113 37.2865C112.113 37.9166 112.624 38.4274 113.254 38.4274Z"
          fill="rgba(0,0,0,1)"
          strokeWidth="2px"
        />
      </g>
      <g className="browser1-1">
        <path
          className="strokeCls1"
          d="M9.66406 34.4492H39.3292C40.2907 34.4492 41.2127 34.8311 41.8925 35.511C42.5723 36.1908 42.9542 37.1128 42.9542 38.0742V75.5078H6.03906V38.0742C6.03906 37.1128 6.42098 36.1908 7.1008 35.511C7.78062 34.8311 8.70265 34.4492 9.66406 34.4492Z"
          stroke="#000000"
          strokeWidth="2px"
          strokeMiterlimit={10}
        />
        <path
          className="strokeCls3"
          d="M39.3808 41.2725H9.23438"
          stroke="#231F20"
          strokeWidth="2px"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="fillCls7"
          d="M10.3714 39.0065C11.0016 39.0065 11.5124 38.4957 11.5124 37.8656C11.5124 37.2354 11.0016 36.7246 10.3714 36.7246C9.7413 36.7246 9.23047 37.2354 9.23047 37.8656C9.23047 38.4957 9.7413 39.0065 10.3714 39.0065Z"
          fill="rgba(0,0,0,1)"
          strokeWidth="2px"
        />
        <path
          className="fillCls7"
          d="M13.8089 39.0065C14.4391 39.0065 14.9499 38.4957 14.9499 37.8656C14.9499 37.2354 14.4391 36.7246 13.8089 36.7246C13.1788 36.7246 12.668 37.2354 12.668 37.8656C12.668 38.4957 13.1788 39.0065 13.8089 39.0065Z"
          fill="rgba(0,0,0,1)"
          strokeWidth="2px"
        />
        <path
          className="fillCls7"
          d="M17.2425 39.0065C17.8727 39.0065 18.3835 38.4957 18.3835 37.8656C18.3835 37.2354 17.8727 36.7246 17.2425 36.7246C16.6124 36.7246 16.1016 37.2354 16.1016 37.8656C16.1016 38.4957 16.6124 39.0065 17.2425 39.0065Z"
          fill="rgba(0,0,0,1)"
          strokeWidth="2px"
        />
      </g>
      <path
        className="fillCls8"
        d="M35.3789 48.2553H117.666C118.628 48.2553 119.55 48.6372 120.23 49.317C120.909 49.9968 121.291 50.9189 121.291 51.8803V122.84H31.7539V51.8803C31.7539 50.9189 32.1358 49.9968 32.8156 49.317C33.4955 48.6372 34.4175 48.2553 35.3789 48.2553Z"
        fill="#FFFFFF"
        strokeWidth="2px"
      />
      <path
        className="strokeCls2"
        d="M35.3789 48.2553H117.666C118.628 48.2553 119.55 48.6372 120.23 49.317C120.909 49.9968 121.291 50.9189 121.291 51.8803V122.84H31.7539V51.8803C31.7539 50.9189 32.1358 49.9968 32.8156 49.317C33.4955 48.6372 34.4175 48.2553 35.3789 48.2553Z"
        stroke="rgba(78,179,212,1)"
        strokeWidth="2px"
        strokeMiterlimit={10}
      />
      <g className="browser1-4">
        <path
          className="strokeCls1"
          d="M108.242 73.0977H143.375C144.336 73.0977 145.258 73.4796 145.938 74.1594C146.618 74.8392 147 75.7612 147 76.7227V112.248H104.617V76.7227C104.617 75.7612 104.999 74.8392 105.679 74.1594C106.359 73.4796 107.281 73.0977 108.242 73.0977V73.0977Z"
          stroke="#000000"
          strokeWidth="2px"
          strokeMiterlimit={10}
        />
        <path
          className="strokeCls3"
          d="M143.85 79.9209H107.809"
          stroke="#231F20"
          strokeWidth="2px"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="fillCls7"
          d="M108.95 77.654C109.58 77.654 110.091 77.1432 110.091 76.513C110.091 75.8829 109.58 75.3721 108.95 75.3721C108.319 75.3721 107.809 75.8829 107.809 76.513C107.809 77.1432 108.319 77.654 108.95 77.654Z"
          fill="rgba(0,0,0,1)"
          strokeWidth="2px"
        />
        <path
          className="fillCls7"
          d="M112.383 77.654C113.013 77.654 113.524 77.1432 113.524 76.513C113.524 75.8829 113.013 75.3721 112.383 75.3721C111.753 75.3721 111.242 75.8829 111.242 76.513C111.242 77.1432 111.753 77.654 112.383 77.654Z"
          fill="rgba(0,0,0,1)"
          strokeWidth="2px"
        />
        <path
          className="fillCls7"
          d="M115.821 77.654C116.451 77.654 116.962 77.1432 116.962 76.513C116.962 75.8829 116.451 75.3721 115.821 75.3721C115.191 75.3721 114.68 75.8829 114.68 76.513C114.68 77.1432 115.191 77.654 115.821 77.654Z"
          fill="rgba(0,0,0,1)"
          strokeWidth="2px"
        />
      </g>
      <g className="browser1-2">
        <path
          className="strokeCls1"
          d="M5.625 84.6475H47.559C48.5204 84.6475 49.4424 85.0294 50.1223 85.7092C50.8021 86.389 51.184 87.3111 51.184 88.2725V107.52H2V88.2725C2 87.3111 2.38192 86.389 3.06174 85.7092C3.74156 85.0294 4.66359 84.6475 5.625 84.6475V84.6475Z"
          stroke="#000000"
          strokeWidth="2px"
          strokeMiterlimit={10}
        />
        <path
          className="strokeCls3"
          d="M48.2754 91.4707H5.19141"
          stroke="#231F20"
          strokeWidth="2px"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="fillCls7"
          d="M6.33237 89.2038C6.96251 89.2038 7.47334 88.693 7.47334 88.0628C7.47334 87.4327 6.96251 86.9219 6.33237 86.9219C5.70224 86.9219 5.19141 87.4327 5.19141 88.0628C5.19141 88.693 5.70224 89.2038 6.33237 89.2038Z"
          fill="rgba(0,0,0,1)"
          strokeWidth="2px"
        />
        <path
          className="fillCls7"
          d="M9.76597 89.2038C10.3961 89.2038 10.9069 88.693 10.9069 88.0628C10.9069 87.4327 10.3961 86.9219 9.76597 86.9219C9.13583 86.9219 8.625 87.4327 8.625 88.0628C8.625 88.693 9.13583 89.2038 9.76597 89.2038Z"
          fill="rgba(0,0,0,1)"
          strokeWidth="2px"
        />
        <path
          className="fillCls7"
          d="M13.2035 89.2038C13.8336 89.2038 14.3444 88.693 14.3444 88.0628C14.3444 87.4327 13.8336 86.9219 13.2035 86.9219C12.5733 86.9219 12.0625 87.4327 12.0625 88.0628C12.0625 88.693 12.5733 89.2038 13.2035 89.2038Z"
          fill="rgba(0,0,0,1)"
          strokeWidth="2px"
        />
      </g>
      <path
        className="fillCls4"
        d="M89.9195 72.0906C90.6599 73.3675 91.2036 74.5148 91.2036 74.5148C91.2036 74.5148 95.8255 63.0054 96.43 61.676C97.0344 60.3465 100.387 58.7307 101.596 58.2929C102.848 57.8398 104.33 57.6857 103.741 58.8974C103.469 59.4557 101.807 60.3166 101.807 60.3166C101.807 60.3166 104.858 60.2568 105.432 59.9242C106.005 59.5916 109.057 56.9943 109.66 56.6916C110.264 56.3889 111.3 56.465 111.08 57.3713C111.08 57.3713 112.062 57.0088 112.424 57.432C112.787 57.8552 112.462 58.5802 112.145 58.9273C112.262 58.9523 112.37 59.0063 112.459 59.0843C112.549 59.1622 112.618 59.2616 112.659 59.3732C112.787 59.7502 112.334 60.8377 111.42 61.7521C111.42 61.7521 111.753 62.3783 111.232 63.126C110.711 63.8736 109.449 65.0817 108.241 65.5239C107.033 65.9662 103.166 66.9476 102.834 66.9476C102.501 66.9476 100.342 79.2799 99.9027 81.9161C99.4695 84.5189 97.8827 94.6943 91.6867 94.6943C90.0708 94.6943 87.971 93.4473 86.5809 90.9469C83.0764 84.663 80.1465 77.0007 80.1465 77.0007L89.9195 72.0906Z"
        fill="rgba(226,193,183,1)"
        strokeWidth="2px"
      />
      <path
        className="fillCls4"
        d="M48.17 73.1554C46.2832 69.8748 43.394 64.8614 42.8748 64.044C42.0927 62.8106 38.5773 61.4403 37.2913 61.4068C36.0054 61.3732 34.8789 61.4068 35.2505 62.3012C35.5686 63.0661 37.3611 63.4404 37.3611 63.4404C37.3611 63.4404 34.3315 63.8029 33.7171 63.5519C33.1027 63.3008 29.7214 61.1512 29.0816 60.9355C28.4418 60.7198 27.4268 60.9355 27.7694 61.8046C27.7694 61.8046 26.7471 61.5817 26.4463 62.0502C26.1454 62.5188 26.5677 63.1921 26.9293 63.493C26.8177 63.5338 26.7183 63.6022 26.6402 63.6917C26.5621 63.7812 26.5079 63.889 26.4825 64.005C26.4073 64.3965 27.0063 65.4115 28.0376 66.19C28.0376 66.19 27.7957 66.857 28.4146 67.5249C29.0336 68.1928 30.4501 69.215 31.7079 69.486C32.9658 69.757 36.9316 70.1956 37.2605 70.1494C37.4599 70.1213 39.1455 74.571 40.6943 78.5005C41.1474 79.6505 45.0443 91.3692 47.6425 93.6974C50.2408 96.0255 55.3801 99.3161 59.0033 87.988C62.6265 76.6599 59.6957 74.0317 59.6957 74.0317L52.1439 73.971L50.5752 77.0024C50.5752 77.0024 48.672 74.029 48.17 73.1554Z"
        fill="rgba(226,193,183,1)"
        strokeWidth="2px"
      />
      <path
        className="fillCls1"
        d="M83.9251 89.1462C85.5564 91.5831 88.6983 112.267 88.4564 122.841H57.0376C57.0376 122.841 56.0099 98.0474 59.0313 89.7099C62.0528 81.3724 83.9251 89.1462 83.9251 89.1462Z"
        fill="#0061FA"
        strokeWidth="2px"
      />
      <path
        className="fillCls4"
        d="M63.0658 45.8221C62.8537 45.5257 62.2447 45.4596 61.9076 45.5955C61.7414 45.6711 61.5956 45.7855 61.4826 45.9289C61.3695 46.0724 61.2925 46.2409 61.2578 46.4202C61.1923 46.7809 61.2372 47.153 61.3865 47.4878C61.5377 47.8472 61.7668 48.1687 62.0571 48.4289C62.3475 48.6892 62.692 48.8819 63.0658 48.993"
        fill="rgba(226,193,183,1)"
        strokeWidth="2px"
      />
      <path
        className="fillCls2"
        d="M74.8293 62.5822C77.1556 62.6828 81.6389 62.5595 83.3181 63.1259C85.7351 63.9415 90.4875 73.1155 90.4875 73.1155L80.9619 78.9862C80.9619 78.9862 83.8827 85.5882 83.8719 85.6453C83.8311 85.8465 83.9226 89.1462 83.9226 89.1462L59.0306 89.7098L58.8285 77.3441C58.8285 77.3441 51.5387 75.1292 50.4512 74.8066C50.4512 74.8066 53.7708 65.5075 57.2834 64.4291C60.7425 63.3679 66.4365 62.4009 66.4365 62.4009L74.8293 62.5822Z"
        fill="rgba(23,48,66,1)"
        strokeWidth="2px"
      />
      <path
        className="fillCls4"
        d="M70.7834 43.4604L72.3087 62.401L74.8307 62.5823C74.8307 62.5823 73.5022 66.9024 68.6365 66.9024C63.7709 66.9024 63.0078 63.0254 63.0078 63.0254L65.1701 62.5995L66.4389 51.4961L69.3606 42.9774L70.7834 43.4604Z"
        fill="rgba(226,193,183,1)"
        strokeWidth="2px"
      />
      <path
        className="fillCls5"
        d="M65.8672 56.5049C67.5691 56.2575 69.7441 54.5474 69.7441 54.5474V51.6066L66.3294 52.4667L65.8672 56.5049Z"
        fill="rgba(224,164,156,1)"
        strokeWidth="2px"
      />
      <path
        className="fillCls4"
        d="M69.7446 51.6066C68.2547 52.9759 65.2332 54.3861 63.5422 52.6135C61.8511 50.8408 63.3401 42.0203 63.3401 42.0203L70.1478 42.3828L69.7446 51.6066Z"
        fill="rgba(226,193,183,1)"
        strokeWidth="2px"
      />
      <path
        className="fillCls3"
        d="M70.1496 44.7191L68.8953 43.5038C68.8953 43.5038 66.3424 44.6819 65.3356 44.6185C64.3287 44.555 61.3281 44.1554 62.1537 40.2277C62.1537 40.2277 63.0799 41.0533 63.9662 41.0134C64.8525 40.9735 66.2418 40.1071 68.0144 40.2277C69.7871 40.3482 71.2162 41.7683 71.6793 43.5038C72.1424 45.2392 72.4687 46.6068 72.3563 47.5819C72.2439 48.557 71.3277 50.2807 71.3277 50.2807L70.9652 45.7368L70.1496 44.7191Z"
        fill="rgba(19,19,28,1)"
        strokeWidth="2px"
      />
      <path
        className="fillCls3"
        d="M65.3334 46.8742L66.0992 48.993L64.3066 49.5322L65.3334 46.8742Z"
        fill="rgba(19,19,28,1)"
        strokeWidth="2px"
      />
      <path
        className="fillCls4"
        d="M69.8304 44.8043C70.2835 44.3059 71.797 42.517 73.2134 44.2307C74.4215 45.6925 72.715 48.3822 70.6152 47.9164C68.5154 47.4506 69.8304 44.8043 69.8304 44.8043Z"
        fill="rgba(226,193,183,1)"
        strokeWidth="2px"
      />
      <path
        className="fillCls6"
        d="M67.414 47.3038C67.5812 47.3038 67.7167 47.1114 67.7167 46.8742C67.7167 46.637 67.5812 46.4446 67.414 46.4446C67.2468 46.4446 67.1113 46.637 67.1113 46.8742C67.1113 47.1114 67.2468 47.3038 67.414 47.3038Z"
        fill="rgba(18,13,13,1)"
        strokeWidth="2px"
      />
      <path
        className="fillCls6"
        d="M67.5423 49.3664C67.2034 49.4047 66.883 49.5406 66.6199 49.7577C66.3568 49.9747 66.1625 50.2635 66.0605 50.5889"
        fill="rgba(18,13,13,1)"
        strokeWidth="2px"
      />
      <path
        className="fillCls6"
        d="M63.8795 47.4623C64.0072 47.4623 64.1106 47.3159 64.1106 47.1352C64.1106 46.9545 64.0072 46.808 63.8795 46.808C63.7519 46.808 63.6484 46.9545 63.6484 47.1352C63.6484 47.3159 63.7519 47.4623 63.8795 47.4623Z"
        fill="rgba(18,13,13,1)"
        strokeWidth="2px"
      />
    </g>
  </svg>
);

export default Failed;
