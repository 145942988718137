export default {
  RIGHT: {
    START: 'right-start',
    CENTER: 'right-center',
    END: 'right-end',
  },
  LEFT: {
    START: 'left-start',
    CENTER: 'left-center',
    END: 'left-end',
  },
};
