import { useCallback } from 'react';

export const useScrollTo = ({ behavior = 'smooth', timeout = 0, offsetY = 0, parent }) => {
  const scrollTo = useCallback(
    (id) => {
      const scrollableElement = parent || document.getElementById('App');
      const element = document.getElementById(id);

      if (!scrollableElement || !element) return;

      const scroll = () => {
        scrollableElement.scrollTo({
          top: scrollableElement.scrollTop + element.getBoundingClientRect().top + offsetY,
          behavior,
        });
      };

      if (timeout) {
        setTimeout(scroll, timeout);
      } else {
        scroll();
      }
    },
    [behavior, offsetY, timeout, parent],
  );

  return scrollTo;
};
