import React, { lazy } from 'react';
import { AuthorizedLayout } from 'components';
import { LazyLoad } from '@livingsecurity/shared';

const CatalogComponent = lazy(() => import('@livingsecurity/content-catalog'));

const Catalog = (props) => {
  return (
    <AuthorizedLayout withoutPadding>
      <LazyLoad component={CatalogComponent} {...props} />
    </AuthorizedLayout>
  );
};

export default Catalog;
