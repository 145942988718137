import { useQueries } from 'react-query';
import { httpClient } from '../lib';

export const loadParticipantTemplateById = (id) => httpClient.get(`/notification/participant_notifications/${id}`);

export const useGetNotifications = (ids) => {
  const queries = ids.map((id) => ({
    queryKey: ['notification', id],
    queryFn: () => loadParticipantTemplateById(id),
  }));

  const results = useQueries(queries);

  return results;
};
