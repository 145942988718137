import { useQuery, useMutation } from 'react-query';
import { httpClient } from '../lib';

export const addToCampaign = ({ campaignGuid = '', test, name, tenantId }) =>
  httpClient.post(`/campaigns/contentful/${campaignGuid}${tenantId ? `?tenant_id=${tenantId}` : ''}`, { test, name });

export const validateCampaignName = ({ name, tenantId }) =>
  httpClient.post(`/campaigns/check_name?tenant_id=${tenantId}`, { name });

export const useValidateCampaignName = (queryOptions = {}) => {
  return useMutation(({ name = '', tenantId = '' }) => {
    return validateCampaignName({ name, tenantId });
  }, queryOptions);
};

export const useAddToCampaign = (mutationOptions = {}) => {
  return useMutation(({ campaignGuid = '', test, name, tenantId }) => {
    return addToCampaign({ campaignGuid, test, name, tenantId });
  }, mutationOptions);
};
