import React from 'react';

const Registering = (props) => (
  <svg
    width={150}
    height={150}
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width: 500,
      height: 500,
    }}
    {...props}
  >
    <style>
      {
        '\n     .form1 {animation:form1 4s infinite;}\n     @keyframes form1 {\n         0%{transform: translate3d(0, -4px, 0);}\n         50%{transform: translate3d(0, 4px, 0);}\n         100%{transform: translate3d(0, -4px, 0);}\n     }\n\n     @media (prefers-reduced-motion: reduce) {\n         .form1 {\n             animation: none;\n         }\n     }\n    '
      }
    </style>
    <g
      className="form1"
      style={{
        animationDuration: '1.9s',
      }}
    >
      <path
        className="fillCls4"
        d="M136.498 5H71.0736C68.2489 5 65.959 7.2899 65.959 10.1146V135.02C65.959 137.845 68.2489 140.135 71.0736 140.135H136.498C139.323 140.135 141.613 137.845 141.613 135.02V10.1146C141.613 7.2899 139.323 5 136.498 5Z"
        fill="rgba(95,218,184,1)"
        strokeWidth="2px"
        style={{
          animationDuration: '1.9s',
        }}
      />
      <path
        className="fillCls12"
        d="M132.978 8.19861H74.1105C71.3517 8.19861 69.1152 10.4337 69.1152 13.1909V131.939C69.1152 134.696 71.3517 136.932 74.1105 136.932H132.978C135.737 136.932 137.973 134.696 137.973 131.939V13.1909C137.973 10.4337 135.737 8.19861 132.978 8.19861Z"
        fill="#FFFFFF"
        strokeWidth="2px"
      />
      <path
        className="strokeCls2"
        d="M127.563 30.1239H74.0957V41.5681H127.563V30.1239Z"
        stroke="rgba(0,0,0,1)"
        strokeWidth="2px"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="strokeCls2"
        d="M127.563 53.8673H74.0957V65.3115H127.563V53.8673Z"
        stroke="rgba(0,0,0,1)"
        strokeWidth="2px"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="strokeCls2"
        d="M127.563 77.6096H74.0957V89.0538H127.563V77.6096Z"
        stroke="rgba(0,0,0,1)"
        strokeWidth="2px"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="strokeCls1"
        d="M76.658 25.1804C76.4595 25.7273 76.9438 26.3605 77.6863 26.5124C78.4922 26.6772 79.3567 26.3 79.8074 25.7809C80.258 25.2618 80.386 24.6325 80.5021 24.0231C80.5905 23.5705 80.5498 22.963 79.9493 22.8429C79.777 22.8145 79.6003 22.8317 79.4366 22.8926C79.273 22.9535 79.1281 23.0562 79.0163 23.1903C78.8287 23.4285 78.793 23.7144 78.7612 23.9843C78.6937 24.565 78.6322 25.1754 78.9478 25.7104C79.2634 26.2454 80.0922 26.6662 80.8456 26.4806C81.2545 26.3814 81.5751 26.1054 81.6456 25.7928C81.716 25.4801 81.5334 25.1427 81.187 24.9531C81.0133 25.2935 80.9438 25.6806 81.1294 26.0171C81.315 26.3536 81.8083 26.6126 82.2947 26.5461C82.781 26.4796 83.1126 26.0141 82.8287 25.7174C82.4922 26.0737 82.8495 26.6553 83.4361 26.7099C84.0227 26.7645 84.5528 26.2563 84.3384 25.8513C84.0277 26.0846 84.2997 26.561 84.7443 26.562C85.189 26.563 85.4619 26.0876 85.1533 25.8523C84.787 26.037 84.8555 26.4985 85.2207 26.6841C85.586 26.8697 86.1051 26.8131 86.4694 26.6265"
        stroke="rgba(0,0,0,1)"
        strokeWidth="2px"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="strokeCls1"
        d="M76.7178 50.5005C77.0824 50.1785 77.3765 49.7845 77.5815 49.3433C77.7865 48.902 77.8979 48.4232 77.9088 47.9368C77.9264 47.6814 77.872 47.4263 77.752 47.2003C77.62 46.9839 77.355 46.8291 77.1118 46.8856C76.7247 46.976 76.6086 47.4683 76.6155 47.8653C76.6264 48.4608 76.7485 49.0722 77.0979 49.5526C77.4473 50.033 78.0577 50.3546 78.6413 50.2375C79.225 50.1204 79.6885 49.4861 79.5148 48.9174C79.3922 48.9513 79.2817 49.0193 79.1963 49.1135C79.1109 49.2078 79.054 49.3243 79.0323 49.4497C79.0106 49.575 79.0249 49.7039 79.0737 49.8214C79.1225 49.9389 79.2036 50.0401 79.3077 50.1132C79.4118 50.1863 79.5345 50.2283 79.6615 50.2344C79.7886 50.2404 79.9147 50.2102 80.0253 50.1473C80.1358 50.0844 80.2262 49.9914 80.2859 49.879C80.3456 49.7667 80.3721 49.6398 80.3624 49.5129C80.3251 49.5784 80.3045 49.652 80.3022 49.7273C80.3 49.8027 80.3163 49.8774 80.3497 49.9449C80.383 50.0125 80.4325 50.0708 80.4937 50.1148C80.5548 50.1588 80.6259 50.1871 80.7005 50.1973C80.7752 50.2074 80.8512 50.1991 80.9219 50.1731C80.9926 50.147 81.0558 50.104 81.106 50.0478C81.1562 49.9916 81.1919 49.9239 81.2098 49.8508C81.2278 49.7776 81.2275 49.7011 81.2091 49.6281C81.0761 49.7273 81.1098 49.9596 81.2577 50.042C81.3321 50.0777 81.4156 50.0898 81.497 50.0767C81.5785 50.0636 81.6539 50.0258 81.7133 49.9685C81.8299 49.8512 81.9137 49.7055 81.9565 49.5457C81.9598 49.726 82.0191 49.9008 82.1261 50.0459C82.2332 50.1911 82.3827 50.2993 82.554 50.3557C82.7253 50.412 82.9099 50.4138 83.0822 50.3606C83.2545 50.3074 83.406 50.2019 83.5158 50.0588"
        stroke="rgba(0,0,0,1)"
        strokeWidth="2px"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="strokeCls1"
        d="M76.6113 38.0843C77.1076 38.5051 77.8808 38.4813 78.4347 38.1448C78.9885 37.8083 79.3399 37.1999 79.5106 36.5726C79.6813 35.9453 79.6922 35.2823 79.7012 34.6371C79.7012 34.3503 79.7071 34.0515 79.583 33.7925C79.459 33.5334 79.1682 33.3309 78.8883 33.4054C78.5796 33.4877 78.4446 33.8431 78.3751 34.1547C78.2004 34.9389 78.1498 35.7686 78.3751 36.5369C78.6004 37.3051 79.1195 38.0188 79.856 38.3423C80.5925 38.6659 81.5433 38.5349 82.0764 37.9364C82.2624 37.7432 82.3688 37.4869 82.3741 37.2188C82.3662 36.9528 82.1895 36.6828 81.9295 36.6232C81.6089 36.5528 81.2833 36.8436 81.2485 37.1711C81.2138 37.4987 81.4113 37.8163 81.6803 38.0059C81.8629 38.1336 82.0753 38.2122 82.2971 38.234C82.5189 38.2557 82.7426 38.22 82.9465 38.1303C83.1505 38.0405 83.3279 37.8997 83.4616 37.7215C83.5954 37.5432 83.6809 37.3335 83.7101 37.1126C83.3538 37.4103 83.5116 38.1051 83.9632 38.2193C84.4148 38.3334 84.8863 37.8073 84.7195 37.3726C84.4456 37.5384 84.4873 38.0009 84.7523 38.1815C85.0173 38.3622 85.3945 38.2947 85.6456 38.0982C85.8967 37.9016 86.0545 37.6019 86.1786 37.3091C86.5325 36.4696 86.7122 35.567 86.7066 34.656C86.7066 34.2768 86.5776 33.7944 86.2004 33.7498C85.7806 33.7001 85.5642 34.2302 85.4907 34.6431C85.3478 35.46 85.2039 36.3046 85.4004 37.1106C85.5969 37.9165 86.2272 38.6778 87.053 38.7543C87.9046 38.8327 88.7304 38.0485 88.6957 37.1939C88.2391 37.4609 88.2649 38.255 88.7374 38.4922C89.2099 38.7294 89.861 38.2748 89.8024 37.7498C89.7925 38.1021 90.0347 38.4247 90.3394 38.6004C90.6441 38.7761 91.0044 38.8297 91.3558 38.8575C92.1911 38.9202 93.0312 38.8532 93.8461 38.659"
        stroke="rgba(0,0,0,1)"
        strokeWidth="2px"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <path
      className="fillCls9"
      d="M69.3138 85.9398L74.2925 92.185L65.9609 94.7091L69.3138 85.9398Z"
      fill="rgba(67,179,212,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '1.9s',
      }}
    />
    <path
      className="fillCls3"
      d="M75.5625 129.347V145H79.3342V138.775H81.6171V145H98.1928C100.384 145 102.183 142.457 97.4911 139.651C92.7992 136.845 88.3714 135.749 88.3714 135.749V129.523L75.5625 129.347Z"
      fill="#231F20"
      strokeWidth="2px"
      style={{
        animationDuration: '1.9s',
      }}
    />
    <path
      className="fillCls3"
      d="M24.0423 119.451H8.38672V123.222H14.613V125.505H8.38672V142.081C8.38672 144.273 10.9296 146.071 13.7356 141.38C16.5416 136.689 17.6383 132.26 17.6383 132.26H23.8706L24.0423 119.451Z"
      fill="#231F20"
      strokeWidth="2px"
      style={{
        animationDuration: '1.9s',
      }}
    />
    <path
      className="fillCls7"
      d="M67.4777 93.832C67.4777 93.832 79.0548 89.0935 84.4017 87.7516C89.7486 86.4096 92.3799 87.299 93.5511 87.7516C96.9129 89.0528 99.9938 93.2106 99.8042 97.298C99.5849 102.106 101.207 102.412 101.338 104.677C101.469 106.942 100.198 107.117 100.198 109.354C100.198 111.59 101.445 113.522 101.348 115.667C101.25 117.812 100.373 117.771 100.373 119.657C100.373 121.543 101.601 123.735 101.601 125.532C101.601 127.33 101.294 127.242 101.294 127.944C101.294 128.646 102.566 129.698 102.435 131.978L74.5486 131.364L75.3427 111.81L65.1263 114.661C65.1263 114.661 69.828 121.968 71.2206 126.66C72.6132 131.352 73.0628 137.899 67.6841 140.88C63.2732 143.322 55.1273 142.195 53.2424 142.501C51.3576 142.807 48.8136 144.079 47.0598 143.99C45.3059 143.9 42.8504 142.718 40.4831 142.763C38.1159 142.808 35.5719 144.649 33.5551 144.561C31.5382 144.472 30.6181 143.158 28.9079 143.158C27.1977 143.158 23.4707 144.299 22.1992 144.25V119.394L38.0712 120.051L33.5958 101.067L59.0697 87.0022L67.4777 93.832Z"
      fill="rgba(23,48,66,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '1.9s',
      }}
    />
    <path
      className="fillCls8"
      d="M72.4023 112.63L75.3433 111.809L75.0098 120.078L77.7542 111.108L72.4023 112.63Z"
      fill="rgba(88,61,61,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '1.9s',
      }}
    />
    <path
      className="fillCls8"
      d="M65.1258 114.659L67.7342 113.931L61.9258 110.433C63.0719 111.78 64.1403 113.191 65.1258 114.659V114.659Z"
      fill="rgba(88,61,61,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '1.9s',
      }}
    />
    <path
      className="fillCls8"
      d="M39.7829 120.184L36.9193 115.155L38.0727 120.052L36.7129 119.996L39.7829 120.184Z"
      fill="rgba(88,61,61,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '1.9s',
      }}
    />
    <path
      className="fillCls2"
      d="M80.1734 68.7385C79.9451 69.1516 79.5693 69.4633 79.1212 69.6112C78.673 69.7591 78.1855 69.7324 77.7561 69.5364C77.3268 69.3403 76.9873 68.9894 76.8056 68.5539C76.6238 68.1183 76.6132 67.6302 76.7759 67.1871C76.524 67.5863 76.1307 67.8756 75.6747 67.9972C75.2187 68.1189 74.7335 68.0639 74.3163 67.8432C73.915 67.6302 73.602 67.2821 73.4327 66.8605C73.2634 66.4388 73.2487 65.9709 73.3913 65.5395C73.1279 65.9008 72.7438 66.1559 72.3086 66.2585C71.8734 66.3611 71.4158 66.3044 71.0188 66.0987C70.6218 65.8931 70.3115 65.552 70.1442 65.1374C69.977 64.7227 69.9637 64.2618 70.1069 63.8383L40.7461 122.753C40.3888 123.415 40.1682 124.141 40.0973 124.889C40.0264 125.637 40.1066 126.392 40.3333 127.108C40.56 127.824 40.9284 128.488 41.4167 129.059C41.905 129.63 42.5032 130.097 43.1756 130.433C43.8481 130.768 44.5812 130.965 45.3312 131.011C46.0812 131.057 46.8329 130.952 47.5415 130.702C48.25 130.452 48.9011 130.062 49.4559 129.555C50.0107 129.048 50.4579 128.435 50.7709 127.752L80.2766 68.54V68.5053C80.2476 68.5853 80.2131 68.6632 80.1734 68.7385V68.7385Z"
      fill="rgba(65,63,53,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '1.9s',
      }}
    />
    <path
      className="fillCls13"
      d="M70.4535 63.5905C70.4535 63.5905 69.595 65.3999 71.1751 66.0431C72.0158 66.3845 72.8268 66.3786 73.3469 65.6669C73.056 66.4997 73.2545 67.7096 74.0565 68.1344C74.8952 68.5791 76.1955 67.9954 76.6938 67.2054C76.3682 68.0471 76.7523 68.9106 77.5682 69.3433C77.7775 69.4543 78.0066 69.523 78.2425 69.5454C78.4784 69.5678 78.7163 69.5435 78.9428 69.4739C79.1693 69.4043 79.3798 69.2908 79.5624 69.1398C79.745 68.9888 79.896 68.8033 80.0069 68.594C80.0449 68.5211 80.0781 68.4459 80.1062 68.3686L79.858 66.6714L78.7354 58.983L72.1449 62.6555L70.4575 63.5954"
      fill="#EEEEEE"
      strokeWidth="2px"
      style={{
        animationDuration: '1.9s',
      }}
    />
    <path
      className="fillCls2"
      d="M79.1046 61.523L78.7334 58.983L76.4902 60.2307C76.7439 60.6854 77.1297 61.0522 77.5966 61.2827C78.0635 61.5131 78.5894 61.5962 79.1046 61.521V61.523Z"
      fill="rgba(65,63,53,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '1.9s',
      }}
    />
    <path
      className="fillCls4"
      d="M49.0353 72.962C47.2487 69.1029 42.3772 69.8245 39.075 68.8587C35.8184 67.9069 35.1911 59.6766 30.4993 59.1615C26.6502 58.7386 24.3504 62.3069 23.474 67.9198C22.5976 73.5327 14.7931 75.0662 11.8988 78.2037C9.00452 81.3411 7.16234 90.7188 14.4854 94.7516C21.8085 98.7843 31.9356 96.1104 31.9356 96.1104L49.0353 72.962Z"
      fill="rgba(95,218,184,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '1.9s',
      }}
    />
    <path
      className="fillCls5"
      d="M42.5645 102.153C42.5645 102.153 48.6379 99.4645 49.5481 98.7141C50.7908 97.6908 51.831 96.5513 52.8513 95.5866C53.8717 94.6218 55.3506 95.4982 54.8543 96.6675C54.358 97.8367 52.7173 99.8585 53.027 100.746C53.4508 101.959 57.1957 102.017 58.9456 101.696C60.6955 101.374 61.2384 101.142 62.1327 100.902C63.4617 100.55 63.7545 101.545 63.5501 102.348C63.5501 102.348 64.3828 102.421 64.4265 103.195C64.4702 103.97 63.6811 104.453 63.6811 104.453C63.865 104.826 63.8967 105.255 63.7694 105.651C63.5501 106.294 62.4791 106.748 62.4791 106.748C62.5479 106.897 62.5823 107.06 62.5797 107.224C62.5772 107.389 62.5377 107.551 62.4642 107.698C62.0553 108.604 59.8627 109.363 58.0503 109.51C56.2379 109.657 51.4885 110.403 49.9243 111.205C48.36 112.007 46.4801 113.313 46.4801 113.313L42.9297 106.284L42.5645 102.153Z"
      fill="rgba(187,130,130,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '1.9s',
      }}
    />
    <path
      className="fillCls9"
      d="M62.5512 79.0624C62.5512 79.0624 55.0276 74.0143 51.6331 73.1368C48.2385 72.2594 39.7949 75.6073 39.7949 75.6073L31.0991 79.6996C31.0991 79.6996 24.4489 82.9592 22.6365 86.1155C20.8241 89.2718 19.3522 99.5617 18.9194 106.284C18.4866 113.007 17.8425 118.79 21.9963 121.66C26.1502 124.529 31.2331 122.362 37.9566 118.385C44.6802 114.407 46.6088 113.239 46.6088 113.239C46.6088 113.239 44.972 110.433 44.3259 107.803C43.6797 105.173 43.1021 101.898 43.1021 101.898L34.0182 106.166L34.2484 101.957L56.9969 90.1453L62.5512 79.0624Z"
      fill="rgba(67,179,212,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '1.9s',
      }}
    />
    <path
      className="fillCls10"
      d="M34.0652 105.32L34.1674 103.448L30.9277 107.543L34.0205 106.139L34.0652 105.32Z"
      fill="#0043AE"
      strokeWidth="2px"
      style={{
        animationDuration: '1.9s',
      }}
    />
    <path
      className="fillCls10"
      d="M34.2656 101.95L36.4274 100.837L34.6974 94.7091L34.2656 101.95Z"
      fill="#0043AE"
      strokeWidth="2px"
      style={{
        animationDuration: '1.9s',
      }}
    />
    <path
      className="fillCls10"
      d="M56.142 90.5934L56.9966 90.1497L57.2318 89.6803L55.0859 86.5319L56.142 90.5934Z"
      fill="#0043AE"
      strokeWidth="2px"
      style={{
        animationDuration: '1.9s',
      }}
    />
    <path
      className="fillCls5"
      d="M32.6517 60.1587C32.6517 60.1587 35.2085 62.3949 36.0562 63.5205C36.9038 64.646 38.1296 66.915 37.4229 68.0654C37.2536 68.357 37.0155 68.6028 36.7293 68.7811C36.4431 68.9595 36.1176 69.0651 35.7812 69.0887L39.8001 75.6068C39.8001 75.6068 39.3177 78.9101 37.1837 79.8163C35.0497 80.7225 31.1043 79.6992 31.1043 79.6992L29.4964 67.7289C29.2992 67.9084 29.0632 68.0399 28.8068 68.1131C28.5505 68.1863 28.2806 68.1992 28.0184 68.1508C27.141 67.9761 25.6363 66.9091 25.7524 65.6376C25.8686 64.3661 26.8055 64.1319 27.3902 64.1319C27.8388 64.1295 28.2809 64.2401 28.6755 64.4535L28.7202 62.8019C28.7202 62.8019 30.9554 62.5537 31.7157 62.0078C32.476 61.4619 32.6517 60.1587 32.6517 60.1587Z"
      fill="rgba(187,130,130,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '1.9s',
      }}
    />
    <path
      className="fillCls1"
      d="M35.3262 67.2562C35.8701 67.238 36.3971 67.0622 36.8431 66.7501C37.289 66.438 37.6346 66.0031 37.8379 65.4982"
      fill="#111111"
      strokeWidth="2px"
      style={{
        animationDuration: '1.9s',
      }}
    />
    <path
      className="fillCls1"
      d="M32.1655 63.973C32.2416 63.8428 32.1617 63.6544 31.9869 63.5521C31.812 63.4498 31.6086 63.4724 31.5324 63.6026C31.4563 63.7327 31.5362 63.9212 31.7111 64.0235C31.8859 64.1257 32.0893 64.1031 32.1655 63.973Z"
      fill="#111111"
      strokeWidth="2px"
      style={{
        animationDuration: '1.9s',
      }}
    />
    <path
      className="fillCls1"
      d="M34.5112 61.7844C34.5873 61.6542 34.5074 61.4658 34.3326 61.3635C34.1577 61.2612 33.9543 61.2838 33.8781 61.414C33.802 61.5441 33.8819 61.7326 34.0568 61.8349C34.2316 61.9371 34.435 61.9145 34.5112 61.7844Z"
      fill="#111111"
      strokeWidth="2px"
      style={{
        animationDuration: '1.9s',
      }}
    />
    <path
      className="fillCls6"
      d="M35.7791 69.0893C35.15 69.1084 34.5203 69.0911 33.8932 69.0377C32.9146 68.9503 31.248 68.5334 31.248 68.5334L31.657 70.5067C32.6343 71.0361 33.708 71.3641 34.8143 71.4714C36.6853 71.661 37.2481 71.4714 37.2481 71.4714L35.7791 69.0893Z"
      fill="rgba(103,76,70,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '1.9s',
      }}
    />
    <path
      className="fillCls7"
      d="M34.0156 62.5701L35.8578 65.0257L37.436 64.0609L34.0156 62.5701Z"
      fill="rgba(23,48,66,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '1.9s',
      }}
    />
    <path
      className="fillCls7"
      d="M33.325 66.2345C33.8218 65.8581 33.9784 65.2282 33.6748 64.8275C33.3713 64.4268 32.7225 64.4072 32.2257 64.7836C31.7289 65.16 31.5723 65.7899 31.8758 66.1905C32.1794 66.5912 32.8282 66.6109 33.325 66.2345Z"
      fill="rgba(23,48,66,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '1.9s',
      }}
    />
    <path
      className="fillCls5"
      d="M79.5216 85.9397C79.5216 85.9397 77.9435 81.1754 77.9723 79.7729C78.0011 78.3704 78.5271 76.5282 78.7465 75.7679C78.9658 75.0076 77.9723 74.1014 77.0661 74.9491C76.1599 75.7967 75.4293 77.6975 75.4293 77.6975C75.4293 77.6975 74.5311 75.9327 73.8621 75.099C73.1931 74.2652 71.9981 72.8161 71.1216 73.0384C70.8892 73.0822 70.6837 73.2165 70.5501 73.4117C70.4165 73.6069 70.3657 73.8471 70.409 74.0796C70.409 74.0796 69.335 73.0057 68.6224 73.7511C67.9097 74.4965 68.7216 75.9218 68.7216 75.9218C68.4913 75.9352 68.2704 76.0184 68.0884 76.1602C67.9064 76.302 67.7717 76.4958 67.7023 76.7158C67.4174 77.5099 68.0646 78.7327 68.0646 78.7327C68.0646 78.7327 67.3479 78.7009 67.0889 79.3789C66.7713 80.2126 67.2338 81.0801 68.7554 83.9387C70.1251 86.5193 70.9797 90.5163 71.1772 92.1491C71.1803 92.1695 71.1846 92.1897 71.1901 92.2096C71.2383 92.1418 71.3027 92.0872 71.3774 92.0508C71.4522 92.0144 71.5349 91.9973 71.6179 92.0012C71.737 92.0012 71.8452 92.0012 71.9484 92.0012C71.945 91.9221 71.958 91.8431 71.9867 91.7693C72.0154 91.6954 72.0592 91.6284 72.1152 91.5724C72.1605 91.4938 72.2252 91.4281 72.3032 91.3815C72.3811 91.3349 72.4696 91.3091 72.5603 91.3064C72.651 91.3037 72.741 91.3243 72.8215 91.3662C72.902 91.408 72.9705 91.4698 73.0204 91.5456C73.2853 91.3412 73.5835 91.1841 73.9018 91.0811C74.4348 90.9153 74.9767 90.7833 75.4899 90.5471C75.9574 90.3327 76.413 90.1004 76.8904 89.9069C76.9546 89.8765 77.0252 89.8619 77.0962 89.8645C77.1672 89.8671 77.2365 89.8868 77.2983 89.9218C78.5986 89.1635 79.5891 88.5322 79.5891 88.5322L79.5216 85.9397Z"
      fill="rgba(187,130,130,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '1.9s',
      }}
    />
    <path
      className="fillCls9"
      d="M80.4789 88.9373L79.5211 85.9398L70.8789 92.049L71.0169 92.4222C73.6491 91.3919 77.3156 89.9964 80.4789 88.9373Z"
      fill="rgba(67,179,212,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '1.9s',
      }}
    />
    <path
      className="fillCls11"
      d="M41.8022 120.631L40.7461 122.754C40.3888 123.415 40.1682 124.141 40.0973 124.89C40.0264 125.638 40.1066 126.392 40.3333 127.109C40.56 127.825 40.9284 128.489 41.4167 129.06C41.905 129.631 42.5032 130.098 43.1756 130.434C43.8481 130.769 44.5812 130.965 45.3312 131.012C46.0812 131.058 46.8329 130.953 47.5415 130.703C48.25 130.453 48.9011 130.063 49.4559 129.556C50.0107 129.049 50.4579 128.436 50.7709 127.753L51.7069 125.875L41.8022 120.631Z"
      fill="rgba(210,147,126,1)"
      strokeWidth="2px"
      style={{
        animationDuration: '1.9s',
      }}
    />
  </svg>
);

export default Registering;
