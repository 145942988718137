import React, { memo, useEffect, useState, useCallback, forwardRef } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import Img from '../Img';
import * as Styled from './styles';
import SvgIcon from '../SvgIcon';

const Button = forwardRef(function Button(
  {
    onClick,
    text,
    disabled,
    variant,
    icon,
    reverseIcon,
    loading,
    iconSize,
    type,
    size,
    skeleton,
    children,
    iconSvgProps,
    ...rest
  },
  ref,
) {
  const [{ src: iconSrc, component: IconComponent }, setIconImage] = useState({ src: undefined, component: undefined });

  const buttonText = typeof text === 'string' ? text : undefined;

  const setImage = useCallback(async (iconName) => {
    let src;
    let svgComponent;
    try {
      src = await import(`./assets/icons/${iconName}.svg`);
      svgComponent = await import(`!!@svgr/webpack?-svgo,+titleProp,+ref!./assets/icons/${iconName}.svg`); // Probably work only for CRA 4.x
    } catch (e) {
      console.log(`failed to import icon - ${iconName}`, e);
    }

    if (!src && iconName) {
      src = `https://cdn.cyberescape.livingsecurity.com/icons/${iconName}.svg`;
      setIconImage({ src, component: undefined });
    } else if (src && src.default) {
      setIconImage({ src: src.default, component: svgComponent?.default });
    }
  }, []);

  useEffect(() => {
    if (icon) {
      setImage(icon);
    }
  }, [icon, setImage]);

  return (
    <Styled.ButtonWrapper
      title={buttonText}
      isLoading={loading}
      variant={variant}
      onClick={onClick}
      disabled={!!disabled || skeleton || loading}
      icon={icon}
      iconAsButton={!!icon && typeof buttonText === 'undefined'}
      type={type}
      size={size}
      reverseIcon={!!reverseIcon}
      ref={ref}
      {...rest}
    >
      {!!icon &&
        (!!iconSvgProps && !!IconComponent ? (
          <SvgIcon as={IconComponent} {...iconSvgProps} />
        ) : (
          <Img size={iconSize} src={iconSrc} />
        ))}
      {children || (!!buttonText && <Styled.ButtonTitle>{buttonText}</Styled.ButtonTitle>)}
      {loading && <Styled.ButtonLoader coverElement />}
      {skeleton && (
        <Styled.SkeletonWrapper>
          <Skeleton width="100%" height="100%" />
        </Styled.SkeletonWrapper>
      )}
    </Styled.ButtonWrapper>
  );
});

Button.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  variant: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string,
  reverseIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  skeleton: PropTypes.bool,
  iconSize: PropTypes.arrayOf(PropTypes.number),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  iconSvgProps: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

Button.defaultProps = {
  onClick: () => {},
  text: undefined,
  disabled: false,
  loading: false,
  skeleton: false,
  icon: undefined,
  iconSize: [14, 14],
  reverseIcon: false,
  iconSvgProps: undefined,
  type: 'submit',
  size: 'medium',
  variant: undefined,
};

export default memo(Button);
