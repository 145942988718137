export const CACHE_KEY = 'TABLES_CACHE';

export function TablePersistor(tableKey = '') {
  const getCache = (key) =>
    key ? JSON.parse(localStorage.getItem(CACHE_KEY))?.[key] : JSON.parse(localStorage.getItem(CACHE_KEY));

  const clear = () => localStorage.removeItem(CACHE_KEY);

  const set = (key, value) => {
    const cache = getCache();
    const tableCache = getCache(tableKey);

    localStorage.setItem(
      CACHE_KEY,
      JSON.stringify({
        ...cache,
        [tableKey]: {
          ...tableCache,
          [key]: value,
        },
      }),
    );
  };

  const setTablesCache = (data) => localStorage.setItem(CACHE_KEY, JSON.stringify(data));
  const clearTablesCache = () => localStorage.removeItem(CACHE_KEY);

  return {
    setColumns: (columns) => set('columns', columns),
    setSorting: (sortData) => set('sort', sortData),
    setPage: (page) => set('page', page),
    setPerPage: (perPage) => set('perPage', perPage),
    setFilters: (filters) => set('filters', filters),
    get: () => getCache(tableKey),
    getTablesCache: () => getCache(),
    setTablesCache,
    clearTablesCache,
    set,
    clear,
  };
}
