import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { merge } from 'lodash';
import qs from 'query-string';

// Basically copy of navigation from router v6 - https://reactrouter.com/docs/en/v6/hooks/use-navigate

export const useNavigate = () => {
  const history = useHistory();
  const { search: locationParams, pathname: locationPathname } = useLocation();

  const navigate = useCallback(
    (
      to,
      {
        replace = false,
        state,
        // probably not needed for router v6
        mergeParams = false,
      } = {},
      { stringifyOptions = {}, parseOptions = {} } = {}, // optional. Only for query-string lib
    ) => {
      if (typeof to === 'number') {
        history.go(to);
        return;
      }

      let toOrPath = '/';

      if (typeof to === 'object') {
        const { pathname, search, hash } = to;

        const pathnameOrFactory =
          typeof pathname === 'function' ? pathname(locationPathname) : pathname ?? locationPathname;

        const searchParams = qs.stringify(
          mergeParams ? merge(qs.parse(locationParams, parseOptions), search) : search,
          stringifyOptions,
        );

        const path = { pathname: pathnameOrFactory, search: searchParams || undefined, hash };

        toOrPath = path;
      }

      history[replace ? 'replace' : 'push'](toOrPath, state);
    },
    [history, locationParams, locationPathname],
  );

  return navigate;
};
