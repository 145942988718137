/**
 * Convert string from spaces to empty string
 *
 * @example removeWhiteSpaces('    ')
 * return ''
 *
 * @param {string} str
 *
 * @return {string}
 *
 */
export const removeWhiteSpaces = (str = '') => str?.replace?.(/^\s*\s*$/, '') ?? str;
