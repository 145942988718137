import { useState, useRef, useEffect } from 'react';

// TODO: add description why should we use it
export const PADDINGS = 80;

export const useContentSlider = (elementWidth, countElements) => {
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [distance, setDistance] = useState(0);
  const [totalInViewport, setTotalInViewport] = useState(0);
  const [viewed, setViewed] = useState(0);

  useEffect(() => {
    if (!containerRef.current) return;

    const width = containerRef.current.clientWidth - PADDINGS;

    setContainerWidth(width);
    setTotalInViewport(Math.floor(width / elementWidth));
  }, [containerRef.current, elementWidth]); // eslint-disable-line

  const handlePrev = () => {
    setViewed((prev) => prev - totalInViewport);
    setDistance((prev) => prev + containerWidth);
  };

  const handleNext = () => {
    setViewed((prev) => prev + totalInViewport);
    setDistance((prev) => prev - containerWidth);
  };

  const slideProps = {
    style: { transform: `translate3d(${distance}px, 0, 0)` },
  };

  const hasPrev = distance < 0;
  const hasNext = viewed + totalInViewport < countElements;

  return {
    handlePrev,
    handleNext,
    slideProps,
    containerRef,
    hasPrev,
    hasNext,
  };
};
