import React, { lazy } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { LazyLoad } from '@livingsecurity/shared';
import { AuthorizedLayout } from 'components';

const CampaignBuilderContainerPkg = lazy(() => import('@livingsecurity/campaign-builder'));

const CampaignBuilder = () => {
  const {
    params: { type },
  } = useRouteMatch();

  return (
    <AuthorizedLayout withoutPadding={!!type}>
      <LazyLoad component={CampaignBuilderContainerPkg} type={type} />
    </AuthorizedLayout>
  );
};

export default CampaignBuilder;
