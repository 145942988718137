import { useEffect, useState, useCallback } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useAuth0 } from '../lib/react-auth0-spa';
import { getPhishingUrl, USER_GROUPS, MARKETING_PAGE } from '../_constants';

export const useLSPhishing = () => {
  const { enableEmbeddedPhishing } = useFlags();
  const { isUserHasRights } = useAuth0();
  const lsAdmin = isUserHasRights([USER_GROUPS.PHISHING_LS_ADMIN]);
  const roleHasAccess = enableEmbeddedPhishing && (isUserHasRights([USER_GROUPS.PHISHING_CUSTOMER_ADMIN]) || lsAdmin);
  const [hasAccess] = useState(roleHasAccess);

  const [loading, setLoading] = useState(true);

  const getEmbeddedUrl = useCallback(() => {
    return hasAccess && !loading ? `${getPhishingUrl()}?embedded=true` : !loading && MARKETING_PAGE.PHISHING;
  }, [hasAccess, loading]);

  useEffect(() => {
    setLoading(false);
  }, []);

  return { loading, hasAccess, getEmbeddedUrl };
};
