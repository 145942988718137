import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { isFunction, isPlainObject } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { useEffectOnce } from 'react-use';
import jsurl from 'jsurl';

/**
 * Stole this file from Unify and removed the Typescript syntax
 * Unify: https://github.com/LivingSecurity/Unify/blob/development/src/frontend/src/hooks/useSearchParams.tsx
 */
const useContextValue = (queryStringOptions) => {
  /**
   * Internal Methods
   */
  const stringifyParams = useCallback(
    (paramsObj) => {
      const objectValuesAsStrings = Object.entries(paramsObj).reduce((result, [key, value]) => {
        if (isPlainObject(value)) {
          result[key] = jsurl.stringify(value);
          return result;
        }
        result[key] = value;
        return result;
      }, {});
      return qs.stringify(objectValuesAsStrings, queryStringOptions);
    },
    [queryStringOptions],
  );

  const parseParams = useCallback(
    (paramsString) => {
      const paramsObj = qs.parse(paramsString, queryStringOptions);
      return Object.entries(paramsObj).reduce((result, [key, value]) => {
        try {
          if (value?.startsWith('~(')) {
            const parsedValue = jsurl.parse(value);
            result[key] = parsedValue;
          } else {
            result[key] = value;
          }
          return result;
        } catch (e) {
          console.error(e);
        }
      }, {});
    },
    [queryStringOptions],
  );

  /**
   * Params State
   */
  const location = useLocation();
  const [searchParams, setSearchParamsState] = useState(parseParams(location.search));

  /**
   * History helpers
   */
  const routerHistory = useHistory();
  const createHistoryHandler = useCallback(
    (method) => (pathname, paramsObj, locationDescriptor) => {
      // optimistic update alongside location.search change
      setSearchParamsState(paramsObj || {});

      // call history method
      routerHistory[method]({
        pathname,
        search: paramsObj ? stringifyParams(paramsObj) : undefined,
        hash: location.hash,
        ...locationDescriptor,
      });
    },
    [location.hash, routerHistory, stringifyParams],
  );
  const history = useMemo(
    () => ({
      push: createHistoryHandler('push'),
      replace: createHistoryHandler('replace'),
    }),
    [createHistoryHandler],
  );

  /**
   * Params State helpers
   */
  const setSearchParams = useCallback(
    (arg1) => {
      history.replace(location.pathname, isFunction(arg1) ? arg1(searchParams) : arg1);
    },
    [history, location.pathname, searchParams],
  );

  // Update searchParams with router changes
  useEffectOnce(() => {
    const unlisten = routerHistory.listen((listener) => {
      setSearchParamsState(parseParams(listener.search));
    });
    return unlisten;
  });

  /**
   * Context Value
   */
  return {
    history,
    searchParams,
    setSearchParams,
    stringifyParams,
    parseParams,
  };
};

/**
 * Context + Provider
 *
 * SearchParamsProvider: used with `useSearchParams` hook
 */
const SearchParamsContext = createContext({});

export const SearchParamsProvider = ({ children, queryStringOptions }) => {
  const value = useContextValue(queryStringOptions);
  return <SearchParamsContext.Provider value={value}>{children}</SearchParamsContext.Provider>;
};

/**
 * Consumer Hook
 */
export const useSearchParams = () => {
  return useContext(SearchParamsContext);
};
