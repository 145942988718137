/* Libs */

import { css } from 'styled-components';

/* Mixins */

export const TextEllipsis = () => css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
