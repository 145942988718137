import React, { useRef, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useAuth0 } from '@livingsecurity/shared';
import { ChatBubble } from '../atoms';
import { ChatBottomBar } from './ChatBottomBar';
import { createMessage } from '../../utils';
import useConversation from '../../api/hooks/useConversation';

const ALLOW_PROMPTS = false;
const ALLOW_ATTACHMENT = false;
const ALLOW_MIC = false;
const ALLOW_SCROLL = false;

/*
 * App wrapper that routes users to our various application routes
 */
const ChatConversation = ({ user, embedded }) => {
  const inputRef = useRef();
  const scrollViewRef = useRef();
  const { activeConversation, isConversationLoading } = useConversation({ user });
  const [messages, setMessages] = useState(activeConversation?.messages || []);
  const [value, updateValue] = useState('');
  const [inputHeight, updateInputHeight] = useState('151');
  const [loading, setLoading] = useState(false);

  const submitMsg = useCallback(async () => {
    // @ts-ignore
    const content = inputRef?.current?.value || '';
    setLoading(true);
    updateInputHeight('151');

    const textInput = content;
    updateValue('');

    setMessages((prevMessages) => {
      const newMessages = [...prevMessages, createMessage(content, 'user', new Date())];
      stream(newMessages, textInput);
      return [...newMessages, createMessage('', 'assistant', new Date())];
    });
  }, [inputRef]);

  const stream = async (messages, input) => {
    const response = await fetch('https://dev-beta.livingsecurity.com/api/v1/chat', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        messages,
        userId: user.email,
        tenantId: user.tenant_id,
      }),
    });

    if (response.status !== 200) {
      throw new Error(response.statusText);
    }

    const reader = response.body.getReader();
    const stream = new ReadableStream({
      start(controller) {
        function push() {
          reader.read().then(({ done, value }) => {
            if (done) {
              controller.close();
              return;
            }
            controller.enqueue(value);
            push();
          });
        }

        push();
      },
    });

    const readerStream = stream.getReader();

    readerStream.read().then(function processText({ done, value }) {
      if (done) {
        return;
      }

      let data = new TextDecoder().decode(value);

      setMessages((prevMessages) => {
        const last = prevMessages[prevMessages.length - 1] || createMessage('', 'assistant', messageStart);
        return [...prevMessages.slice(0, -1), { ...last, content: last.content + data, role: 'assistant' }];
      });

      return readerStream.read().then(processText);
    });

    // @ts-ignore
    scrollViewRef.current?.scrollIntoView({ behavior: 'smooth' });
    setLoading(false);
  };

  useEffect(() => {
    if (messages?.length) {
      // Scroll to bottom every time messages change
      // @ts-ignore
      scrollViewRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages?.length]);

  const convo = [
    {
      content: 'Hey!',
      role: 'user',
    },
    {
      content: 'Hello! How can I assist you today in the realm of cybersecurity awareness training?',
      role: 'assistant',
    },
    {
      content: 'What can you help with?\n',
      role: 'user',
    },
    {
      content:
        'As a Human Risk Management consultant, I can provide assistance and guidance on various aspects of cybersecurity awareness training. This includes understanding common cyber threats, promoting safe online practices, raising awareness about phishing and social engineering attacks, educating employees on the importance of strong passwords and secure browsing, and much more. I can also share best practices and strategies for implementing effective cybersecurity awareness programs within organizations. How can I assist you specifically?',
      role: 'assistant',
    },
  ];

  return (
    <>
      <div className="bg-gradient-to-b dark:from-base-100/50 dark:to-neutral relative px-1 md:px-2">
        {!messages.length && (
          <div className="absolute top-[50px] text-center left-0 right-0 flex flex-col justify-center items-center p-4 sm:px-6 lg:px-8 lg:py-8 mx-auto">
            <h1 className="text-3xl font-bold text-gray-800 sm:text-4xl dark:text-white">
              Welcome to Living Security Assist
            </h1>
            <p className="mt-3 text-label text-gray-600 dark:text-gray-400">
              Your AI-powered Human Risk Management Copilot
            </p>
          </div>
        )}

        <ul className="space-y-2 mt-4 mb-12" style={{ minHeight: `calc(100vh - 357px)` }}>
          {messages?.map(({ content, role }, index) => {
            return (
              <ChatBubble
                key={index}
                message={content}
                avatarUrl={
                  role === 'assistant' ? 'https://platform-cdn.livingsecurity.com/chat/logo.png' : user?.picture
                }
                timestamp={new Date().toString()}
                position={role === 'assistant' ? 'start' : 'end'}
                botLoading={role === 'assistant' && loading && index === messages.length - 1}
                embedded={embedded}
              />
            );
          })}
        </ul>
      </div>
      <ChatBottomBar
        updateValue={updateValue}
        submitMsg={submitMsg}
        updateInputHeight={updateInputHeight}
        inputRef={inputRef}
        inputHeight={inputHeight}
        embedded={embedded}
        loading={loading}
        value={value}
      />
    </>
  );
};

export default ChatConversation;
