import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { BUTTON_TYPES } from '../../../_constants';
import Button from '../../Button';

import * as Styled from './styles';

function ConfirmNotification({ message, onConfirm, onCancel, deleteType }) {
  const { t } = useTranslation('shared');

  return (
    <Styled.Wrapper>
      <Styled.Message>{message}</Styled.Message>
      <Styled.ButtonsWrapper>
        <Button
          text={deleteType ? t('common.confirm-notification.yes-delete') : t('common.confirm-notification.yes')}
          variant={BUTTON_TYPES.SKY}
          onClick={onConfirm}
        />
        <Button text={t('common.confirm-notification.no-cancel')} onClick={onCancel} />
      </Styled.ButtonsWrapper>
    </Styled.Wrapper>
  );
}

ConfirmNotification.propTypes = {
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  deleteType: PropTypes.bool,
};

ConfirmNotification.defaultProps = {
  onCancel: null,
  deleteType: false,
};

export default ConfirmNotification;
