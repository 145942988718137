import { rest } from 'msw';
import { mockAddCampaignError } from '../../entities/other';

export const handlers = [
  rest.post(`${process.env.REACT_APP_API_URL}/campaigns/contentful/:campaignGuid`, async (req, res, ctx) => {
    const { campaignGuid } = req.params;

    if (campaignGuid) {
      return res(
        ctx.status(200),
        ctx.json({
          id: '38239c95-ecde-4a2d-b92f-6ea60fb2e750',
        }),
      );
    } else {
      return res(ctx.status(400), mockAddCampaignError(ctx));
    }
  }),
  rest.get(`${process.env.REACT_APP_API_URL}/content/collection/videos`, async (req, res, ctx) => {
    const params = await req.url.searchParams;

    return res(
      ctx.status(200),
      ctx.json({
        params,
        data: [
          {
            id: '38239c95-ecde-4a2d-b92f-6ea60fb2e750',
            title: 'Test Video',
            description: 'Test Video Description',
            thumbnail: 'https://www.youtube.com/watch?v=9bZkp7q19f0',
            duration: 100,
          },
        ],
        total: 1,
      }),
    );
  }),
];
