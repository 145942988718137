import { CAPITAL_LETTERS_RULE } from '../../_constants';

/**
 * Convert phrase to abbreviation
 *
 * @example convertStringToAbbr('Hello World')
 * return 'HW'
 * @param {string} index
 *
 * @return {string}
 *
 */
export const convertStringToAbbr = (string) => string.match(CAPITAL_LETTERS_RULE).join('');
