import styled from 'styled-components';
import { BREAKPOINTS } from '@livingsecurity/shared';

export const Header = styled.header`
  padding: 2rem;

  z-index: 1;
  position: relative;

  @media (max-width: ${BREAKPOINTS.TABLET}) {
    display: flex;
    justify-content: center;
    padding-bottom: 4rem;
  }
`;
