import { sortBy, get } from 'lodash';

/**
 * Sorts array by specific order
 * @param {array} arr Array to sort. Should be array of objects
 * @param {array} orders array of order. Should be array of strings
 * @param {string} key object key path
 * @returns {array} sorted array
 */
export const sortByOrder = (arr, orders, key) => sortBy(arr, (i) => orders?.indexOf(get(i, key)));
