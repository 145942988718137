import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const PuzzleContainer = styled.div`
  position: relative;
  overflow: hidden;
  // max-width: 1360px;
  // min-width: 800px;
  // min-height: 400px;
  // max-height: 760px;
  width: 100%;
  height: 100%;
`;
