import { useMutation } from 'react-query';
import { checkEmail } from '../_api';

const useCheckEmail = (mutationOptions = {}) => {
  return useMutation(({ email = '' }) => {
    return checkEmail(email);
  }, mutationOptions);
};

export default useCheckEmail;
