const COLORS_CONFIG = {
  PRIMARY: {
    LS_CYAN: '#4EB3D4',
    LS_CYAN_HOVER: '#5ECAED',
    LS_BLUE: '#0284C7',
    LS_GREEN: '#00865E',
    POPPY_RED: '#F16153',
    LS_BLACK: '#373636',
    SANDY_GRAY: '#EAEAEA',
  },
  FEEDBACK: {
    SUCCESS: '#4ED4B2',
    INFO: '#3D68D3',
    WARNING: '#E1DB5F',
    DANGER: '#EB6383',
  },
  SECONDARY: {
    OFF_WHITE: '#F4F7F9',
    CRYSTAL: '#EDF7FB',
    SKY: '#DFEEF2',
    IRON: '#A2ACB3',
    WHITE: '#FFFFFF',
    DEEP_SEA: '#173042',
    STEEL: '#626E76',
  },
  GRADIENTS: {
    LAKE_TRAVIS: 'linear-gradient(to right,#4EB3D4,#79DEFF)',
    GRASS: 'linear-gradient(to right,#7CD88B,#AAFFB8)',
    UVA: 'linear-gradient(to right,#3D68D3,#F4E0FB)',
    SUNNY_SIDE_UP: 'linear-gradient(to right,#E1DB5F,#F4EE7F)',
  },
  PARTICIPANT_DASHBOARD: {
    SLATE_GRAY: '#6b7280',
    BLACK_75: 'rgba(24, 24, 27, 0.75)',
    LIGHT_BLACK: '#27272a',
    LIGHT_BLACK_60: 'rgba(39, 39, 42, 0.6)',
    LIGHT_BLACK_75: 'rgba(39, 39, 42, 0.75)',
    LIGHT_GREY_50: 'rgba(209, 213, 219, 0.5)',
    BLUE_5: 'rgba(2, 132, 199, 0.05)',
  },
  OTHER: {
    ORANGE: '#FF9900',
  },
};

export default COLORS_CONFIG;

export const AVAILABLE_COLORS = Object.values(COLORS_CONFIG);
