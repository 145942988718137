export const getNumberOfDigits = (number) => {
  return number.toString().length;
};

export function getRandomInt(min, max) {
  const minCeil = Math.ceil(min);
  const maxCeil = Math.floor(max);
  return Math.floor(Math.random() * (maxCeil - minCeil + 1)) + minCeil;
}

/**
 * returns a number formatted based on the number and how many trailing digits.
 * 1000 will return 1k, 1 million will return 1M, 759878 w/ 0 digits will return 759k.
 * @param {int} num - A number needing to be formatted with a letter...or not
 * @param {int} digits - A integer representing the number of trailing digits after a period
 * @return {string} - A string representing the formatted number with the appropriate symbol
 */
export function nFormatter(num, digits) {
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (+num >= si[i].value) {
      break;
    }
  }
  return (+num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
}
/*
    numAbbr.abbreviate(12, 1)
    // => 12
    numAbbr.abbreviate(0, 2)
    // => 0
    numAbbr.abbreviate(1234, 0)
    // => 1k
    numAbbr.abbreviate(34567, 2)
    // => 34.57k
    numAbbr.abbreviate(918395, 1)
    // => 918.4k
    numAbbr.abbreviate(2134124, 2)
    // => 2.13m
    numAbbr.abbreviate(-47475782130, 2)
    // => -47.48b
 */
export function NumAbbr() {
  let units;
  if (!(this instanceof NumAbbr)) {
    // function usage: abbrev(n, decPlaces, units)
    const n = arguments[0];
    const decPlaces = arguments[1];
    units = arguments[2];
    const ab = new NumAbbr(units);
    return ab.abbreviate(n, decPlaces);
  }
  // class usage: new NumberAbbreviate(units)
  units = arguments[0];
  this.units = units == null ? ['k', 'm', 'b', 't'] : units;
}

NumAbbr.prototype._abbreviate = function (number, decPlaces) {
  decPlaces = Math.pow(10, decPlaces);

  for (let i = this.units.length - 1; i >= 0; i--) {
    const size = Math.pow(10, (i + 1) * 3);

    if (size <= number) {
      number = Math.round((number * decPlaces) / size) / decPlaces;

      if (number === 1000 && i < this.units.length - 1) {
        number = 1;
        i++;
      }

      number += this.units[i];

      break;
    }
  }

  return number;
};

NumAbbr.prototype.abbreviate = function (number, decPlaces) {
  const isNegative = number < 0;
  const abbreviatedNumber = this._abbreviate(Math.abs(number), decPlaces || 0);

  return isNegative ? '-' + abbreviatedNumber : abbreviatedNumber;
};
