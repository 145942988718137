import PropTypes from 'prop-types';

export default PropTypes.shape({
  data: PropTypes.shape({
    assign_day: PropTypes.number,
    due_day: PropTypes.number,
    nickname: PropTypes.string,
    type: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.shape({})),
    assignment_notifications: {
      content: PropTypes.arrayOf(PropTypes.shape({})),
    },
    due_date_notifications: {
      content: PropTypes.arrayOf(PropTypes.shape({})),
      start_before: PropTypes.number,
    },
    past_due_notifications: {
      content: PropTypes.arrayOf(PropTypes.shape({})),
      start_after: PropTypes.number,
      repetitions: PropTypes.number,
      repetitions_interval: PropTypes.number,
    },
    notifications_enabled: PropTypes.bool,
    badges: PropTypes.shape({
      status: PropTypes.bool,
      data: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    featuredTraining: PropTypes.shape({
      status: PropTypes.bool,
      starts: PropTypes.number,
      ends: PropTypes.number,
    }),
  }),
  isOpen: PropTypes.bool,
});
