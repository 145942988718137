import { snakeCase, isPlainObject } from 'lodash';

/**
 * Convert object keys to camel case
 *
 * @example convertObjKeysToSnakeCase({ 'firstValue': 5 })
 * return { first_value: 5 }
 *
 * @param {object} obj
 *
 * @return {object}
 */
export const convertObjKeysToSnakeCase = (obj = {}) =>
  isPlainObject(obj)
    ? Object.entries(obj).reduce((o, [k, v]) => ({ ...o, [snakeCase(k)]: convertObjKeysToSnakeCase(v) }), {})
    : obj;
