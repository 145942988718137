import { USER_GROUPS } from '../../_constants';

/**
 * Find USER_GROUP value in array of groups
 *
 * @example getPriorityGroup(['CUSTOMER_ADMIN', 'ANYTHING_ELSE'])
 * return 'CUSTOMER_ADMIN'
 *
 * @param {string[]} arrOfGroup

 *
 * @return {string}
 */
export const getPriorityGroup = (arrOfGroup) => {
  if (!Array.isArray(arrOfGroup)) return '';

  const priorityName = [
    USER_GROUPS.LS_ADMIN,
    USER_GROUPS.ENTERPRISE_ADMIN,
    USER_GROUPS.UNIFY_CUSTOMER_ADMIN,
    USER_GROUPS.UNIFY_CUSTOMER_LIMITED_ADMIN,
    USER_GROUPS.CUSTOMER_ADMIN,
    USER_GROUPS.CAMPAIGN_MGR,
    USER_GROUPS.TRAINING_DEMO,
    USER_GROUPS.PARTICIPANT,
  ].find((group) => arrOfGroup.find((userGroup) => group === userGroup));

  return priorityName || '';
};
