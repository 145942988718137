import React from 'react';

import Analytics from './Analytics';
import Registering from './Registering';
import Dashboard from './Dashboard';
import Complete from './Complete';
import Failed from './Failed';

const Icon = ({ icon }) => {
  const renderIcon = () => {
    switch (icon) {
      case 'REGISTERING':
        return <Registering />;
      case 'DASHBOARD':
        return <Dashboard />;
      case 'COMPLETE':
        return <Complete />;
      case 'FAILED':
        return <Failed />;
      case 'ANALYTICS':
        return <Analytics />;

      default:
        return null;
    }
  };

  return renderIcon();
};

export default Icon;
