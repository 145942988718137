export function mockKeyIndicator(initialValue = {}) {
  return {
    errors: [],
    data: {},
    message: '',
    ...initialValue,
  };
}

export function mockNoFilterError(ctx) {
  return ctx.json(
    mockKeyIndicator({
      errors: [{ message: 'This endpoint requires a filter in the request body' }],
      message: 'This endpoint requires a filter in the request body',
    }),
  );
}

export function mockAddCampaignError(ctx) {
  return ctx.json(
    mockKeyIndicator({
      errors: [{ message: 'There was an issue adding this Campaign, please try again' }],
      message: 'There was an issue adding this Campaign, please try again',
    }),
  );
}

export function mockCampaignTable(initialValue = {}) {
  return {
    ...initialValue,
    data: [
      {
        campaignId: '455f29d0-230d-4a68-a09d-69fd7c5a6cb9',
        name: 'Media Team Annual Compliance Training',
        state: 'ACTIVE',
        tenantId: '3b32d93a-a2bb-4ddc-a9d7-f89a666b2460',
        ingestTimestamp: '2023-01-17T23:01:07.718858786Z',
        dateStart: '2020-12-13T19:38:00.048455+00:00',
        audienceType: '',
        accuracy: 43.0,
        campaignFields: {},
        assignmentsCompleted: 55.0,
        audienceCount: 3.0,
        averageCompletionTime: 5.0,
        completionRate: 73.0,
        assignmentCompletionRate: 43.0,
        currentParticipantCount: 56.0,
        delinquencyRate: 27.0,
        overdue: 10,
        notStarted: 13,
        underway: 5,
        completed: 10,
        totalAssignments: 203.0,
        totalQuestionsAnswered: 89.0,
        totalQuestionsCorrect: 40.0,
        correctnessVsTotalQuestions: '40 / 230',
      },
      {
        campaignId: 'be58b724-ee60-4d86-9264-a1c3561baccd',
        name: 'Test Campaign',
        state: 'ACTIVE',
        tenantId: '3b32d93a-a2bb-4ddc-a9d7-f89a666b2460',
        ingestTimestamp: '2023-05-30T07:17:20.322166164Z',
        dateStart: '2022-09-11T19:08:36.549525+00:00',
        audienceType: '',
        accuracy: 0.5,
        campaignFields: {},
        assignmentsCompleted: 0.0,
        audienceCount: 1.0,
        averageCompletionTime: 0.38320209973753283,
        completionRate: 0.0026246719160104987,
        assignmentCompletionRate: 0.0,
        currentParticipantCount: 381,
        delinquencyRate: 0.9973753280839895,
        overdue: 380,
        notStarted: 0,
        underway: 0,
        completed: 1,
        totalAssignments: 1135.0,
        totalQuestionsAnswered: 8.0,
        totalQuestionsCorrect: 4.0,
        correctnessVsTotalQuestions: '4 / 8',
      },
      {
        campaignId: '2966e498-2c4a-482c-a747-34d06bcd4f0b',
        name: 'Fall 2021 Compliance Training',
        state: 'ACTIVE',
        tenantId: '3b32d93a-a2bb-4ddc-a9d7-f89a666b2460',
        ingestTimestamp: '2023-05-30T07:17:20.320602056Z',
        dateStart: '2022-08-08T19:48:39.575306+00:00',
        audienceType: '',
        accuracy: 1.0,
        campaignFields: {},
        assignmentsCompleted: 0.0,
        audienceCount: 1.0,
        averageCompletionTime: 0.27034120734908135,
        completionRate: 0.0026246719160104987,
        assignmentCompletionRate: 0.0,
        currentParticipantCount: 381,
        delinquencyRate: 0.9973753280839895,
        overdue: 380,
        notStarted: 0,
        underway: 0,
        completed: 1,
        totalAssignments: 761.0,
        totalQuestionsAnswered: 3.0,
        totalQuestionsCorrect: 3.0,
        correctnessVsTotalQuestions: '3 / 3',
      },
    ],
    meta: {
      start: '2023-05-30',
      end: '2023-05-30',
      timezone: 'UTC',
      daysInRange: 1,
      totalResults: 3,
      dataUpdatedAt: '2023-05-30T07:17:20.322Z',
      campaignDataUpdatedAt: '2023-05-30T07:17:20.322Z',
    },
  };
}
