export default {
  DARK_BLUE_GREY: '#173042',
  ICE_BLUE: '#edf0f2',
  ROBIN_S_EGG_BLUE: '#9ee7fb',
  PINE_GREEN: '#081a27',
  SEAFOAM_BLUE: '#5be5be',
  VERY_LIGHT_BLUE: '#dfeef2',
  BLACK: '#373636',
  BLACK_TWO: '#000000',
  ICE_BLUE_TWO: '#e8f1f3',
  PALE_GREY: '#f4f7f9',
  AQUAMARINE: '#4ed4b2',
  SKY: '#4eb3d4',
  YELLOW: '#e1db5f',
  TRANSPARENT: 'transparent',
};
