import { useState, useRef, useCallback } from 'react';
import { useAuth0 } from '../lib';
import { showErrorsFromBackend } from '../utils/custom';

export const useHttp = (fetcher, throwError = false) => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const safeFetcher = useRef(fetcher);

  const runPromise = useCallback(
    (...args) => {
      setIsLoading(true);
      return safeFetcher
        .current(...args)
        .then((r) => {
          setData(r);
          return r;
        })
        .catch((e) => {
          setError(e);
          showErrorsFromBackend(e);
          if (throwError) {
            throw e;
          }
        })
        .finally(() => setIsLoading(false));
    },
    [throwError],
  );

  return [runPromise, { data, isLoading, error }];
};

export const useAuthHttp = (fetcher, ...rest) => {
  const {
    user: { tenant_id: tenantId },
  } = useAuth0();

  return useHttp((params) => fetcher({ ...(params ?? {}), tenantId }), ...rest);
};
