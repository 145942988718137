import styled from 'styled-components';
import COLORS_CONFIG from '../../../../assets/styles/colors';

export const ModalBackdrop = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: 30px;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  overflow-x: hidden;
  z-index: 9;

  * {
    box-sizing: content-box !important;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS_CONFIG.SECONDARY.WHITE};
  color: ${COLORS_CONFIG.PRIMARY.LS_BLACK};
  border: 1px solid ${COLORS_CONFIG.PRIMARY.LS_CYAN};
  border-radius: 16px;
`;

export const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;
  min-width: ${(props) => props.width};
  max-width: ${(props) => props.width};
  color: ${COLORS_CONFIG.PRIMARY.LS_BLACK};
  padding: 16px;
  overflow-x: hidden;
`;

export const ModalHeader = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    margin-top: 1rem;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;

    text-align: center;
  }
  svg {
    transform: scale(1.75);
  }
`;
export const ModalBody = styled.div`
  margin-top: 1rem;
  text-align: center;
  max-width: 60%;
  font-size: 14px !important;
  display: flex;
  flex-direction: column;

  strong {
    margin-top: 1rem;
  }
`;
export const ModalFooter = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;

  button {
    width: 125px;
    height: 32px;
    font-size: 1rem;
    font-weight: 700;
  }
`;
