import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { VideoPlayer } from '@livingsecurity/cyberblocks';

import { CONTENT_TYPES, getMediaValue, getPlayerCoverImage } from '@livingsecurity/shared';
import { isEmpty } from 'lodash';

import 'cloudinary-video-player/dist/cld-video-player.css';

const VideoWrapper = styled.div`
  .video-js.vjs-4-3,
  .video-js.vjs-16-9,
  .video-js.vjs-fluid {
    width: 100%;
    max-width: 100%;
    height: 100% !important;
  }

  #cld-video-player {
    opacity: 0;
    transition: 300ms;
  }

  .cld-video-player-dimensions.vjs-fluid {
    padding: 0 !important;
  }

  .vjs-cloudinary-button {
    display: ${({ hideLogo }) => (hideLogo ? 'none !important' : '')};
  }

  width: 100%;
  height: 100%;
`;

const VideoView = ({ data, type, onPercentChange, playerConfigParams, disableSeeking, refTime, flags }) => {
  const [loading, setLoading] = useState(false);
  const { hideVideoPlayerLogo } = flags;
  const videoData = type === CONTENT_TYPES.MODULE ? data.video : data.videoFile?.[0];
  const videoType = type === CONTENT_TYPES.MODULE ? 'video' : 'videoFile';
  const publicId = getMediaValue(videoData, videoType).publicID;
  const coverImage = getPlayerCoverImage(data?.coverImage)?.coverImage;

  if (!data || type !== CONTENT_TYPES.VIDEO) return null;

  useEffect(() => {
    // On back-to-back videos we need to force the player to re-mount and set new cover image
    onReset(300);
  }, [data]);

  const onReset = useCallback((timeout = 1000) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, timeout);
  }, []);

  return (
    <VideoWrapper hideLogo={hideVideoPlayerLogo}>
      {!loading && (
        <VideoPlayer
          publicId={publicId}
          captions={data?.captionFile}
          onPercentChange={onPercentChange}
          playerConfigParams={playerConfigParams}
          disableSeeking={disableSeeking}
          refTime={refTime}
          coverImage={coverImage}
          width="600"
          onReset={onReset}
          wrapperStyles={{
            width: '97%', // TRAIN-1779 Video is shaking
            height: '100%',
            margin: '0 auto',
          }}
          playerOptions={{
            fluid: true,
            controls: true,
            hideContextMenu: false,
            showJumpControls: true,
            playedEventPercents: [25, 50, 75, 90, 100],
            logoOnclickUrl: 'https://livingsecurity.com',
            logoImageUrl:
              'https://res.cloudinary.com/demo/image/fetch/h_25/https://puzzles.cdn.livingsecurity.com/CEO/LS-Logo.png',
            colors: { base: '#000a10', accent: '#4eb3d4', text: '#fff' },
            posterOptions: {
              publicId: isEmpty(coverImage) ? publicId : coverImage,
            },
            ...(playerConfigParams || {}),
          }}
        />
      )}
    </VideoWrapper>
  );
};

VideoView.propTypes = {
  data: PropTypes.shape(),
  type: PropTypes.oneOf(['video', 'videoFile']).isRequired,
  onPercentChange: PropTypes.func,
  playerConfigParams: PropTypes.shape(),
  disableSeeking: PropTypes.bool,
  refTime: PropTypes.number,
};

VideoView.defaultProps = {
  data: null,
  onPercentChange: null,
  playerConfigParams: {},
  disableSeeking: false,
  refTime: 0,
};

export default VideoView;
