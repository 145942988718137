import COLORS_CONFIG from '../styles/colors';

const themes = {
  light: {
    id: 'light',
    name: 'Light',
    bgColor: '#ffffff',
    secondaryBgColor: '#F1F5F9',
    accent: '#0284C7',
    color: COLORS_CONFIG.SECONDARY.DEEP_SEA,
    disabledColor: '#71717A',
    secondaryColor: '#E2E8F0',
    black: '#18181B',
    gray: '#626E76',
    green: COLORS_CONFIG.PRIMARY.LS_GREEN,
    cyan: COLORS_CONFIG.PRIMARY.LS_CYAN,
    curiousBlue: '#1A99DB',
    contentCard: {
      assignmentCard: {
        bgColor: '#ffffff',
        secondaryBgColor: 'rgba(2, 132, 199, 0.85)',
        titleColor: '#737373',
      },
      green: '#00865E',
      red: '#E60027',
    },
    darkBlue: '#173042',
    lightGray: '#EAEAEA',
    lightWhite: '#DFEEF2',
    orange: '#F59F1D',
  },
  dark: {
    id: 'dark',
    name: 'Dark',
    bgColor: '#18181B',
    secondaryBgColor: '#27272A',
    gray: '#626E76',
    accent: '#0284C7',
    color: '#FFFFFF',
    disabledColor: '#71717A',
    secondaryColor: '#3F3F46',
    green: COLORS_CONFIG.PRIMARY.LS_GREEN,
    cyan: COLORS_CONFIG.PRIMARY.LS_CYAN,
    darkBlue: '#173042',
    curiousBlue: '#1A99DB',
    orange: '#F59F1D',
    contentCard: {
      assignmentCard: {
        bgColor: '#27272A',
        secondaryBgColor: 'rgba(24, 24, 27, 0.85)',
        titleColor: '#929293',
      },
      green: '#34D399',
      red: '#FF4767',
    },
  },
};
export default themes;
