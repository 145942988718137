import { isPlainObject } from 'lodash';

export const useSortTable = (sortOption, sortHandler, passObject = false) => {
  if (!isPlainObject(sortOption)) {
    throw new Error('[sortOption] should be an object');
  }

  if (typeof sortHandler !== 'function') {
    throw new Error('[sortHandler] should be a function');
  }

  const { sortBy, sortOrder } = sortOption;

  const ascHandler = (prop) => () => {
    if (sortBy === prop && sortOrder === 1) return;

    if (passObject) {
      sortHandler({ sortBy: prop, sortOrder: 1 });
    } else {
      sortHandler(prop, 1);
    }
  };

  const descHandler = (prop) => () => {
    if (sortBy === prop && sortOrder === -1) return;

    if (passObject) {
      sortHandler({ sortBy: prop, sortOrder: -1 });
    } else {
      sortHandler(prop, -1);
    }
  };

  return [ascHandler, descHandler];
};
