import { camelCase, isPlainObject } from 'lodash';

/**
 * Convert object keys to camel case
 *
 * @example convertObjKeysToCamelCase({ 'First Value': 5 })
 * return { firstValue: 5 }
 *
 * @param {object} obj
 *
 * @return {object}
 */
export const convertObjKeysToCamelCase = (obj = {}) =>
  isPlainObject(obj)
    ? Object.entries(obj).reduce((o, [k, v]) => ({ ...o, [camelCase(k)]: convertObjKeysToCamelCase(v) }), {})
    : obj;
