import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useClickedOutside } from '../../../../hooks/useClickedOutside';

import Img from '../../../../common/Img';
import { ALARM_ICON } from '../../../../assets/icons';

import * as Styled from '../_components';
import COLORS_CONFIG from '../../../../assets/styles/colors';

const Warning = ({ width, title, children, onClose }) => {
  const container = useRef();

  useClickedOutside(container, () => onClose());

  return (
    <Styled.ModalBackdrop>
      <Styled.ModalContainer ref={container}>
        <Styled.ModalContentWrapper width={width}>
          <Styled.ModalHeader>
            <Img src={ALARM_ICON} size={[40, 40]} style={{ fill: COLORS_CONFIG.FEEDBACK.WARNING }} />
            <h2>{title}</h2>
          </Styled.ModalHeader>
          {children}
        </Styled.ModalContentWrapper>
      </Styled.ModalContainer>
    </Styled.ModalBackdrop>
  );
};

Warning.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  width: PropTypes.string,
};

Warning.defaultProps = {
  width: '560px',
};

export default Warning;
