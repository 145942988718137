import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Userpilot } from 'userpilot';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { useAuth0 } from '../lib/react-auth0-spa';

import { URLS, USER_GROUPS } from '../_constants';

export const ServicesEnum = {
  LaunchDarkly: 1,
  Contentful: 2,
  UserPilot: 3,
  Hubspot: 4,
  Datadog: 5,
};

export const useInitializationService = ({ companyInfo }) => {
  const { isAuthenticated, user, userGroups, isImpersonate } = useAuth0();
  const { hideAccessibeWidget, hideUserPilotWidget, hideHubspotWidget, recordUserSessions } = useFlags();
  const location = useLocation();
  const ldClient = useLDClient();
  const [statuses, setStatuses] = useState({
    [ServicesEnum.LaunchDarkly]: false, // +
    [ServicesEnum.Contentful]: false, // +
    [ServicesEnum.UserPilot]: false, // +
    [ServicesEnum.Hubspot]: false, // +
    [ServicesEnum.Datadog]: false, // +
  });

  const setServiceInitialized = useCallback((name, value = true) => {
    setStatuses((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  // ----------------------LaunchDarkly-----------------------
  const [launchDarklyError, setLaunchDarklyError] = useState(false);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    if (ldClient && !isEmpty(user)) {
      ldClient.identify({
        key: `${user.tenant_id}:${user.email}`,
        email: user.email,
        custom: {
          tenantId: user.tenant_id,
          userGroups,
          isImpersonate,
          companyName: user.tenant_id ? companyInfo?.name : null,
          companySubscriptions: user.tenant_id ? companyInfo?.subscriptions : [],
          createdAt: user.created_at,
          updatedAt: user.updated_at,
          loginCount: user.logins_count,
          blocked: user.blocked,
          metadata: user.user_metadata,
        },
      });
      if (!statuses[ServicesEnum.LaunchDarkly]) {
        window.ldClient = ldClient;
        setServiceInitialized(ServicesEnum.LaunchDarkly, true);
      }
    }
  }, [ldClient, user, userGroups, companyInfo, isImpersonate, statuses]);

  // Log if launchDarkly blocked
  useEffect(() => {
    function onLaunchDarklyError() {
      setLaunchDarklyError(true);
      console.error(`LaunchDarkly error in company ID ${user.tenant_id} for user ${user.email}`);
    }

    if (ldClient) {
      ldClient.on('error', onLaunchDarklyError);
    }
    return () => ldClient?.off('error', onLaunchDarklyError);
  }, [ldClient]);
  // ----------------------LaunchDarkly-----------------------

  // ----------------------UserPilot-----------------------
  useEffect(() => {
    if (!window.location.href.includes('localhost') || process.env.REACT_APP_USERPILOT === 'true') {
      Userpilot.initialize(process.env.REACT_APP_USERPILOT_APP_TOKEN);
    }
  }, []);

  useEffect(() => {
    if (!statuses[ServicesEnum.LaunchDarkly]) {
      return;
    }

    if (
      !isEmpty(user) &&
      companyInfo &&
      (!window.location.href.includes('localhost') || process.env.REACT_APP_USERPILOT === 'true') &&
      !hideUserPilotWidget &&
      !statuses[ServicesEnum.UserPilot]
    ) {
      Userpilot.identify(`${user.email}`, {
        id: user.user_id,
        name: user.first_name ? user.name : user.nickname || user.email,
        email: user.email,
        userGroups,
        isImpersonate,
        created_at: user.created_at,
        updated_at: user.updated_at,
        login_count: user.logins_count,
        isBlocked: user.blocked,
        product: 'TRAINING',
        company: {
          id: user.tenant_id,
          ...companyInfo,
        },
      });
      console.log(`UserPilot initialized`);
      setServiceInitialized(ServicesEnum.UserPilot, true);
    }
  }, [user, userGroups, companyInfo, isImpersonate, hideUserPilotWidget, statuses]);

  useEffect(() => {
    if (hideUserPilotWidget) {
      Userpilot.destroy();
    } else {
      Userpilot.reload();
    }
  }, [location, hideUserPilotWidget]);
  // ----------------------UserPilot-----------------------

  // ----------------------Accessibe-----------------------
  useEffect(() => {
    if (hideAccessibeWidget) {
      window.AccessiBe?.destroy && window.AccessiBe?.destroy();
      window.document.getElementById('acsb-trigger')?.remove();
      window.document.getElementById('acsb-widget')?.remove();
      const el1 = window.document.getElementsByClassName('acsb-trigger');
      const el2 = window.document.getElementsByClassName('acsb-widget');
      el1?.length && el1[0].remove();
      el2?.length && el2[0].remove();
    }
  }, [hideAccessibeWidget]);
  // ----------------------Accessibe-----------------------

  // ----------------------Hubspot-----------------------
  const [showHubspotWidget, setHubspotWidget] = useState();
  useEffect(() => {
    if (!statuses[ServicesEnum.LaunchDarkly]) {
      return;
    }

    if (
      userGroups.includes(USER_GROUPS.CUSTOMER_ADMIN) &&
      !(
        location.pathname === URLS.player ||
        location.pathname === URLS.dashboard ||
        location.pathname === URLS.assignment
      ) &&
      !statuses[ServicesEnum.Hubspot] &&
      !hideHubspotWidget
    ) {
      console.log(`Hubspot widget initialized`);
      setHubspotWidget(true);
      setServiceInitialized(ServicesEnum.Hubspot, true);
    } else if (
      location.pathname === URLS.player ||
      location.pathname === URLS.dashboard ||
      location.pathname === URLS.assignment ||
      location.pathname === URLS.teamsBase ||
      hideHubspotWidget
    ) {
      setHubspotWidget(false);
    }
  }, [hideHubspotWidget, location, userGroups, statuses]);
  // ----------------------Hubspot-----------------------

  // ----------------------Datadog-----------------------
  useEffect(() => {
    if (!window.location.href.includes('localhost')) {
      datadogLogs.init({
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_ID,
        site: 'datadoghq.com',
        service: 'training-frontend',
        version: process.env.REACT_APP_VERSION,
        env: process.env.REACT_APP_DATADOG_ENV || 'dev',
        sampleRate: 100,
        forwardErrorsToLogs: true,
        silentMultipleInit: true,
        trackInteractions: true,
        useCrossSiteSessionCookie: true,
        trackSessionAcrossSubdomains: true,
        beforeSend: (log) => {
          // Ignore noisy warnings
          if (
            log?.message?.includes('There is no feedback for the content id') ||
            log?.message?.includes('Failed prop') ||
            log?.message?.includes('Warning:') ||
            log?.message?.includes('at e.exports') ||
            log?.message?.includes('Network Error') ||
            log?.message?.includes('Script error') ||
            log?.message?.includes('acsbJS is not defined') ||
            log?.message?.includes('ResizeObserver loop') ||
            log?.message?.includes('XHR error GET https://app.launchdarkly.com') ||
            log?.message?.includes('XHR error GET https://assets.livingsecurity.com')
          ) {
            return false;
          }
        },
      });
      datadogRum.init({
        applicationId: process.env.REACT_APP_DATADOG_APP_ID,
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_ID,
        site: 'datadoghq.com',
        service: 'training-frontend',
        version: process.env.REACT_APP_VERSION,
        env: process.env.REACT_APP_DATADOG_ENV || 'dev',
        sampleRate: 100,
        replaySampleRate: 50,
        trackInteractions: true,
        useCrossSiteSessionCookie: true,
        trackSessionAcrossSubdomains: true,
        beforeSend: ({ error }) => {
          // Ignore noisy warnings
          if (
            error?.message?.includes('Failed prop') ||
            error?.message?.includes('Warning:') ||
            error?.message?.includes('ResizeObserver loop') ||
            error?.message?.includes('XHR error GET https://app.launchdarkly.com')
          ) {
            return false;
          }
        },
      });
      console.log(`Datadog initialized with app version ${process.env.REACT_APP_VERSION}`);

      if (user.email && companyInfo) {
        datadogLogs.logger.debug(
          `Starting Platform session for: ${user.email} from ${companyInfo?.name} (${user.tenant_id})`,
        );
      }

      setServiceInitialized(ServicesEnum.Datadog, true);
    }
  }, []);

  useEffect(() => {
    if (!window.location.href.includes('localhost') && recordUserSessions) {
      if (isAuthenticated) {
        // Enabling Datadog Session Replay - https://docs.datadoghq.com/real_user_monitoring/guide/session-replay-getting-started
        datadogRum.startSessionReplayRecording();
      } else {
        datadogRum.stopSessionReplayRecording();
      }
    }
  }, [isAuthenticated, recordUserSessions]);
  // ----------------------Datadog-----------------------

  const isAllServicesInitialized = useMemo(() => {
    return Object.values(statuses).every((service) => service);
  }, [statuses]);

  return {
    showHubspotWidget,
    launchDarklyError,
    isAllServicesInitialized,
  };
};
