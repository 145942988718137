import { useQuery, useQueryClient } from 'react-query';
import useBus from 'use-bus';
import { isEmpty } from 'lodash';

import { useAuth0, URLS, httpClient } from '@livingsecurity/shared';
import { useMemo } from 'react';

const loadDomainSettings = (tenantId) =>
  httpClient.get(`/notification/email-domain-settings`, { params: { tenant_id: tenantId } });

//! Note: response schema should follow - { path: string, state: boolean }
const ICON_QUERIES_CONFIG = {
  DOMAINS_VERIFICATION: {
    query: loadDomainSettings,
    select: (response) => ({ path: URLS.userDomains, state: isEmpty(response) ? false : !response?.valid }),
  },
};

const QUERY_KEY = '@ui/SHOW_SIDEBAR_ICONS';

const queryFn = ({ queryKey: [, tid] }) => {
  const iconQueries = Object.values(ICON_QUERIES_CONFIG).map((config) =>
    config.query(tid).then((response) => (typeof config.select === 'function' ? config.select(response) : response)),
  );

  return Promise.allSettled(iconQueries).then((promiseResults) =>
    promiseResults.reduce(
      (acc, promise) => (promise?.value?.path ? { ...acc, [promise.value.path]: !!promise?.value?.state } : acc),
      {},
    ),
  );
};

export const useSidebarIcons = () => {
  const {
    user: { tenant_id: tenantId },
  } = useAuth0();
  const qc = useQueryClient();

  const queryKey = useMemo(() => [QUERY_KEY, tenantId], [tenantId]);

  useBus(QUERY_KEY, ({ payload }) =>
    qc.setQueryData(queryKey, (prevCache) => ({ ...prevCache, [payload?.path]: payload?.state })),
  );

  return useQuery(queryKey, queryFn, { enabled: !!tenantId });
};
