import { useEffect, useCallback, useState } from 'react';
import clientContentful from '../lib/clientContentful';

const CUSTOM_LOGO_KEY = 'lsCustomLogo';

export const fetchLogo = (tenantId) =>
  clientContentful
    .getEntries({ content_type: 'platformTenantUiCustomization', 'fields.tenantId': tenantId })
    .then((response) => {
      const url = response.items[0]?.fields?.logo?.fields?.file?.url;
      return url ? `https://${url}` : null;
    });

export const useLogo = (id, reload, fetchOnly) => {
  const [companyLogo, setCompanyLogo] = useState(localStorage.getItem(CUSTOM_LOGO_KEY));

  const fetchCompanyLogo = useCallback(async () => {
    try {
      const companyLogoUrl = await fetchLogo(id);
      setCompanyLogo(companyLogoUrl);
      if (companyLogoUrl && !fetchOnly) {
        localStorage.setItem(CUSTOM_LOGO_KEY, companyLogoUrl);
      } else {
        localStorage.removeItem(CUSTOM_LOGO_KEY);
      }
    } catch (e) {
      console.log(e);
    }
  }, [id]);

  useEffect(() => {
    /* istanbul ignore else */
    if (id && reload) fetchCompanyLogo();
  }, [id, reload]); // eslint-disable-line

  return {
    logo: companyLogo,
  };
};
