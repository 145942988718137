/**
 * This function sets a value to the local storage.
 *
 * @param {string} key - The key to store the value under.
 * @param {string} value - The value to store.
 */
export const setToLS = (key, value) => {
  if (typeof window === 'undefined') return null;
  try {
    window?.localStorage?.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.error('Failed to set localStorage item');
  }
};

/**
 * This function gets a value from the local storage.
 *
 * @param {string} key - The key to get the value from.
 * @returns {string|null} The value from the local storage.
 */
export const getFromLS = (key) => {
  if (typeof window === 'undefined') return null;
  try {
    const value = window?.localStorage?.getItem(key);
    const parsed = JSON.parse(value);
    return parsed;
  } catch (e) {
    console.error('Failed to parse value:', e?.message);
  }
};

export const EMBED_COOKIE_KEY = 'ls_embedded';

export const LOCATION_KEY = {
  TRAINING_PATH: 'lastTrainingLocation',
  TEAMS_PATH: 'lastTeamsLocation',
  UNIFY_PATH: 'lastUnifyLocation',
};
